/* eslint-disable max-lines-per-function */
import { useState, useReducer, useCallback, useEffect, useRef } from 'react'
import axios from 'axios'
import { store } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import buildUrl from '/src/hooks/api/query_builder'
import { dispatch } from '/src/hooks/bus/bus'
import handleError from '/src/hooks/api/errors'
import fetchAPIReducer, { initialState } from '/src/hooks/api/fetch_api_reducer'

export const MAX_PAGE_SIZE = 100

export const MIN_TOTAL_PAGES_FOR_SHOW_LOADING_MODAL = 3

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

const AVOID_ERROR_FROM_PATH = ['sync', 'eav_templates']

const useFetchAPI = (path, hideErrors = false) => {
  const componentMounted = useRef(true)
  const [resourcePath] = useState(path)
  const [getToken] = useCookie('authentication_token')
  const source = axios.CancelToken.source()

  const [fetchState, dispatchFetch] = useReducer(fetchAPIReducer, initialState)

  useEffect(() => {
    return () => {
      source.cancel()
      componentMounted.current = false
    }
  }, [])

  const setErrorNotifications = (errors) => {
    if (AVOID_ERROR_FROM_PATH.includes(resourcePath)) return

    if (Array.isArray(errors)) {
      return errors.forEach((body) => {
        notifyError({ body, closeTimeout: 10 })
      })
    }

    if (typeof errors === 'object') {
      return notifyError({ body: errors.body, title: errors.title, closeTimeout: 10 })
    }

    notifyError({ body: errors, closeTimeout: 10 })
  }

  const dispatchSuccess = (response, params) => {
    const { httpAction, dataOptions, dialogMessages, resourceId, additionalResource } = params
    const { paging } = dataOptions || { paging: null }
    let responseData

    try {
      if (response && typeof response.data === 'string') responseData = JSON.parse(response.data)
      else responseData = response.data
    } catch (err) {
      responseData = response.data ? response.data : response
    }

    if (httpAction === 'get') {
      responseData = { data: responseData.data ? responseData.data : [responseData] }
      if (paging) responseData.total = response.data.total
    } else if (httpAction === 'delete') {
      const responseId = additionalResource ? additionalResource.resourceId : resourceId
      responseData = { id: responseId }
    }

    if (dialogMessages) dispatch(BusEvents.SHOW_SUCCESS_DIALOG)
    dispatchFetch({ type: 'SUCCESS', responseData })

    return responseData
  }

  const dispatchError = (response, dialogMessages) => {
    const errors = handleError(response.request)
    dialogMessages ? dispatch(BusEvents.SHOW_FAILURE_DIALOG) : setErrorNotifications(errors)
    dispatchFetch({ type: 'ERROR', errors })

    return errors
  }

  const getUrlParams = (params) => {
    return {
      ...params,
      resourcePath: params.resourcePath || resourcePath,
      serverUrl: SERVER_URL,
      globalSubproject: store.get('subproject'),
      globalProject: store.get('project')
    }
  }

  const fetchAPI = useCallback((params, token) => {
    dispatchFetch({ type: 'FETCHING', requestAction: params.requestAction })
    const authToken = getToken() || token
    const headers = { 'Content-Type': 'application/json; charset=utf-8' }
    if (authToken) headers['Authorization'] = `Token ${authToken}`

    axios({
      cancelToken: source.token,
      method: params.httpAction,
      url: buildUrl(getUrlParams(params, token)),
      data: params.data,
      responseType: 'text',
      withCredentials: true,
      headers
    })
      .then((response) => {
        if (componentMounted.current) {
          dispatchSuccess(response, params)
        }
      })
      .catch((response) => {
        if (hideErrors) return

        if (!axios.isCancel(response)) dispatchError(response, params.dialogMessages)
      })
  }, [])

  return {
    loading: fetchState.loading,
    responseData: fetchState.response,
    errors: fetchState.errors,
    status: fetchState.status,
    fetchAPI,
    requestAction: fetchState.requestAction
  }
}

export default useFetchAPI
