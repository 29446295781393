import { useCallback } from 'react'
import RequestModel, { hasRequestSummary } from '/src/models/request'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { indexify } from '/src/utils/array'
import useFetch from '/src/hooks/api/fetch'

const model = new RequestModel()

export default function useRequestSummaries() {
  const { fetch } = useFetch()

  const includeSummariesOnDataSource = useCallback(({ data: dataSource }) => {
    if (
      !dataSource ||
      dataSource.length === 0 ||
      dataSource.every((dataItem) => hasRequestSummary(dataItem))
    ) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: dataSource.map(({ id }) => id) }
      }
    }

    fetch('requests/summaries', queryParams, {
      onSuccess: ({ data: { data } }) => {
        const modelName = model.paramName
        const summariesById = indexify(data, 'id')
        const onSetDataSource = (prevDataSource) => ({
          ...prevDataSource,
          data: prevDataSource.data.map((request) => ({
            ...request, ...summariesById[request.id]
          }))
        })
        dispatch({
          type: BusEvents.UPDATE_GRID_DATA_SOURCE,
          payload: { modelName, onSetDataSource }
        })
      }
    })
  }, [fetch])

  return includeSummariesOnDataSource
}
