import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import FlagIconsBadge from '/src/ui/core/badges/flag_icons_badge'
import { ReactComponent as FeatureSearch } from '/public/static/svg/feature_search_icon'

function getInspectionFlag(hasInspectionPending) {
  if (!hasInspectionPending) return null

  return {
    title: I18n.t('estimate_services.statuses.pending_inspection'),
    type: 'inspection-pending',
    icon: <FeatureSearch />
  }
}
export default function FlagColumn({ dataItem }) {
  const { inspected_flag: inspectedFlag } = dataItem

  const hasInspectionPending = inspectedFlag && inspectedFlag === 'pending_inspection'

  const flags = [
    getInspectionFlag(hasInspectionPending)
  ].filter(Boolean)

  return <FlagIconsBadge flags={flags} />
}

FlagColumn.propTypes = {
  dataItem: PropTypes.shape({
    inspected_flag: PropTypes.string
  }).isRequired
}
