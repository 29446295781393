import React, { useEffect, useMemo, useRef, useCallback } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useQueryParam, NumberParam } from 'use-query-params'
import Tabs from '/src/ui/core/layouts/tabs'

export default function TemplateTabs({ tabs, onTemplateChange }) {
  const prevUrl = useRef()
  const [urlTab, setUrlTab] = useQueryParam('eav_template_id', NumberParam)
  const [interactiveMode] = useStore('interactive_mode')
  const initialTab = useRef(urlTab)

  const setTemplateTitle = useCallback(() => {
    tabs.forEach((tab) => {
      if (tab.id === (urlTab || tabs[0].id)) onTemplateChange?.(tab)
    })
  }, [urlTab, tabs, onTemplateChange])

  const onTabChange = useCallback((tab) => {
    setUrlTab(tab?.id)
  }, [setUrlTab])

  useEffect(() => {
    if (tabs && tabs.length > 0) {
      if (!tabs.some((t) => t.id === urlTab)) {
        if (!urlTab) setUrlTab(prevUrl.current || tabs[0].id)
        initialTab.current = tabs[0].id
        onTemplateChange?.(tabs[0])
      } else if (urlTab !== prevUrl.current) {
        setTemplateTitle()
      }
    } else if (tabs && tabs.length === 0) {
      setUrlTab()
    }
    if (urlTab) prevUrl.current = urlTab
  }, [tabs, urlTab, setUrlTab, onTemplateChange, setTemplateTitle])

  const tabsMemo = useMemo(() => {
    if (tabs && tabs.length > 0) {
      if (tabs.some((t) => t.id === urlTab)) initialTab.current = urlTab
      else if (!tabs.some((t) => t.id === initialTab.current)) initialTab.current = tabs[0].id
    }

    return (
      <Tabs
        tabsList={tabs}
        onTabChange={onTabChange}
        initialTabId={initialTab.current}
        filterTabs={interactiveMode && interactiveMode.status}
      />
    )
  }, [urlTab, tabs, interactiveMode, onTabChange])

  return <div>{tabsMemo}</div>
}

TemplateTabs.propTypes = {
  tabs: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTemplateChange: PropTypes.func
}

TemplateTabs.defaultProps = {
  onTemplateChange: () => {}
}
