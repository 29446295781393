import { useMemo, useState } from 'react'
import useGeneralDiscipline from '/src/hooks/general_discipline'
import useAccessSolutionDisciplines from '/src/hooks/access_solution_disciplines'
import { addRequestByDisciplineQueryToColumns } from '/src/models/concerns/form'
import { isBlank } from '/src/utils/boolean_refinements'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function useRequestQueryByDiscipline(columns, modelName, eavTemplateId) {
  const [eavTemplates, setEavTemplates] = useState()
  const isScaffoldingModel = modelName === 'scaffolding'
  const generalDiscipline = useGeneralDiscipline()
  const accessSolutionDisciplines = useAccessSolutionDisciplines()
  const isLoadingTemplate = !isScaffoldingModel && isBlank(eavTemplates)

  useBus(
    BusEvents.SET_EDITABLE_GRID_EAV_TEMPLATE,
    ({ payload }) => {
      const { eavTemplates } = payload || {}
      setEavTemplates(eavTemplates)
    },
    [eavTemplateId]
  )

  const baseDisciplines = useMemo(
    () => (isScaffoldingModel ? accessSolutionDisciplines : [generalDiscipline]),
    [isScaffoldingModel, accessSolutionDisciplines, generalDiscipline]
  )

  const columnsUpdated = useMemo(() => {
    if (!isScaffoldingModel && (isBlank(eavTemplates) || eavTemplates.length === 0)) return columns

    const eavTemplate = !isScaffoldingModel ? eavTemplates.find(({ id }) => id === eavTemplateId) : {}

    return addRequestByDisciplineQueryToColumns(columns, eavTemplate, baseDisciplines)
  }, [columns, eavTemplates, eavTemplateId, isScaffoldingModel, baseDisciplines])

  return { isLoadingTemplate, columnsUpdated }
}
