import React from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import FlexibleActionButtons from '/src/ui/core/buttons/flexible_action_buttons'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import TrackBlock from '/src/ui/domain/requests/track_block'
import I18n from '/src/utils/translations'
import WorkDescriptionModal from '/src/ui/domain/progresses/work_description_modal'
import ProgressesTrackRichText from '/src/ui/domain/progresses/progresses_track_rich_text'
import { objectPop } from '/src/utils/object'
import '/src/static/css/core/blocks/track_block.css'

export default function ProgressesTrackBlock({ dataItem, contextMenuItems, fields, request, showAttachments, template }) {
  const [items, mainItem] = objectPop(contextMenuItems, 'history')
  const showMainItem = mainItem && mainItem.visible(dataItem)
  const mappedItems = Object.values(items ?? {}).map((menuItem) => ({ ...menuItem, dataItem }))

  const buttons = <FlexibleActionButtons mainItem={showMainItem && mainItem} dataItem={dataItem} items={mappedItems} />

  return !request.id ? (
    <ThreeDotsLoader />
  ) : (
    <TrackBlock detail={request} iconAttachments={showAttachments} actions={buttons}>
      <div className="progress-track-block-children">
        <div className="summary-item">
          {I18n.t('progresses.item')} {dataItem.number}
        </div>
        <ProgressesTrackRichText dataItem={dataItem} template={template} />
      </div>
      <WorkDescriptionModal dataItem={dataItem} />
    </TrackBlock>
  )
}

ProgressesTrackBlock.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  contextMenuItems: PropTypes.shape({
    history: PropTypes.oneOfType([PropTypes.object]).isRequired,
    edit: PropTypes.oneOfType([PropTypes.object]).isRequired,
    duplicate: PropTypes.oneOfType([PropTypes.object]).isRequired,
    cancel: PropTypes.oneOfType([PropTypes.object]).isRequired
  }),
  fields: PropTypes.oneOfType([PropTypes.object]),
  request: PropTypes.oneOfType([PropTypes.object]),
  showAttachments: PropTypes.bool,
  template: PropTypes.oneOfType([PropTypes.object])
}

ProgressesTrackBlock.defaultProps = {
  dataItem: undefined,
  contextMenuItems: undefined,
  fields: {},
  request: {},
  showAttachments: true,
  template: undefined
}
