import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import { store, withStore } from 'react-context-hook'
import { GridPDFExport } from '@progress/kendo-react-pdf'
import { Grid, GridColumn } from '@progress/kendo-react-grid'
import I18n from '/src/utils/translations'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import GridPDFPage from '/src/ui/core/grid/pdf_print/grid_pdf_page'
import { notifyError } from '/src/ui/core/dialogs/notifications'

export default function SimpleGridPDFExport({ model, gridData, cellFormat }) {
  const gridPDFExport = useRef()

  const gridPdfOptions = (paperSize) => ({
    paperSize,
    margin: { top: '2.4cm', left: '1.2cm', right: '1.2cm', bottom: '1.2cm' },
    landscape: true,
    scale: 0.6,
    repeatHeaders: true,
    avoidLinks: true,
    pageTemplate: (props) => <GridPDFPage {...props} model={model} numItems={gridData.length || 0} />
  })

  const renderCell = (props, cellColumn) => {
    const cellFormatter = cellFormat(cellColumn)
    return cellFormatter({ ...props, pdfCell: true })
  }

  const exportPDF = (selectedColumns, paperSize) => {
    if (!gridPDFExport.current) return

    const grid = <Grid />
    const pdfOptions = gridPdfOptions(paperSize)
    const columns = selectedColumns.map((column) => {
      const { id, description, field, title } = column

      return (
        <GridColumn
          key={id}
          field={field || description}
          title={title}
          className="grid-pdf-column"
          cell={cellFormat && withStore((props) => renderCell(props, column), store.getState())}
          headerCell={(_) => <span className="grid-pdf-cell-header">{title}</span>}
        />
      )
    })

    gridPDFExport.current.saveGridPDF(grid, pdfOptions, undefined, gridData, columns)
  }

  useBus(
    BusEvents.PRINT_GRID,
    ({ payload }) => {
      const { selectedColumns, paperSize, model: printModel } = payload
      if (printModel.name !== model.name) return
      exportPDF(selectedColumns, paperSize)
    },
    [exportPDF]
  )

  return <GridPDFExport ref={gridPDFExport} />
}

SimpleGridPDFExport.propTypes = {
  model: PropTypes.shape({
    name: PropTypes.string.isRequired
  }).isRequired,
  gridData: PropTypes.array.isRequired,
  cellFormat: PropTypes.func
}

SimpleGridPDFExport.defaultProps = {
  cellFormat: undefined
}
