/* eslint-disable max-lines-per-function */
/* eslint-disable no-restricted-syntax */
/* eslint-disable guard-for-in */
const compare = (a, b, property) => {
  if (!a[property]) return 1
  if (!b[property]) return -1
  return a[property] > b[property] ? 1 : -1
}

const compareString = (a, b, property) => (a[property].toLowerCase() > b[property].toLowerCase() ? 1 : -1)

export function sortArrayOfObjectsByNumber(array, property) {
  return array.sort((a, b) => compare(a, b, property))
}

export function sortArrayOfObjectsByString(array, property) {
  if (array.filter((obj) => typeof obj[property] !== 'string').length > 0) return array
  return array.sort((a, b) => compareString(a, b, property))
}

export function filter(object, criteria) {
  return Object.fromEntries(Object.entries(object).filter(criteria))
}

// Enable DOTs access on nested object
// E.g.: byString({ a: { b: 10 } }, 'a.b')
//       10
export function byString(object, lookup) {
  if ([null, undefined].includes(object)) return null

  const keys = lookup.split('.')

  let response = object

  for (let i = 0; i < keys.length; i += 1) {
    if ([null, undefined].includes(response[keys[i]])) return null

    response = response[keys[i]]
  }

  return response
}

// Invert keys with values on the object
// E.g.: invert({ 1: 'string', 2: 'integer' })
//       { 'string': 1, 'integer': 2 }
export function invert(object) {
  const inverted = {}

  Object.keys(object).forEach((key) => {
    inverted[object[key]] = key
  })

  return inverted
}

// Compare objects by id values
// obj1: { person: { id: 1, description: 'Tiago' } }
// obj2: { person: { id: 1, description: 'Nilton' } }
export function equalIds(obj1, obj2) {
  // Check type at start
  if (typeof obj1 !== typeof obj2) return false

  const hasOwnProperty = (obj, key) => Object.prototype.hasOwnProperty.call(obj, key)

  for (const p in obj1) {
    // Check property exists on both objects
    if (hasOwnProperty(obj1, p) !== hasOwnProperty(obj2, p)) return false

    // If there is no values
    if (obj1[p] === undefined) return false
    if (obj2[p] === undefined) return false

    // Check just for id values
    if (obj1[p].id !== obj2[p].id) return false
  }

  // Check object 2 for any extra properties
  for (const p in obj2) {
    if (obj1[p] === undefined && obj2[p] !== undefined) return false
  }

  return true
}

/**
 * Returns true if simple objects are equals
 * @param {object} obj1
 * @param {object} obj1
 * @returns {boolean} object comparison
 */
export function objectEquals(obj1, obj2) {
  return JSON.stringify(obj1) === JSON.stringify(obj2)
}

/**
 * Returns true if object is empty
 * @param {object} obj
 * @returns {boolean} object has keys
 */
export function isEmpty(obj) {
  return Object.keys(obj).length === 0
}

/**
 * Returns the value of property and remove it from object
 * @param {object} object object to have the property removed
 * @param {string} property property to be returned and removed
 * @returns {any} the value of the property
 */
export function objectPop(object, property) {
  if (!object || typeof object !== 'object') return [object, undefined]
  const objectCopy = { ...object }
  const item = objectCopy[property]

  // eslint-disable-next-line no-param-reassign
  delete objectCopy[property]
  return [objectCopy, item]
}

/**
 * Remove single or multiple properties of an object
 * @param {object} obj
 * @param {array} keys all keys to be deleted
 * @returns {object} String of keys to be deleted
 */
export function removeProperties(obj, keys) {
  const newObj = obj

  keys.forEach((key) => {
    if (Object.prototype.hasOwnProperty.call(newObj, key)) {
      delete newObj[key]
    }
  })

  return newObj
}

export function isObject(object) {
  return typeof object === 'object' && !Array.isArray(object) && object !== null
}

export const forceBooleanDefault = (object, property) => ({
  ...object,
  [property]: object[property] || false
})
