import React from 'react'
import PropTypes from 'prop-types'
// eslint-disable-next-line max-len
import InspectionTemplateButton from '/src/ui/domain/requests/inspection_template/inspection_template_button'
import { groupInspectionsByDatasheetCategory } from '/src/models/inspection'

export default function ChooseInspectionTemplateBody({
  inspectionTemplates,
  openInspectionModalCallback,
  openTemplateCategory,
  templateCategoryName
}) {
  const inspectionsByCategory = groupInspectionsByDatasheetCategory(inspectionTemplates)

  if (templateCategoryName) {
    return inspectionsByCategory[templateCategoryName].category.map((item) => (
      <InspectionTemplateButton
        key={item.title}
        title={item.title}
        onClick={(e) => openInspectionModalCallback(e, item)}
      />
    ))
  }

  const buttons = []
  Object.keys(inspectionsByCategory).forEach((key) => {
    if (inspectionsByCategory[key].hasOwnProperty('template')) {
      const item = inspectionsByCategory[key].template
      buttons.push(
        <InspectionTemplateButton key={key} title={key} onClick={(e) => openInspectionModalCallback(e, item)} />
      )
      return
    }

    buttons.push(
      <InspectionTemplateButton key={key} title={key} onClick={(e) => openTemplateCategory(e, key)} isCategory />
    )
  })

  return buttons
}

ChooseInspectionTemplateBody.propTypes = {
  inspectionTemplates: PropTypes.arrayOf(PropTypes.object),
  openInspectionModalCallback: PropTypes.func.isRequired,
  openTemplateCategory: PropTypes.func.isRequired,
  templateCategoryName: PropTypes.string
}

ChooseInspectionTemplateBody.defaultProps = {
  inspectionTemplates: [],
  templateCategoryName: null
}
