import React, { useCallback, useMemo, useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import ScaffoldingModel from '/src/models/scaffolding'
import SimpleGrid from '/src/ui/core/grid/simple_grid'
import ScaffoldingFormModal from '/src/ui/domain/scaffoldings/scaffolding_form_modal'
import I18n from '/src/utils/translations'
import CellFactory from '/src/ui/core/grid/column_cell_factory/cell_factory'
import useScaffoldingContextMenu from '/src/ui/domain/scaffoldings/scaffolding_context_menu'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useEntitiesCache from '/src/hooks/get_entities_cache'
import {
  foreignDropToEditableGrid,
  formattedToEditableGrid,
  showColumnsOnEditableGrid,
  subAreaToEditableGrid
} from '/src/utils/columns_formatter'
import NewButton from '/src/ui/core/icons/new_button'
import useScaffoldingStatusFilter from '/src/ui/domain/scaffoldings/scaffolding_status_filter'
import useDismantlePopup from '/src/ui/domain/scaffoldings/dismantle_popup'
import StatusFilterLabel from '/src/ui/core/grid/statuses_filter_label'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import InspectionsIcon from '/src/ui/core/icons/inspections_icon'
import VerticalDivider from '/src/ui/core/icons/vertical_divider'
import useConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import { printMenuItem, clearTemplatePreferencesMenuItem } from '/src/ui/core/grid/context_menu_entries'
import useModel from '/src/ui/core/forms/model_hook'

export default function ScaffoldingsGrid({ onRowClick, selectedItem, onDataSource, onGridColumns, onBulkEditing }) {
  const [scaffoldingModel] = useModel(new ScaffoldingModel())

  const [gridDataSource, setGridDataSource] = useState()
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [openDismantlePopup, renderDismantlePopup] = useDismantlePopup(selectedItem, scaffoldingModel)

  const scaffoldingContextMenu = useScaffoldingContextMenu({ showConfirmation, openDismantlePopup })
  const { statusFilterButton, selectedStatuses, clearFilters, statusFilter } = useScaffoldingStatusFilter()

  const batchedEntities = useEntitiesCache()

  const [openConfigurationPopup, printGridPopup, isPrintable] = useConfigurePrintGridFile(
    scaffoldingModel.columns,
    gridDataSource,
    scaffoldingModel
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(
    scaffoldingModel.templateId
  )

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick)
  ]

  const clearAllFiltersCallback = useCallback(() => {
    clearFilters()
  }, [clearFilters])

  const icons = [
    statusFilterButton,
    <InspectionsIcon key="inspections-icon" />,
    <VerticalDivider />,
    <BulkEditingIcon onClick={() => onBulkEditing('top')} />,
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <NewButton modelName={scaffoldingModel.paramName} opts={{ subGrid: false }} />
  ]

  const statusFilterLabel = useMemo(
    () =>
      selectedStatuses &&
      selectedStatuses.length > 0 && (
        <StatusFilterLabel key="scaffolding-status-filter" options={selectedStatuses} onClearFilters={clearFilters} />
      ),
    [selectedStatuses, clearFilters]
  )

  const filters = useMemo(() => (statusFilter ? [statusFilter] : undefined), [statusFilter])

  const [statuses] = useStore('scaffolding_statuses')

  const isScaffoldingUnableToInspection = (scaffolding) => {
    if (!statuses || !scaffolding || !Object.keys(statuses).length) return false
    const status = statuses[scaffolding.scaffolding_status_id]
    return status && status.i18n_id !== 'erected' && status.i18n_id !== 'ready_to_dismantle'
  }

  const onSetGridDataSource = useCallback(
    (dataSource) => {
      dataSource.data.map((data) => (
        // eslint-disable-next-line no-param-reassign
        data.checkboxDisabled = isScaffoldingUnableToInspection(data)
      ))
      if (onDataSource) onDataSource(dataSource)
      setGridDataSource(dataSource)
    },
    [onDataSource]
  )

  const onSetGridColumns = useCallback(
    (columns) => {
      const formattedColumns = ['scaffolding_status', 'area_noise_id', 'load_class_id']
      const foreignDropColumns = ['area', 'subarea', 'area_noise_id', 'load_class_id']
      const showColumns = ['tag_number']
      columns = subAreaToEditableGrid(columns)
      columns = formattedToEditableGrid(columns, formattedColumns, batchedEntities)
      columns = foreignDropToEditableGrid(columns, foreignDropColumns, batchedEntities)
      columns = showColumnsOnEditableGrid(columns, showColumns)
      if (onGridColumns) onGridColumns(columns)
    },
    [onGridColumns, batchedEntities]
  )

  return (
    <React.Fragment>
      {renderConfirmation()}
      {renderDismantlePopup()}
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      <SimpleGrid
        key="scaffoldings"
        templateId={scaffoldingModel.templateId}
        model={scaffoldingModel}
        gridTitle={I18n.t('grid.all')}
        labels={[statusFilterLabel]}
        icons={icons}
        filter={filters}
        clearAllFiltersCallback={clearAllFiltersCallback}
        columnCellFactory={<CellFactory type={scaffoldingModel.paramName} />}
        contextMenuItems={scaffoldingContextMenu}
        selectedItem={selectedItem}
        onRowClick={onRowClick}
        onDataSource={onSetGridDataSource}
        onGridColumns={onSetGridColumns}
        selecting
      />
      <ScaffoldingFormModal scaffoldingModel={scaffoldingModel} />
    </React.Fragment>
  )
}

ScaffoldingsGrid.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  onDataSource: PropTypes.func,
  onGridColumns: PropTypes.func,
  onBulkEditing: PropTypes.func
}

ScaffoldingsGrid.defaultProps = {
  selectedItem: undefined,
  onRowClick: () => {},
  onDataSource: () => {},
  onGridColumns: () => {},
  onBulkEditing: () => {}
}
