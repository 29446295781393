import React, { useState } from 'react'
import I18n from '/src/utils/translations'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { ReactComponent as NoteStackAddSvg } from '/public/static/svg/note_stack_add'
import '/src/static/css/core/icons/popup_icon.css'
export default function NoteStackAddIcon({}) {
  const [selectedRecords, setSelectedRecords] = useState([])
  
  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => {
      setSelectedRecords(payload)
    },
    [setSelectedRecords]
  )

  if (selectedRecords.length === 0) return null

  const buttonClass = 'batch-assigner-icon__button open-icon-popup btn-icon grid-header-icon'

  return (
    <div className="note-stack-add-icon">
      <CustomTooltip position="bottom" parentTitle>
        <button
          className={buttonClass}
          title={I18n.t('dialogs.duplicate_services.title')}
          type='button'
        >
          <NoteStackAddSvg />
        </button>
      </CustomTooltip>
    </div>
  )
}
