import { sortArrayOfObjectsByNumber } from '/src/utils/object'

export default function useFirstFlexibleColumn(columnsToFilter = ['string', 'formula']) {
  const filterFirstFlexibleColumn = (eavColumns) => {
    if (!eavColumns || eavColumns.length === 0) return

    const descriptionColumns = eavColumns.filter((col) => {
      return columnsToFilter.includes(col.column_type.description)
    })

    if (descriptionColumns.length === 0) return

    return sortArrayOfObjectsByNumber(descriptionColumns, 'position')[0]
  }

  return filterFirstFlexibleColumn
}
