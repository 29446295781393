import { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { isBlank, isBlankOrFalse, isBlankOrZero } from '/src/utils/boolean_refinements'
import { formatDate } from '/src/utils/project_formatter'
import { isEmpty } from '/src/utils/object'
import I18n from '/src/utils/translations'

function moveToInput(element) {
  if (element && element.nodeName === 'INPUT' && element.type !== 'checkbox') {
    element.focus()
    return true
  }

  return false
}

export function moveScreenToError() {
  const errorElement = document.querySelector('.input-error')
  if (!errorElement || moveToInput(errorElement)) return

  const elementClassName = errorElement.className
  const isUnfocusable =
    elementClassName.includes('k-timepicker') ||
    elementClassName.includes('k-datetimepicker') ||
    elementClassName.includes('radio-button')

  if (!isUnfocusable) {
    const errorElementInput = errorElement.querySelector('input')
    if (moveToInput(errorElementInput)) return
  }

  if (errorElement) errorElement.closest('.section-field').scrollIntoView()
}

/**
 * It will keep track and let the input know that are some error on the field
 *
 * @param inputValue - the value of the input
 * @param title - the title of the field to custom error message
 * @param required - boolean if or not the field is required
 * @param type - the type of the field e.g: date, date_range, drop and so on  (optional)
 * @returns custom error message
 */
export default function useInputError({ inputValue, title, required, min, max, type, dataItem, id }) {
  const [error, setError] = useState(null)
  const [project] = useStore('project')
  const isRequiredFunction = typeof required === 'function'

  useEffect(() => {
    if (isRequiredFunction) return
    setError(null)
  }, [inputValue, required, isRequiredFunction])

  const requiredCheck = () => {
    let isRequiredAndEmpty

    const isRequired = isRequiredFunction ? required(dataItem) : required

    if (inputValue && type === 'date_range')
      isRequiredAndEmpty = isRequired && (isBlank(inputValue.start) || isBlank(inputValue.end))
    else if (inputValue && type === 'array') isRequiredAndEmpty = isRequired && inputValue.length === 0
    else if (inputValue && type === 'object') isRequiredAndEmpty = isRequired && isEmpty(inputValue)
    else if (type === 'drop_formula') isRequiredAndEmpty = isRequired && isBlankOrZero(inputValue)
    else isRequiredAndEmpty = isRequired && isBlankOrFalse(inputValue)

    const requiredMsg = I18n.t('form.inputs.errors.mandatory', { field: title })
    setError(isRequiredAndEmpty ? requiredMsg : null)
  }

  const minMaxCheck = () => {
    let errorMsg = null

    if (inputValue && type === 'date') {
      if (min && inputValue < min) errorMsg = I18n.t('form.inputs.errors.date_less_than_min',
        { min: formatDate(min, project) })
      if (max && inputValue > max) errorMsg = I18n.t('form.inputs.errors.date_greater_than_max',
        { max: formatDate(max, project) })
    }

    if (errorMsg != null) setError(errorMsg)
  }

  const validation = (skipValidation) => {
    if (skipValidation && skipValidation.includes(id)) return

    requiredCheck()
    minMaxCheck()
  }

  useBus(BusEvents.INPUT_VALIDATE, ({ skipValidation }) => validation(skipValidation), [inputValue, required, min, max])

  return error
}
