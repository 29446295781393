/* eslint-disable no-restricted-syntax */
import { query, filterToQuery } from '/src/hooks/api/query'

const buildBasePath = (opts) => {
  const { resourcePath, resourceId, additionalResource, serverUrl } = opts
  let baseUrl = `${serverUrl}/api/v1/${resourcePath}`

  if (resourceId) {
    baseUrl += `/${resourceId}`
  }

  if (additionalResource && additionalResource.path) baseUrl += `/${additionalResource.path}`

  if (additionalResource && additionalResource.resourceId) {
    baseUrl += `/${additionalResource.resourceId}`
  }

  return baseUrl
}

const includePagingOptions = (pagingOptions) => {
  if (!pagingOptions) return ''
  let sortingUrl = ''
  if (pagingOptions.sorting) {
    pagingOptions.sorting.forEach((sortItem) => {
      const field = sortItem.realSort || sortItem.field
      const orderQuery = `&query[order][${field}]=${sortItem.dir}`
      sortingUrl += orderQuery
    })
  }
  let pagingUrl = ''

  if (pagingOptions.paging) pagingUrl = `&page_size=${pagingOptions.paging.pageSize}&skip=${pagingOptions.paging.skip}`
  return `${sortingUrl}${pagingUrl}`
}

const additionalParam = (opts) => (
  opts.additionalParam ?
    `&${opts.additionalParam}`
    : ''
)

const buildUrlQueries = (baseUrl, opts, pagingOptions) => {
  const queryParams = {
    ...opts.query,
    projectId: opts.globalProject.id,
    subprojectId: opts.globalSubproject.id
  }

  return `${baseUrl}${query(queryParams)}${includePagingOptions(pagingOptions)}${additionalParam(opts)}`
}

// TODO: split function below into multiple functions in order to reduce the amount of lines
// eslint-disable-next-line max-lines-per-function
const buildUrl = (opts) => {
  const {
    httpAction,
    dataOptions,
    resourceId,
    additionalResource,
    resourcePath,
    serverUrl,
    globalSubproject,
    globalProject
  } = opts
  const { paging, filtering, sorting, tabbing } = dataOptions || {
    paging: null,
    filtering: null,
    sorting: null
  }

  const baseOpts = { httpAction, resourcePath, resourceId, additionalResource, serverUrl }
  const baseUrl = buildBasePath(baseOpts)

  if (opts.query) {
    return buildUrlQueries(baseUrl, opts, { paging, sorting })
  }

  const queryString = []

  const isProjectIdStored = globalProject.id && globalSubproject.id

  if (isProjectIdStored) {
    queryString.push(`subproject_id=${globalSubproject.id}&project_id=${globalProject.id}`)
  }

  if (paging) queryString.push(`page_size=${paging.pageSize}&skip=${paging.skip}`)
  if (filtering && filtering.filter.filters.length > 0) {
    filtering.filter.filters.forEach((filter) => queryString.push(filterToQuery(filter)))
  }
  if (tabbing) queryString.push(`query[where][eav_template_id]=${tabbing}`)
  if (sorting) {
    sorting.forEach((sortItem) => {
      const field = sortItem.realSort || sortItem.field
      const orderQuery = `query[order][${field}]=${sortItem.dir}`
      queryString.push(orderQuery)
    })
  }

  return `${baseUrl}?${queryString.join('&')}`
}

export default buildUrl

//  Input: { where: { id: 1 } }
// Output: [{ type: 'where', column: 'id', values: 1 }]
export const convert = (backendQuery) => {
  const frontendQuery = []

  for (const [type, columnValue] of Object.entries(backendQuery)) {
    for (const [column, value] of Object.entries(columnValue)) {
      if (typeof value === 'object' && value !== null) {
        for (const [nestedColumn, nestedValue] of Object.entries(value)) {
          frontendQuery.push({ type, column: `${column}][${nestedColumn}`, value: nestedValue })
        }
      } else {
        frontendQuery.push({ type, column, value })
      }
    }
  }

  return frontendQuery
}
