import React from 'react'
import PropTypes from 'prop-types'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import i18n from 'i18n-js'
import { pluralize } from 'inflected'
import formatBytes from '/src/utils/converter'
import '/src/static/css/core/progress_bars/attachment_upload_progress_bar.css'

export default function AttachmentUploadProgress({
  currentProgress,
  maxProgress,
  attachmentTypeTranslation,
  filesSize
}) {
  return (
    <div className="attachment-upload-progress">
      <div className="attachment-upload-progress__title">
        <span>
          {currentProgress}
          /
          {maxProgress}
        </span>
        <span>{pluralize(i18n.t(`${attachmentTypeTranslation}`))}</span>
        <span className='attachment-upload-progress__title__files-size'>
          (
          {formatBytes(filesSize)}
          )
        </span>
      </div>
      <div className="attachment-upload-progress__progress-bar">
        <ProgressBar className="progress-bar--green" max={maxProgress} value={currentProgress} labelVisible={false} />
      </div>
    </div>
  )
}

AttachmentUploadProgress.propTypes = {
  currentProgress: PropTypes.number.isRequired,
  maxProgress: PropTypes.number.isRequired,
  attachmentTypeTranslation: PropTypes.string.isRequired,
  filesSize: PropTypes.number.isRequired
}
