import React from 'react'
import PropTypes from 'prop-types'
import { Link } from 'react-router-dom'
import { fullTitleDisplay, titleLimiter } from '/src/utils/string'

export default function CategoryItem({ category }) {
  const { id, description } = category
  const lenghtLimit = 20

  return (
    <Link to={`/datasheets?filter=datasheet_category_id:${id}`} title={fullTitleDisplay(description, lenghtLimit)}>
      {titleLimiter(description, lenghtLimit)}
    </Link>
  )
}

CategoryItem.propTypes = {
  category: PropTypes.shape({
    id: PropTypes.number,
    description: PropTypes.string
  }).isRequired
}
