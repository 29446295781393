import React, { useState } from 'react'
import I18n from '/src/utils/translations'
import { MdChecklist } from 'react-icons/md'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import InspectionModel from '/src/models/inspection'

export default function InspectionsIcon() {
  const [selectedRows, setSelectedRows] = useState([])

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ payload }) => {
      setSelectedRows(payload)
    },
    []
  )

  const handleBatchInspectionClick = () => {
    dispatch({
      type: BusEvents.OPEN_MODAL,
      triggeredModelName: InspectionModel.paramName,
      payload: { dataItem: { items: selectedRows } }
    })
  }

  if (selectedRows.length <= 1) return null

  return (
    <CustomTooltip position="bottom" parentTitle>
      <button
        id="inspections-btn"
        type="button"
        title={I18n.t(`actions.create_inspection`)}
        data-testid="inspections-btn"
        className="btn-icon grid-header-icon"
        onClick={handleBatchInspectionClick}
      >
        <MdChecklist />
      </button>
    </CustomTooltip>
  )
}
