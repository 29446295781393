/* eslint-disable jsx-a11y/aria-role */
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdEdit, MdCalculate } from 'react-icons/md'
import { useHistory } from 'react-router-dom'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { isScaffoldingDismantled } from '/src/utils/constants/scaffolding'
import QRCode from 'qrcode.react'
import ScaffoldingStatusLabel from '/src/ui/domain/scaffoldings/scaffolding_status_label'
import Button from '/src/ui/core/buttons/button'
import ScaffoldingPartModel from '/src/models/scaffolding_part'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PopupButton from '/src/ui/core/buttons/popup_button'
import '/src/static/css/core/blocks/request_detail_summary.css'
import '/src/static/css/core/blocks/track_block.css'

export default function ScaffoldingPartDetailSummary({ scaffolding, dataItem }) {
  const scaffoldingPartModel = new ScaffoldingPartModel()
  const history = useHistory()
  const [statuses] = useStore('scaffolding_statuses')

  const dismantled = useMemo(() => isScaffoldingDismantled(statuses, scaffolding), [statuses, scaffolding])

  const contextMenuIcons = [
    {
      text: I18n.t('scaffolding_parts.menu_items.modifications'),
      icon: <MdCalculate />,
      onClick: () => {
        dispatch({
          type: BusEvents.OPEN_SIDE_PANEL_FORM,
          formType: 'new',
          actionType: 'modification'
        })
      },
      visible: () => dataItem.total > 0
    },
    {
      text: I18n.t('requests.request_detail.edit'),
      icon: <MdEdit />,
      onClick: () => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: scaffoldingPartModel.paramName,
          payload: { dataItem }
        })
      }
    }
  ]

  const tagNumber = `#${scaffolding.tag_number}`

  return (
    <div className="detail-summary">
      <div className="request-main-info">
        <div>
          <div className="title-side-panel">
            {I18n.t('scaffoldings.tag')}
            <span className="summary-request-id">{tagNumber}</span>
          </div>
          <div className="detail-row">
            <ScaffoldingStatusLabel dataItem={scaffolding} />
          </div>
        </div>
        {scaffolding.qr_code && <QRCode value={scaffolding.qr_code} size={76} />}
      </div>
      <div className="detail-field" data-testid="detail-reference-title">
        <span>{I18n.t('scaffoldings.reason')}</span>
        <p>
          {scaffolding.main_work_order_erect
            ? `${scaffolding.main_work_order_erect.reason}
                ${scaffolding.main_work_order_erect.comments}`
            : I18n.t('fields.na')}
        </p>
        <span>{I18n.t('scaffoldings.description')}</span>
        <p>{scaffolding.description}</p>
      </div>
      {history && (
        <div className='actions-row'>
          <div className='track-block-buttons'>
            {!dismantled && (
              <Button
                text={I18n.t('scaffolding_parts.menu_items.dimensions')}
                icon={<MdCalculate />}
                className='edit-button-track-block'
                onClick={() => {
                  dispatch({
                    type: BusEvents.OPEN_SIDE_PANEL_FORM,
                    formType: 'new',
                    actionType: 'dimension'
                  })
                }}
              />
            )}
            {!dismantled && (
              <PopupButton
                items={contextMenuIcons}
                text={I18n.t('actions.more')}
                role='show-button'
              />
            )}
          </div>
        </div>
      )}
    </div>
  )
}

ScaffoldingPartDetailSummary.propTypes = {
  scaffolding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  dataItem: PropTypes.oneOfType([PropTypes.object]).isRequired
}
