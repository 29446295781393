import React from 'react'
import PropTypes from 'prop-types'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'
import '/src/static/css/inputs.css'

export default function InputText({ inputProps }) {
  const { id, className, testId, name, placeholder, title, value, onChange, onBlur, onFocus, required, readOnly } =
    inputProps

  const textValue = value ? `${value}` : ''

  const [inputValue, onInputChange] = useInputChange({ id, value: textValue, onChange })
  const error = useInputError({ inputValue, title, required })

  const inputClass = () => {
    const classes = ['form-input-text']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  return (
    <React.Fragment>
      <input
        id={id}
        className={inputClass()}
        name={name}
        placeholder={placeholder}
        value={inputValue || ''}
        onChange={onInputChange}
        onBlur={onBlur}
        onFocus={onFocus}
        data-testid={testId}
        disabled={readOnly}
        type="text"
      />
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

InputText.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    testId: PropTypes.string,
    name: PropTypes.string,
    title: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    readOnly: PropTypes.bool
  }).isRequired
}
