import React, { useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { useQueryParam, NumberParam } from 'use-query-params'
import ScopeModel from '/src/models/scope'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import useRequestGridFilter from '/src/hooks/request_grid_filter'
import useRequestsPinned from '/src/hooks/requests_pinned'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import NewButton from '/src/ui/core/icons/new_button'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import VerticalDivider from '/src/ui/core/icons/vertical_divider'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import InspectionsIcon from '/src/ui/core/icons/inspections_icon'
import RequestEstimatesIcon from '/src/ui/core/icons/request_estimates_icon'
import {
  importMenuItem,
  exportMenuItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import EstimateFormModal from '/src/ui/domain/estimates/estimate_form_modal'
import useConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import { isPresent } from '/src/utils/boolean_refinements'
import { translateFormulaServiceColumns } from '/src/ui/domain/formulas_services/formulas_services_utils'
import I18n from '/src/utils/translations.js'
import '/src/static/css/core/grid/grid.css'

// eslint-disable-next-line max-lines-per-function
export default function ScopesGrid({
  selectedItem,
  onRowClick,
  bulkEditItemVisible,
  onBulkEditItemClick,
  onDataSource,
  onGridColumns,
  contextMenuItems,
  templateTitle,
  selectFiltering
}) {
  const scopeModel = new ScopeModel()
  const [gridColumns, setGridColumns] = useState([])
  const [gridDataSource, setGridDataSource] = useState()
  const [openImport, setOpenImport] = useState(false)
  const [templateId] = useQueryParam('eav_template_id', NumberParam)

  const { selectedRequests } = useRequestsPinned()
  const hasSubRequestPinned = Object.values(selectedRequests).find(({ parent_id }) => isPresent(parent_id))

  const { filter, gridTitle, labels } = useRequestGridFilter({
    route: scopeModel.route,
    templateId
  })

  const scopesFilter = useMemo(
    () => [...filter, { type: 'withValue', column: 'request_status', value: true }],
    [filter]
  )

  const exportParams = { entity: 'scopes', templateId, isApi: true }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)
  const exportIcon = exportMenuItem(linkExport, { templateTitle, model: scopeModel })

  const [openConfigurationPopup, printGridPopup, isPrintable] = useConfigurePrintGridFile(
    gridColumns,
    gridDataSource,
    scopeModel
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(templateId)

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick),
    importMenuItem(setOpenImport)
  ].concat(!templateTitle ? [] : [exportIcon])

  const toggleViewObj = {
    type: 'toggle_view',
    path: 'estimate_services',
    title: I18n.t('actions.alternate_view')
  }
  const icons = [
    !hasSubRequestPinned && 'label',
    !hasSubRequestPinned && 'clustered',
    'batch_assigner',
    'note_stack_add',
    <RequestEstimatesIcon key="request-estimates-icon" selectedRequests={[...Object.values(selectedRequests)]} />,
    <InspectionsIcon key="inspections-icon" />,
    <VerticalDivider />,
    toggleViewObj,
    bulkEditItemVisible('top') && <BulkEditingIcon onClick={() => onBulkEditItemClick('top')} />,
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <NewButton modelName={scopeModel.paramName} opts={{ subGrid: false }} />
  ]

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  const onSetGridColumns = (columns) => {
    setGridColumns(columns)
    onGridColumns(columns)
  }

  const onSetGridDataSource = (dataSource) => {
    setGridDataSource(dataSource)
    if (onDataSource) onDataSource(dataSource)
  }

  if (templateId) translateFormulaServiceColumns(scopeModel, templateId)

  return (
    <div className={selectedItem && selectFiltering ? 'scope-item-selected' : 'scope-item-not-selected'}>
      {openImport && (
        <PopupImportFile modelRoute={scopeModel.route} templateId={templateId} onClose={onClosePopupImport} />
      )}
      {printGridPopup}
      {clearTemplatePreferencesPopup()}
      <EstimateFormModal />
      <TemplatableGrid
        contextMenuItems={contextMenuItems}
        model={scopeModel}
        labels={labels}
        filter={scopesFilter}
        gridTitle={gridTitle}
        onFilterUpdate={setExportFilters}
        icons={icons}
        selectedItem={selectedItem}
        onRowClick={onRowClick}
        onDataSource={onSetGridDataSource}
        onGridColumns={onSetGridColumns}
        selectFiltering={selectFiltering}
        selecting
      />
    </div>
  )
}

ScopesGrid.propTypes = {
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  onRowClick: PropTypes.func,
  onBulkEditItemClick: PropTypes.func.isRequired,
  onGridColumns: PropTypes.func.isRequired,
  onDataSource: PropTypes.func.isRequired,
  bulkEditItemVisible: PropTypes.func.isRequired,
  templateTitle: PropTypes.string,
  contextMenuItems: PropTypes.arrayOf(
    PropTypes.shape({
      text: PropTypes.string.isRequired,
      icon: PropTypes.element,
      onClick: PropTypes.func.isRequired,
      visible: PropTypes.func
    })
  ).isRequired
}

ScopesGrid.defaultProps = {
  selectedItem: undefined,
  onRowClick: () => {},
  templateTitle: ''
}
