import { useEffect } from 'react'
import { isPresent } from '/src/utils/boolean_refinements'

export default function useEditableGridCounter(dataSource, onUpdate) {
  useEffect(() => {
    onUpdate(
      dataSource.filter(({ dirty, virtualKey, to_be_deleted: toDelete }) => (
        dirty || isPresent(virtualKey) || toDelete
      )).length
    )
  }, [onUpdate, dataSource])
}
