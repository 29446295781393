import I18n from '/src/utils/translations'
import { isPresent } from '/src/utils/refinements'

const translationPath = 'inspections.inspected_modules'

const inspectedFormatter = (inspected, type) => {
  const inspected_type = isPresent(type) ? I18n.t([translationPath, type.toLowerCase()].join('.')) : ''

  if (isPresent(inspected.number) && isPresent(inspected.eav_template) && isPresent(type)) {
    return `#${inspected.number} - ${inspected_type} - ${inspected.eav_template.title}`
  }

  if (isPresent(type) && type === 'Scaffolding' && isPresent(inspected.tagNumber) && isPresent(inspected.description)) {
    return `TAG #${inspected.tagNumber} - ${inspected.description}`
  }

  return inspected_type
}

export default inspectedFormatter
