/* eslint-disable import/prefer-default-export */
/* eslint-disable no-param-reassign */

import { camelize, pluralize, singularize } from 'inflected'

/**
 * Force a hidden column to be visible on editable grid. The column is identified by its description.
 * @param {Array} columns - The array with all the model's columns.
 * @param {string|string[]} description
 * - A string, or array of strings, with the description of the columns to be forced to shown.
 * @returns {Array} The entire array of columns, but with the selected column now visible.
 */
export function forceShowColumnsOnEditableGrid(columns, description) {
  const descriptions = typeof description === 'string' ? [description] : description

  return columns.map((column) =>
    descriptions.includes(column.description)
      ? {
        ...column,
        hideOnForm: false
      }
    : column
    )
  }

export function hideColumnsOnSubgrid(columns) {
  const newColumns = columns?.map((column) => {
    if (column.hideOnSubGrid) {
      return ({
        ...column,
        hideOnForm: true,
        hideOnGrid: true
      })
    }
    return column
  })

  return newColumns
}

export function dpmsIdToEditableGrid(columns, requestId) {
  const isColumnRequestId = (column) => column.description === 'request_id'

  const requestIdColumn = columns.find((column) => isColumnRequestId(column))
  const requestColumn = columns.find((column) => column.description === 'request')

  if (!requestIdColumn || !requestColumn) return columns

  const newRequestColumn = {
    ...requestColumn,
    ...requestIdColumn,
    hideOnGrid: false,
    hideOnForm: false,
    type: requestColumn.type,
    blockPreviousItemEdition: true,
    allowOnEditableGrid: requestId === undefined
  }

  Object.keys(newRequestColumn).forEach((key) => (requestIdColumn[key] = newRequestColumn[key]))

  const newColumns = columns.filter((column) => column.description !== 'request')
  newColumns.forEach((column) => {
    if (isColumnRequestId(column) && requestId) column.allowOnEditableGrid = false
  })

  return newColumns
}

export function contractServiceToEditableGrid(columns, opts) {
  const newColumns = columns.map((column) => {
    if (column.description === 'contract_service') {
      return {
        ...column,
        field: 'contract_service.description',
        type: 'search',
        blockPreviousItemEdition: true,
        allowOnEditableGrid: true,
        searchFields: ['id', 'description'],
        searchExtraQuery: {
          where: {
            discipline_id: opts.discipline_id
          }
        },
        searchRoute: 'contract_services/latest_prices',
        textDisplayFields: ['description'],
        textField: 'description'
      }
    }

    return column
  })

  return newColumns
}

export function showColumnsOnEditableGrid(columns, descriptions) {
  return columns.map((column) =>
    descriptions.includes(column.description)
      ? {
          ...column,
          hideOnForm: false,
          hideOnGrid: false,
          allowOnEditableGrid: true
        }
      : column
  )
}

export function subRequestToEditableGrid(columns) {
  return columns.map((c) => (c.description === 'sub' ? { ...c, hideLockIcon: true } : c))
}

export function editColumnsOnEditableGrid(columns, descriptions) {
  return columns.map((column) =>
    descriptions.includes(column.description)
      ? {
          ...column,
          editable: true
        }
      : column
  )
}

export function foreignDropToEditableGrid(columns, descriptions, batchedEntities) {
  return columns.map((column) => {
    const foreignKey = column.foreignKey && camelize(pluralize(column.foreignKey), false)
    return descriptions.includes(column.description)
      ? {
          ...column,
          description: column.foreignAttribute,
          metadata: JSON.stringify(Object.values(batchedEntities[foreignKey])),
          dataItemFieldToDisplay: column.description,
          field: `${column.description}.description`
        }
      : column
  })
}

export function formattedToEditableGrid(columns, descriptions, batchedEntities) {
  return columns.map((column) =>
    descriptions.includes(column.description)
      ? {
          ...column,
          fieldFormatter: (dataItem) => {
            const entitykey = camelize(pluralize(column.foreignKey), false)
            const batchEntity = batchedEntities[entitykey]

            return column.fieldFormatter({
              ...dataItem,
              [singularize(column.foreignKey)]: batchEntity && batchEntity[dataItem[column.description]]
            })
          }
        }
      : column
  )
}

export function multilineTextToEditableGrid(columns) {
  return columns.map((c) => (c.type === 'multiline_text' ? { ...c, useStringInput: true } : c))
}

export function subAreaToEditableGrid(columns) {
  return columns.map((column) =>
    column.description === 'subarea'
      ? {
          ...column,
          type: 'drop'
        }
      : column
  )
}

export function fillDefaultToEditableGrid(columns, columnDescription, defaultValue) {
  return columns.map((column) =>
    column.description === columnDescription
      ? {
          ...column,
          default: defaultValue
        }
      : column
  )
}

export function includeDefaultValuesOnModel(contract, requestModel, onChangeColumn) {
  if (
    !contract ||
    !requestModel ||
    !onChangeColumn ||
    contract.skip_scoping === undefined ||
    contract.skip_scoping === null ||
    contract.estimates_authorization === undefined ||
    contract.estimates_authorization === null
  )
    return
  const { columns } = requestModel
  const skipScoping = columns.findIndex((column) => column.description === 'skip_scoping')
  columns[skipScoping].default = contract.skip_scoping
  const authorization = columns.findIndex((col) => col.description === 'estimates_authorization')
  columns[authorization].default = contract.estimates_authorization
  onChangeColumn('skip_scoping', contract.skip_scoping)
  onChangeColumn('estimates_authorization', contract.estimates_authorization)
}

export function progressToEditableGrid(columns) {
  return columns.map((c) =>
    c.description === 'progress' || c.description === 'actual_progress'
      ? {
          ...c,
          hideOnForm: false,
          description: 'actual_progress',
          allowOnEditableGrid: true,
          showCellFromFactory: true,
          foreignAttribute: 'actual_progress',
          min: 0
        }
      : c
  )
}
