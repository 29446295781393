import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import { useQueryParam, NumberParam } from 'use-query-params'
import I18n from '/src/utils/translations'
import { extractRouteFromHistory } from '/src/utils/url'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import ColumnsBatchAssigner from '/src/ui/core/popups/columns_batch_assigner'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import PropTypes from 'prop-types'
import { ReactComponent as DynamicForm } from '/public/static/svg/dynamic-form'
import '/src/static/css/core/icons/popup_icon.css'

export default function BatchAssignerIcon({ modelName }) {
  const [eavTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const [isPopupOpen, setIsPopupOpen] = useState(false)
  const [selectedRecords, setSelectedRecords] = useState([])
  const history = useHistory()

  useBus(
    BusEvents.SELECTED_ROWS,
    ({ paramName, payload }) => {
      if (paramName === modelName) {
        setSelectedRecords(payload)
      }
    },
    [modelName, setSelectedRecords]
  )

  const handleSuccessfulBatchUpdate = () => {
    setIsPopupOpen(false)
    dispatch(BusEvents.RELOAD_GRID)
    setSelectedRecords([])
  }

  if (selectedRecords.length === 0) return null

  const buttonClass = 'batch-assigner-icon__button open-icon-popup btn-icon grid-header-icon'

  return (
    <div className="batch-assigner-icon">
      <CustomTooltip position="bottom" parentTitle>
        <button
          className={buttonClass + (isPopupOpen ? ' active-button' : '')}
          title={I18n.t('dialogs.columns_batch_assigner.header')}
          onClick={() => setIsPopupOpen(true)}
          type='button'
        >
          <DynamicForm />
        </button>
      </CustomTooltip>

      {isPopupOpen && (
        <ColumnsBatchAssigner
          isOpen={isPopupOpen}
          eavTemplateId={eavTemplateId}
          onClose={() => setIsPopupOpen(false)}
          selectedRecords={selectedRecords}
          modelRoute={extractRouteFromHistory(history)}
          onSuccessfulUpdate={handleSuccessfulBatchUpdate}
          modelName={modelName}
        />
      )}
    </div>
  )
}

BatchAssignerIcon.propTypes = {
  modelName: PropTypes.string.isRequired
}
