import React, { useCallback, useEffect, useState } from 'react'
import lodash from 'lodash'
import { useHistory } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFetch from '/src/hooks/api/fetch'
import ScopeModel from '/src/models/scope'
import { deleteMenuItem, duplicateModalMenuItem, editModalMenuItem } from '/src/ui/core/grid/context_menu_entries'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import Layout from '/src/ui/core/layouts/layout'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { templateType } from '/src/models/concerns/template_types'
import EstimateServiceModel from '/src/models/estimate_service'
import EstimateServicePackageableModel from '/src/models/estimate_service_packageable'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import ChooseEstimateFormModal from '/src/ui/domain/estimate_services/choose_estimate_form_modal'
import EstimateServiceForm from '/src/ui/domain/estimate_services/estimate_service_form'
// eslint-disable-next-line max-len
import EstimateServicePackageableForm from '/src/ui/domain/estimate_services/estimate_service_packageable_form'
import LoadingGrid from '/src/ui/core/grid/loading_grid'
import EstimateServicesGrid from '/src/ui/domain/estimate_services/estimate_services_grid'
import useGetEstimate from '/src/ui/domain/estimate_services/get_estimate_hook.jsx'
import ScopingServicesSidePanel from '/src/ui/domain/scopes/scoping_services_side_panel'
import { contractServiceToEditableGrid, forceShowColumnsOnEditableGrid } from '/src/utils/columns_formatter'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import { byString } from '/src/utils/object'
import { indexify } from '/src/utils/array'

const estimateServiceModel = new EstimateServiceModel()

// eslint-disable-next-line max-lines-per-function
export default function EstimatesServicesPage() {
  const scopeModel = new ScopeModel()
  const [loading, setLoading] = useState(true)
  const [itemTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const history = useHistory()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  const [selectServiceId, setSelectServiceId] = useQueryParam('select_service_id', NumberParam)

  // Service Related
  const { fetch } = useFetch()
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [serviceSidePanelDataItem, setServiceSidePanelDataItem] = useState()
  const [serviceSectionsColumns, setServiceSectionsColumns] = useState([])
  const [serviceTemplate, setServiceTemplate] = useState('fetching')
  const [modalEstimateService, setModalEstimateService] = useState(false)
  const estimateServicePackageableModel = new EstimateServicePackageableModel()
  const estimate = useGetEstimate()

  // Scope related
  const [scopeTemplate, setScopeTemplate] = useState()
  const [scopeColumns, setScopeColumns] = useState()

  // Editable Grid
  const restablishGridSettings = () => ({
    selectedService: serviceSidePanelDataItem
  })

  const onCreateNewItem = (dataItem) => ({
    ...dataItem,
    discipline_id: serviceTemplate.discipline_id,
    eav_template_id: serviceTemplate.id
  })

  const editableGridProps = {
    topEntity: {
      model: estimateServiceModel,
      onCreateNewItem
    },
    recoverSettings: restablishGridSettings,
    allowCreate: true,
    allowDelete: true,
    tabTemplate: scopeTemplate,
    filterStepsModalOpts: { scopeColumns }
  }

  const [editableGrid, setInEditMode, editableTopGridColumns, editableTopGridDataSource] =
    useEditableGrid(editableGridProps)

  const bulkEditItemVisible = () => {
    return (
      editableTopGridDataSource
      && editableTopGridDataSource.get
      && !editableTopGridDataSource.get.loading
    )
  }

  const estimatesBulkEditItemVisible = () => {
    return bulkEditItemVisible()
  }

  const onSetTopGridColumns = (columns) => {
    const scopeColumnVisible = forceShowColumnsOnEditableGrid(columns, 'scope')
    const requestIdColumnVisible = forceShowColumnsOnEditableGrid(scopeColumnVisible, 'request_id')

    editableTopGridColumns.set(
      contractServiceToEditableGrid(requestIdColumnVisible, {
        discipline_id: byString(serviceTemplate, 'discipline_id')
      })
    )
  }

  const onTemplateChange = (template) => {
    setScopeTemplate(template)
  }

  const handleCloseSidePanel = () => {
    setServiceSelectedItem()
    setServiceSidePanelDataItem()

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const fetchRequests = useCallback((dataSource) => {
    if (
      !dataSource ||
      dataSource.length === 0 ||
      dataSource.every((dataItem) => dataItem?.request?.request_status_id )
    ) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: dataSource.map((request) => request?.request_id) }
      }
    }

    const scopesById = indexify(dataSource, 'id')
    fetch('requests', queryParams, {
      onSuccess: ({ data: { data } }) => {
        const modelName = estimateServiceModel.paramName

        const requestsById = indexify(data, 'id')
        const onSetDataSource = (prevDataSource) => ({
          ...prevDataSource,
          data: prevDataSource.data.map((item) => ({
            ...item,
            request: requestsById[scopesById[item.scope_id].request_id]
          }))
        })

        dispatch({
          type: BusEvents.UPDATE_GRID_DATA_SOURCE,
          payload: { modelName, onSetDataSource }
        })

      }
    })
  }, [fetch])

  const fetchScopes = useCallback(({ data: dataSource }) => {
    if (
      !dataSource ||
      dataSource.length === 0 ||
      dataSource.every((dataItem) => dataItem.scope)
    ) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: dataSource.map(({ scope_id: scopeID }) => scopeID) }
      }
    }
    
    fetch('scopes', queryParams, {
      onSuccess: ({ data: { data } }) => {
        const modelName = estimateServiceModel.paramName

        fetchRequests(data)

        const scopesById = indexify(data, 'id')
        const onSetDataSource = (prevDataSource) => ({
          ...prevDataSource,
          data: prevDataSource.data.map((item) => ({
            ...item, 
            scope: scopesById[item.scope_id],
            request_id: scopesById[item.scope_id]?.request_id
          }))
        })

        dispatch({  
          type: BusEvents.UPDATE_GRID_DATA_SOURCE,
          payload: { modelName, onSetDataSource }
        })

      }
    })
  }, [fetch,fetchRequests])

  const onServiceGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableTopGridDataSource.set(dataSource)

    fetchScopes(dataSource)

    const newSelectedService = dataSource.data.filter(
      (item) => item.id === selectServiceId || (serviceSelectedItem && item.id === serviceSelectedItem.id)
    )[0]

    if (!isEqual(serviceSelectedItem, newSelectedService)) {
      if (selectServiceId) setSelectServiceId()
      setServiceSelectedItem(newSelectedService)
      setServiceSidePanelDataItem(newSelectedService)

      setColumnStyles(SIDE_PANEL_OPENED)
    }
  }

  const handleDeletedEstimateService = () => {
    setServiceSelectedItem()
    setServiceSidePanelDataItem()
  }

  const estimateServicesMenuItems = {
    edit: editModalMenuItem(
      () => true,
      (_, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: estimateServiceModel.paramName,
          payload: {
            dataItem,
            formType: 'edit',
            opts: {
              scopeId: dataItem.scope_id,
              templateId: serviceTemplate.id,
              disciplineId: serviceTemplate.discipline_id
            }
          }
        })
    ),
    duplicate: duplicateModalMenuItem(
      () => true,
      (_, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: estimateServiceModel.paramName,
          payload: {
            dataItem,
            formType: 'duplicate',
            opts: {
              scopeId: dataItem.scope_id,
              templateId: serviceTemplate.id,
              disciplineId: serviceTemplate.discipline_id
            }
          }
        })
    ),
    remove: deleteMenuItem(history, estimateServiceModel, showConfirmation, handleDeletedEstimateService, () => true)
  }

  // Service
  const onServiceRowClick = useCallback((e) => {
    setServiceSelectedItem((prevServiceSelectedItem) => {
      let newItem = e.dataItem
      if (prevServiceSelectedItem && prevServiceSelectedItem.id === e.dataItem.id) {
        newItem = undefined
        handleCloseSidePanel()
      } else {
        setColumnStyles(SIDE_PANEL_OPENED)
      }

      setServiceSidePanelDataItem(newItem)
      return newItem
    })
  }, [])

  const fetchServiceTemplate = useCallback(
    (parentTemplateId) => {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: { parent_template_id: parentTemplateId, template_type: 'estimate_service' }
        }
      }

      fetch('eav_templates', params, {
        onSuccess: ({ data }) => {
          const responseData = data.data
          if (!responseData) return
          setServiceTemplate(responseData[0])
        }
      })
    },
    [fetch]
  )

  const fetchServiceSections = useCallback(
    (fetchTemplateId) => {
      const params = {
        httpAction: 'get',
        additionalResource: { path: 'eav_columns' },
        query: {
          where: {
            'eav_templates][parent_template_id': fetchTemplateId,
            'eav_templates][template_type': templateType.estimate_service
          }
        }
      }

      fetch('eav_sections', params, {
        onSuccess: ({ data }) => {
          const newSectionsColumns = filterVisibleOnWeb(data)
          setServiceSectionsColumns(newSectionsColumns)
          setLoading(false)
        }
      })
    },
    [fetch]
  )

  const fetchScopeColumns = useCallback((templateId) => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { eav_template_id: [templateId] } }
    }

    fetch('eav_columns', params, {
      onSuccess: ({ data }) => {
        const { data: eavColumns } = data
        setScopeColumns(eavColumns)
      }
    })
  }, [fetch])

  // Service
  useEffect(() => {
    if (!itemTemplateId) return
    setLoading(true)
    setServiceTemplate(null)

    fetchServiceTemplate(itemTemplateId)
    fetchServiceSections(itemTemplateId)
    fetchScopeColumns(itemTemplateId)
    handleCloseSidePanel()
  }, [itemTemplateId, fetchServiceTemplate, fetchServiceSections, fetchScopeColumns])

  return (
    <Layout>
      {renderConfirmation()}
      {editableGrid(
        <React.Fragment>
          <TabsWrapper tabType={scopeModel.templateType} onTemplateChange={onTemplateChange} />
          <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
            <div className="unshadowed-page" key={itemTemplateId}>
              {modalEstimateService && (
                <ChooseEstimateFormModal
                  onClose={() => setModalEstimateService(false)}
                  opts={{
                    service: serviceSelectedItem,
                    serviceTemplate,
                    modelName: estimateServiceModel.paramName,
                    packageableModelName: estimateServicePackageableModel.name,
                    setModalEstimateService
                  }}
                />
              )}
              {!loading ? (
                <EstimateServicesGrid
                  selectedItem={serviceSelectedItem}
                  onRowClick={onServiceRowClick}
                  onBulkEditItemClick={setInEditMode}
                  onGridColumns={onSetTopGridColumns}
                  onDataSource={onServiceGridDataSource}
                  bulkEditItemVisible={estimatesBulkEditItemVisible}
                  template={serviceTemplate}
                  contextMenuItems={Object.values(estimateServicesMenuItems)}
                  isPrimaryGrid
                />
              ) : (
                <LoadingGrid gridTitle={estimateServiceModel.name} model={estimateServiceModel} />
              )}
            </div>
            <>
              {serviceSidePanelDataItem && (
                <ScopingServicesSidePanel
                  dataItem={serviceSidePanelDataItem}
                  sections={serviceSectionsColumns}
                  scopeSections={serviceSectionsColumns}
                  onClose={handleCloseSidePanel}
                  contextMenuItems={estimateServicesMenuItems}
                />
              )}
            </>
            <GridFormModal modelName={estimateServiceModel.paramName}>
              <EstimateServiceForm estimateId={estimate.id} />
            </GridFormModal>
            <GridFormModal modelName={estimateServicePackageableModel.name}>
              <EstimateServicePackageableForm estimateId={estimate.id} />
            </GridFormModal>
          </LayoutPanels>
        </React.Fragment>
      )}
    </Layout>
  )
}

EstimatesServicesPage.propTypes = {}

EstimatesServicesPage.defaultProps = {}
