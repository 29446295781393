/* eslint-disable jsx-a11y/aria-role */
import React from 'react'
import { MdEdit } from 'react-icons/md'
import { withRouter, useHistory } from 'react-router-dom'
import { useStore } from 'react-context-hook'
import { normalizeDateToString } from '/src/utils/project_formatter'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import QRCode from 'qrcode.react'
import Button from '/src/ui/core/buttons/button'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import PopupButton from '/src/ui/core/buttons/popup_button'
import { FaCheckDouble } from 'react-icons/fa'
import { MdChecklist } from 'react-icons/md'
import InspectionModel from '/src/models/inspection'
import ScaffoldingModel from '/src/models/scaffolding'
import ScaffoldingStatusLabel from '/src/ui/domain/scaffoldings/scaffolding_status_label'
import '/src/static/css/core/blocks/request_detail_summary.css'
import '/src/static/css/core/blocks/track_block.css'

const nonEditableStatuses = new ScaffoldingModel().cannotEditStatuses
const inspectionAllowedStatuses = new ScaffoldingModel().inspectionAllowedStatuses

export default withRouter(function ScaffoldingDetailSummary({ dataItem, onEdit, hideButtons = false }) {
  const [statuses] = useStore(`scaffolding_statuses`)
  const [user] = useStore('user')
  const scaffoldingModel = new ScaffoldingModel()
  const inspectionModelParamName = InspectionModel.paramName
  const history = useHistory()

  const status =
    dataItem &&
    statuses &&
    statuses.length !== 0 &&
    statuses[dataItem.scaffolding_status_id] &&
    statuses[dataItem.scaffolding_status_id].i18n_id

  const isEditable = !nonEditableStatuses.includes(status)
  const isInspectionsAllowed = inspectionAllowedStatuses.includes(status)

  const isEmptyMoreMenu = !isInspectionsAllowed

  const contextMenuIcons = [
    {
      text: I18n.t('actions.create_inspection'),
      icon: <MdChecklist />,
      onClick: (e, data) => {
        dispatch({
          type: BusEvents.OPEN_MODAL,
          triggeredModelName: inspectionModelParamName,
          payload: {
            dataItem: {
              responsible_id: user?.employee_id,
              inspect_date: normalizeDateToString(new Date()),
              ...dataItem
            }
          }
        })
      }
    },
    {
      text: I18n.t('requests.request_detail.inspections'),
      icon: <FaCheckDouble />,
      onClick: (e, data) => {
        history.push(`/inspections?inspected_id=${dataItem.id}&inspected_type=scaffolding`)
      }
    }
  ]

  return (
    <div className="detail-summary">
      <div className="request-main-info">
        <div>
          <div className="title-side-panel">
            {I18n.t('scaffoldings.tag')}
            <span className="summary-request-id">#{dataItem.tag_number}</span>
          </div>
          <div className="detail-row">
            <ScaffoldingStatusLabel dataItem={dataItem} />
          </div>
        </div>
        {dataItem.qr_code && <QRCode value={dataItem.qr_code} size={76} />}
      </div>
      <div className="detail-field" data-testid="detail-reference-title">
        <span>{I18n.t('scaffoldings.reason')}</span>
        <p>
          {dataItem.main_work_order_erect
            ? `${dataItem.main_work_order_erect.reason} ${dataItem.main_work_order_erect.comments}`
            : I18n.t('fields.na')}
        </p>
        <span>{I18n.t('scaffoldings.description')}</span>
        <p>{dataItem.description}</p>
      </div>
      {history && !hideButtons && (
        <div className="actions-row">
          <div className={`track-block-buttons ${(!isEditable || isEmptyMoreMenu) && 'show-button-expanded'}`}>
            {isEditable && (
              <Button
                text={I18n.t('requests.request_detail.edit')}
                icon={<MdEdit />}
                className="edit-button-track-block"
                onClick={
                  onEdit ||
                  (() => {
                    dispatch({
                      type: BusEvents.OPEN_GRID_FORM_MODAL,
                      modelName: scaffoldingModel.paramName,
                      payload: { dataItem, formType: 'edit' }
                    })
                  })
                }
                role="edit-button-panel"
              />
            )}
            {!isEmptyMoreMenu &&
              (<PopupButton items={contextMenuIcons} text={I18n.t('actions.more')} role="show-button" />)}
          </div>
        </div>
      )}
    </div>
  )
})
