import React from 'react'
import { MdTraffic, MdViewStream, MdMoreVert, MdVerticalSplit } from 'react-icons/md'
import { Link } from 'react-router-dom'
import PropTypes from 'prop-types'
import ClusteredIcon from '/src/ui/core/icons/clustered_icon'
import LabelIcon from '/src/ui/domain/scopes/label_icon'
import SpliterClusterIcon from '/src/ui/core/icons/spliter_cluster_icon'
import BatchAssignerIcon from '/src/ui/core/icons/batch_assigner_icon'
import NoteStackAddIcon from '/src/ui/core/icons/note_stack_add_icon'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/buttons/buttons.css'

export default function GridHeaderIcons({ icons, selectedItem, modelName  }) {
  const buildButton = (testId, icon, opts = {}) => {
    const { title, path, extraClasses } = opts

    const buttonProps = {
      className: `btn-icon grid-header-icon ${extraClasses || ''}`,
      key: testId,
      'data-testid': testId,
      ...(title && { title })
    }

    let button = (
      // eslint-disable-next-line react/jsx-props-no-spreading
      <button type="button" {...buttonProps}>
        {icon}
      </button>
    )

    if (path) {
      button = <Link to={path}>{button}</Link>
    }

    if (title) {
      button = (
        <CustomTooltip position="bottom" parentTitle>
          {button}
        </CustomTooltip>
      )
    }

    return button
  }

  const iconFunctions = {
    split: () => <SpliterClusterIcon selectedItem={selectedItem} />,
    label: () => <LabelIcon />,
    clustered: () => <ClusteredIcon />,
    batch_assigner: () => <BatchAssignerIcon modelName={modelName} />,
    note_stack_add: () => <NoteStackAddIcon />,
    cluster: () => buildButton('cluster-icon', <MdViewStream />),
    filter: () => buildButton('filter-icon', <MdTraffic />),
    toggle_view: (opts) => buildButton('toggle-view-icon', <MdVerticalSplit />, opts),
    more: () => buildButton('more-icon', <MdMoreVert />)
  }

  const renderIcon = (icon) => {
    const key = icons.indexOf(icon)

    if (React.isValidElement(icon)) {
      return { ...icon, key }
    }

    if (icon?.type === 'toggle_view') {
      const { type, ...opts } = icon
      return <React.Fragment key={key}>{iconFunctions[type](opts)}</React.Fragment>
    }

    return iconFunctions[icon] ? <React.Fragment key={key}>{iconFunctions[icon]()}</React.Fragment> : null
  }

  return (
    <div data-testid="group-icons" className="group-icons">
      {icons.map(renderIcon)}
    </div>
  )
}

GridHeaderIcons.propTypes = {
  icons: PropTypes.oneOfType([PropTypes.array]).isRequired,
  selectedItem: PropTypes.oneOfType([PropTypes.object]),
  modelName: PropTypes.string
}

GridHeaderIcons.defaultProps = {
  selectedItem: undefined,
  modelName: ''
}
