export const estimatePDFName = (estimates) => {
  if (estimates.length === 1) {
    const request = estimates[0]

    if (!request.comments) return `${request.reason} - ${request.discipline}`

    return `${request.reason} - ${request.discipline} - ${request.comments}`
  }

  if (estimates.length > 1) {
    const reasons = estimates.map((request) => request.reason).join('_')
    return `${reasons} [${estimates.length}]`
  }
  return 'export'
}
