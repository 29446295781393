/* eslint-disable react-hooks/exhaustive-deps */

import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Scheduler, WeekView } from '@progress/kendo-react-scheduler'
import { useHistory } from 'react-router-dom'
import I18n from '/src/utils/translations.js'
import { Button, ButtonGroup } from '@progress/kendo-react-buttons'
import Layout from '/src/ui/core/layouts/layout'
import { endOfWeek, format, startOfWeek } from 'date-fns'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import useFetch from '/src/hooks/api/fetch'
import '/src/static/css/domain/inspections/inspection_calendar_page.css'

const CustomHeaderCell = ({ date }) => {
  const validDate = date?._localDate || date?._utcDate

  if (!validDate || Number.isNaN(validDate.getTime())) {
    return <div className="k-scheduler-cell">{(I18n.t('filtering.invalid_value'))}</div>
  }

  const formattedDate = format(validDate, 'eeee dd/MM/yyyy')
  return (
    <div className="k-scheduler-cell">
      {formattedDate}
    </div>
  )
}

CustomHeaderCell.propTypes = {
  date: PropTypes.shape({
    _utcDate: PropTypes.instanceOf(Date),
    _localDate: PropTypes.instanceOf(Date),
    timezone: PropTypes.string,
    timezoneOffset: PropTypes.number,
  }).isRequired,
}

export default function InspectionsCalendarPage() {
  const [inspections, setInspections] = useState()
  const [currentDate, setCurrentDate] = useState(new Date())
  const history = useHistory()

  const { fetch } = useFetch()

  const handleTableClick = () => {
    history.push('/inspections')
  }

  const fetchInspections = (startDate, endDate) => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { between: { inspect_date: [startDate, endDate] } }
    }
    fetch('inspections', params, {
      onSuccess: ({ data }) => {
        const formattedEvents = data.data.map((event) => {
          const startTimeInspection = new Date(event.inspect_date)
          startTimeInspection.setDate(startTimeInspection.getDate() + 1)
          
          const endTimeInspection = new Date(startTimeInspection)
          endTimeInspection.setHours(endTimeInspection.getHours() + 1)
          return ({
            ...event,
            start: startTimeInspection,
            end: endTimeInspection,
            title: event.inspected_type,
            isAllDay: true
          })
        })
        setInspections(formattedEvents)
      }
    })
  }

  useEffect(() => {
    const start = startOfWeek(currentDate, { weekStartsOn: 0 })
    const end = endOfWeek(currentDate, { weekStartsOn: 0 })

    fetchInspections(start.toISOString().split('T')[0], end.toISOString().split('T')[0])
  }, [currentDate, fetch])

  const handleDateChange = React.useCallback(
    (event) => {
      // eslint-disable-next-line no-underscore-dangle
      const selectedDate = event.value?._localDate || event.value?._utcDate

      if (selectedDate) {
        setCurrentDate(selectedDate)
      }
    }
  )

    return (
      <Layout key="inspection_calendar">
        <div className='inspection-schedule'>
          <h2 className='inspection-schedule__title'>{(I18n.t('home.inspection_calendar.title'))}</h2>
          <ButtonGroup className='inspection-schedule__buttons'>
            <Button 
              className='inspection-schedule__buttons__button inspection-schedule__buttons__button--selected' 
              togglable 
              selected
            >
              {(I18n.t('home.inspection_calendar.calendar'))}
            </Button>
            <Button 
              className='inspection-schedule__buttons__button' 
              togglable 
              onClick={handleTableClick}
            >
              {(I18n.t('home.inspection_calendar.table'))}
            </Button>
          </ButtonGroup>
        </div>
        {inspections ? (
          <Scheduler
            data={inspections}
            onDateChange={handleDateChange}
            
          >
            <WeekView
              dateHeaderCell={(
              { date }) => <CustomHeaderCell date={date} />}
              slotDuration={2400}
            />
          </Scheduler>
        ) : (
          <ThreeDotsLoader />
        )}
      </Layout>
    )
  }
