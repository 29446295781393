import React, { useState, useEffect } from 'react'
import { useStore } from 'react-context-hook'
import { useHistory, useLocation } from 'react-router-dom'
import ProgressServiceModel from '/src/models/progress_service'
import {
  deleteMenuItem,
  cancelMenuItem,
  completeMenuItem,
  editModalMenuItem,
  duplicateModalMenuItem,
  historyMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useFetch from '/src/hooks/api/fetch'
import useTranslations from '/src/hooks/api/translations'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import { templateType } from '/src/models/concerns/template_types'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import ProgressServicesGrid from '/src/ui/domain/progress_services/progress_services_grid'
import ProgressServicesSidePanel from '/src/ui/domain/progress_services/progress_services_side_panel'
import BusEvents from '/src/hooks/bus/bus_events'
import { dispatch } from '/src/hooks/bus/bus'
import '/src/static/css/core/grid/grid.css'
import '/src/static/css/core/layouts/shadowed_page.css'

export default function useProgressServices({
  handleCloseSidePanel,
  progressSelectedItem,
  progressTemplateId,
  serviceSelectedItem,
  onSelectServiceItem,
  onClick,
  showConfirmation,
  filtered,
  labels,
  serviceModel,
  setInEditMode,
  onGridColumns,
  onGridDataSource,
  serviceSidePanelDataItem,
  progressDataItem,
  progressSections
}) {
  const [serviceSectionsColumns, setServiceSectionsColumns] = useState([])
  const [serviceTemplate, setServiceTemplate] = useState()
  const [subproject] = useStore('subproject')
  const [progressServiceStatuses] = useStore('progress_service_statuses')
  const { fetch } = useFetch()
  const loadingTranslations = useTranslations()
  const itemTemplateId = useQueryParamWithPrevious('eav_template_id')
  const history = useHistory()
  const progressServiceModel = serviceModel || new ProgressServiceModel()
  const location = useLocation()

  useEffect(() => {
    if (!itemTemplateId) return

    let whereClause = {
      'eav_templates][template_type': templateType.progress_service
    }

    if (location.pathname == '/plannings') whereClause.eav_template_id = itemTemplateId
    else if (location.pathname == '/performances') whereClause['eav_templates][parent_template_id'] = progressTemplateId

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: {
        where: whereClause
      }
    }

    const fetchServiceTemplate = () => {
      const serviceParams = {
        requestAction: 'READ',
        httpAction: 'get',
        query: {
          where: {
            parent_template_id: itemTemplateId,
            template_type: 'progress_service'
          }
        }
      }

      fetch('eav_templates', serviceParams, {
        onSuccess: ({ data }) => {
          if (!data.data[0]) setServiceTemplate()
          setServiceTemplate(data.data[0])
        }
      })
    }

    fetchServiceTemplate()
    fetch('eav_sections', params, {
      onSuccess: ({ data }) => {
        const newSectionsColumns = filterVisibleOnWeb(data)
        setServiceSectionsColumns(newSectionsColumns)
      }
    })

    handleCloseSidePanel()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [itemTemplateId, progressTemplateId, fetch, location.pathname])

  const showGrid = Boolean(progressSelectedItem)

  const closeSidePanelItem = () => onClick()

  const urlParams = () => {
    const params = []
    if (itemTemplateId) params.push(`item_template_id=${itemTemplateId}`)
    progressServiceModel.path && params.push(`path=${progressServiceModel.path}`)
    return params.join('&')
  }

  const historiesDataParams = (dataItem) => {
    const params = []
    params.push(`select_item_id=${dataItem.progress_id}`)
    params.push(`select_service_id=${dataItem.id}`)
    return params.join('&')
  }

  const statusI18n = (statusId) => {
    const statusObject = progressServiceStatuses && progressServiceStatuses[statusId]
    return statusObject && statusObject.i18n_id
  }

  const menuItemDispatchOpenGridFormModal = (formType, dataItem) => {
    dispatch({
      type: BusEvents.OPEN_GRID_FORM_MODAL,
      modelName: serviceModel.paramName,
      payload: {
        formType,
        dataItem
      }
    })
  }

  const onSelectItem = (dataItem, openProgressFormTab = false) => {
    if (dataItem.id === serviceSelectedItem?.id) return
    if (!serviceSelectedItem && openProgressFormTab) {
      onSelectServiceItem({ dataItem: { ...dataItem, dispatchProgressFormTab: true } })
    } else {
      onSelectServiceItem({ dataItem })
    }
  }

  const progressServicesMenuItems = {
    history: historyMenuItem(
      history,
      progressServiceModel.route,
      (dataItem) => {
        if (subproject.progress_type === 'progress') return false
        const allowedStatuses = ['planned', 'in_planning', 'in_performance', 'done']
        const i18nStatus = statusI18n(dataItem.progress_service_status_id)

        return allowedStatuses.includes(i18nStatus)
      },
      {
        urlParams: urlParams(),
        dataParams: historiesDataParams,
        onSelectItem,
        getStatusI18n: (dataItem) => statusI18n(dataItem.progress_service_status_id),
        serviceSelectedItem
      }
    ),
    duplicate: duplicateModalMenuItem(
      (dataItem) => {
        const allowedStatuses = ['planned', 'in_planning', 'in_performance']
        const i18nStatus = statusI18n(dataItem.progress_service_status_id)

        return allowedStatuses.includes(i18nStatus)
      },
      (_, dataItem) => {
        menuItemDispatchOpenGridFormModal('duplicate', dataItem)
      }
    ),
    remove: deleteMenuItem(history, progressServiceModel, showConfirmation, closeSidePanelItem, (dataItem) => {
      if (dataItem.estimate_service_id !== null) return false
      const allowedStatuses = ['planned', 'in_planning', 'in_performance']
      const i18nStatus = statusI18n(dataItem.progress_service_status_id)

      return allowedStatuses.includes(i18nStatus)
    }),
    cancel: cancelMenuItem(progressServiceModel, showConfirmation, (dataItem) => {
      const allowedStatuses = ['planned', 'in_planning', 'in_performance']
      const i18nStatus = statusI18n(dataItem.progress_service_status_id)

      return allowedStatuses.includes(i18nStatus)
    }),
    complete: completeMenuItem(progressServiceModel, showConfirmation, closeSidePanelItem, (dataItem) => {
      if (subproject.progress_type === 'progress') return false
      const allowedStatuses = ['planned', 'in_planning', 'in_performance']
      const i18nStatus = statusI18n(dataItem.progress_service_status_id)

      return allowedStatuses.includes(i18nStatus)
    }),
    edit: editModalMenuItem(
      (dataItem) => {
        const i18nStatus = statusI18n(dataItem.progress_service_status_id)
        const allowedStatuses = ['planned', 'in_planning', 'in_performance', 'on_hold']

        return allowedStatuses.includes(i18nStatus)
      },
      (_, dataItem) => {
        menuItemDispatchOpenGridFormModal('edit', dataItem)
      }
    )
  }

  return [
    (template) =>
      template && (
        <ProgressServicesGrid
          parentItem={progressSelectedItem}
          selectedItem={serviceSelectedItem}
          template={template}
          filtered={filtered}
          showGrid={showGrid}
          onRowClick={onClick}
          contextMenuItems={[
            progressServicesMenuItems.history,
            progressServicesMenuItems.duplicate,
            progressServicesMenuItems.edit,
            progressServicesMenuItems.remove,
            progressServicesMenuItems.cancel,
            progressServicesMenuItems.complete
          ]}
          labels={labels}
          model={progressServiceModel}
          setInEditMode={setInEditMode}
          onGridColumns={onGridColumns}
          onDataSource={onGridDataSource}
        />
      ),
    () => (
      <ProgressServicesSidePanel
        dataItem={serviceSidePanelDataItem}
        sections={serviceSectionsColumns}
        onClose={handleCloseSidePanel}
        contextMenuItems={progressServicesMenuItems}
        progressDataItem={progressDataItem}
        progressSections={progressSections}
      />
    ),
    serviceTemplate,
    setServiceTemplate
  ]
}

useProgressServices.defaultProps = {
  filtered: true,
  labels: []
}
