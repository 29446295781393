/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react'
import ColumnMenuVisibility from '/src/ui/core/grid/column_menu_visibility'
import '/src/static/css/core/grid/column_menu.css'

export function ColumnMenu(props) {
  const [opened, setOpened] = useState('visibility')

  return (
    <div className="column-menu-items">
      <ColumnMenuVisibility {...props} opened={opened === 'visibility'} onExpand={() => setOpened('visibility')} />
    </div>
  )
}
