/* eslint-disable no-prototype-builtins */
import React, { useCallback, useState } from 'react'
import PropTypes from 'prop-types'
import Modal from '/src/ui/core/popups/modal'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { GRID_FORM_MODAL_WIDTH } from '/src/models/concerns/form'
import '/src/static/css/core/forms/grid_form_modal.css'

export default function GridFormModal({ children, modelName, className, formSidePanel }) {
  const [isFormOpened, setIsFormOpened] = useState(false)
  const [formDataItem, setFormDataItem] = useState()
  const [sidePanelDataItem, setSidePanelDataItem] = useState()
  const [itemCount, setItemCount] = useState(1)
  const [isRequestBatchInspection, setIsRequestBatchInspection] = useState(false)

  const showSidePanel = formSidePanel && !isRequestBatchInspection

  const onCloseFormModal = useCallback(() => {
    setIsFormOpened(false)
    setIsRequestBatchInspection(false)
    setSidePanelDataItem()
  }, [])

  useBus(BusEvents.FORM_CANCEL_BUTTON_CLICKED, onCloseFormModal, [onCloseFormModal])

  useBus(BusEvents.FORM_BACK_BUTTON_CLICKED, onCloseFormModal, [onCloseFormModal])

  useBus(
    BusEvents.FORM_FINISHED,
    ({ payload }) => {
      // removing the following line could break the entire front, so it's still commented
      // dispatch(BusEvents.RELOAD_GRID)

      if (payload && payload.newClicked) {
        setItemCount((count) => count + 1)
      } else onCloseFormModal()
    },
    [onCloseFormModal]
  )

  useBus(
    BusEvents.OPEN_GRID_FORM_MODAL,
    ({ payload, modelName: triggeredModelName }) => {
      if (triggeredModelName && triggeredModelName !== modelName) return

      if (payload) {
        setFormDataItem(payload)
        setSidePanelDataItem(payload.dataItem)
        if (Array.isArray(payload.dataItem?.items)) {
          setIsRequestBatchInspection(true)
        }
      } else {
        setFormDataItem()
      }

      setIsFormOpened(true)
    },
    []
  )

  useBus(BusEvents.UPDATE_INSPECTED_ITEM, ({ payload }) => setSidePanelDataItem(payload), [])

  const handleFormDataItem = (property) => {
    return formDataItem && Object.hasOwn(formDataItem, property)
  }

  return (
    isFormOpened &&
    children && (
      <Modal
        width={GRID_FORM_MODAL_WIDTH}
        className={showSidePanel ? 'grid-form-modal-with-side-panel' : className}
        onClose={() => dispatch(BusEvents.FORM_DISCARD_CHANGES_CONFIRM)}
        closable
      >
        <div className="modal-container">
          <div className="modal-form">
            {React.cloneElement(children, {
              ...children.props,
              dataItem: handleFormDataItem('dataItem') ? formDataItem.dataItem : formDataItem,
              type: handleFormDataItem('formType') ? formDataItem.formType : undefined,
              opts: handleFormDataItem('opts') ? formDataItem.opts : undefined,
              key: itemCount
            })}
          </div>
          {showSidePanel && (
            <div className="modal-side-panel">
              {React.cloneElement(formSidePanel, {
                ...formSidePanel.props,
                dataItem: sidePanelDataItem
              })}
            </div>
          )}
        </div>
      </Modal>
    )
  )
}

GridFormModal.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array, PropTypes.string]),
  modelName: PropTypes.string,
  className: PropTypes.string,
  formSidePanel: PropTypes.element
}

GridFormModal.defaultProps = {
  children: null,
  modelName: undefined,
  className: 'grid-form-modal',
  formSidePanel: null
}
