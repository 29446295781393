import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BusEvents from '/src/hooks/bus/bus_events'
import SidePanelFormWrapper from '/src/ui/core/grid/side_panel/side_panel_form_wrapper'
import useActionFormTitles from '/src/hooks/scaffolding_action_form_titles'
import '/src/static/css/core/grid/side_panel/side_panel_form.css'
import Form from '/src/ui/core/forms/form'
import ScaffoldingPartActionModel from '/src/models/scaffolding_part_action'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import useUpdateActionFormModel from '/src/hooks/update_action_form_model'
import { PartBadges } from '/src/ui/domain/scaffoldings/badges.jsx'

export default function ScaffoldingPartActionForm({ addOrModify, scaffolding, scaffoldingPartItem, partAction, type }) {
  const [showConfirmation, renderConfirmation] = useConfirmationModal(true)
  const isEdit = type === 'edit'
  const getActionType = (actionType) => (actionType === 'modification' ? 'modify' : 'build')
  const scaffoldingDimensionType = scaffoldingPartItem.scaffolding_type.dimension_type
  const { formTitle, formSubTitle } = useActionFormTitles(scaffolding)
  const [model] = useUpdateActionFormModel(
    new ScaffoldingPartActionModel(),
    scaffolding,
    {
      addOrModify,
      scaffoldingDimensionType
    }
  )

  const badges = <PartBadges scaffoldingPartItem={scaffoldingPartItem} />

  const buttons = [
    {
      id: 1,
      text: I18n.t('actions.cancel'),
      cancel: true,
      class: 'button-cancel'
    },
    !isEdit && {
      id: 2,
      text: I18n.t('actions.save_new'),
      class: 'button-action',
      createNew: true
    },
    {
      id: 3,
      text: I18n.t('actions.save_exit'),
      class: 'button-action-save-exit',
      submit: true
    }
  ]

  const completeCancel = () => {
    dispatch(BusEvents.FORM_CANCEL_BUTTON_CLICKED)
  }

  const staticParams = {
    title: I18n.t('discard_modal.title'),
    description: I18n.t('discard_modal.description'),
    actionButtonText: I18n.t('discard_modal.ok'),
    cancelButtonText: I18n.t('discard_modal.cancel'),
    buttonColor: '#B33939'
  }

  const getConfirmationParams = () => ({
    ...staticParams,
    onSuccess: () => completeCancel
  })

  useBus(BusEvents.FORM_DISCARD_CHANGES_CONFIRM, () => showConfirmation(getConfirmationParams()), [
    showConfirmation,
    getConfirmationParams
  ])

  const handleClose = () => {
    showConfirmation(getConfirmationParams())
  }

  const includeOnForm = () => {
    return {
      scaffolding_part_id: scaffoldingPartItem.id,
      action_type: getActionType(addOrModify)
    }
  }

  const actionItem = { ...partAction, dimensionType: scaffoldingDimensionType }

  return (
    <SidePanelFormWrapper
      title={formTitle()}
      subtitle={formSubTitle()}
      badge={badges}
      buttons={buttons}
      model={model}
      dataItem={undefined}
      separator={false}
      onClose={handleClose}
      awaitFormSuccess
    >
      <React.Fragment>
        {renderConfirmation()}
        <Form
          model={model}
          sections={[]}
          sectionable={false}
          hideable={false}
          dataItem={actionItem}
          includeOnForm={includeOnForm()}
          type={type === 'duplicate' ? 'new' : type}
          isDuplicate={type === 'duplicate'}
          key={type + addOrModify}
        />
      </React.Fragment>
    </SidePanelFormWrapper>
  )
}

ScaffoldingPartActionForm.propTypes = {
  addOrModify: PropTypes.string.isRequired,
  scaffolding: PropTypes.oneOfType([PropTypes.object]).isRequired,
  scaffoldingPartItem: PropTypes.oneOfType([PropTypes.object]).isRequired,
  partAction: PropTypes.oneOfType([PropTypes.object]),
  type: PropTypes.string
}

ScaffoldingPartActionForm.defaultProps = {
  partAction: {},
  type: undefined
}
