import { useDeleteStoreValue } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import useClearBatchEntities from '/src/hooks/clear_batch_entities'
import useRequestsPinned from '/src/hooks/requests_pinned'
import usePreferences from '/src/ui/core/grid/preferences'

export default function useLogout() {
  const removeUser = useDeleteStoreValue('user')
  const removeUserRole = useDeleteStoreValue('user_role')
  const [, , removeCookie] = useCookie('authentication_token')
  const clearBatchEntities = useClearBatchEntities()
  const { clearRequests } = useRequestsPinned()
  const { clearLocalGridSettings } = usePreferences()

  return () => {
    removeCookie()
    removeUser()
    removeUserRole()
    clearBatchEntities()
    clearRequests()
    clearLocalGridSettings()
  }
}
