/* eslint-disable import/no-unresolved */

import React, { useMemo } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import CurrencyColumn from '/src/ui/core/grid/flexible_column/currency'
import { estimatesPrice, estimateIsReadOnly } from '/src/models/estimate'
import '/src/static/css/domain/estimates/estimates_pdf_summary.css'

const renderStatusBadge = (statusId, statuses) => {
  const status = statuses[statusId] || {}
  const isApproved = status.description === 'Authorized' || status.description === 'Done'
  const statusClass = isApproved ? 'approved' : 'pending'

  return (
    <span className={`summary__list__item__status summary__list__item__status--${statusClass}`}>
      {isApproved
        ? I18n.t('inspections.statuses.approved')
        : I18n.t('inspections.statuses.pending')}
    </span>
  )
}

const EstimatesPDFSummary = ({ requests, estimates, statuses }) => {
  const [project] = useStore('project')

  const estimateList = useMemo(() => {
    if (!estimates) return []
    return Object.values(estimates).reduce((acc, val) => acc.concat(val), [])
  }, [estimates])

  const approvedEstimates = useMemo(() => {
    return estimateList.filter((estimate) => estimateIsReadOnly(estimate, statuses))
  }, [estimateList, statuses])

  const pendingEstimates = useMemo(() => {
    return estimateList.filter((estimate) => !estimateIsReadOnly(estimate, statuses))
  }, [estimateList, statuses])

  if (!requests || requests.length === 0) {
    return <div>{I18n.t('kendo_translations.dropdowns.nodata')}</div>
  }

  const requestMap = new Map(requests.map((request) => [request.id, request]))
  const estimateArray = Object.values(estimates)

  return (
    <div className="summary">
      <div className="summary__logos">
        <img
          className="summary__logos__logo summary__logos__logo--primary"
          src="/static/svg/kaefer-logo.svg"
          alt="Kaefer Logo"
        />
        <img
          className="summary__logos__logo summary__logos__logo--secondary"
          src="/static/svg/logo-red.svg"
          alt="Red Logo"
        />
      </div>

      <div className="summary__main">
        <h1 className="summary__main__title">{I18n.t('estimate.pdf_summary.title')}</h1>
        <span className="summary__main__info">
          <strong>{I18n.t('estimate.pdf_header.project')}: </strong>
          {project.description}
        </span>
        <span className="summary__main__info">
          <strong>{I18n.t('estimate.pdf_header.subproject')}: </strong>
          {requests[0].subproject}
        </span>
      </div>

      <div className="summary__costs">
        <div className="summary__costs__item">
          <p>{I18n.t('estimate.total_approved')}</p>
          <p>
            <strong>{estimatesPrice(approvedEstimates, project)}</strong>
          </p>
        </div>
        <div className="summary__costs__item">
          <p>{I18n.t('estimate.total_pending')}</p>
          <p>
            <strong>{estimatesPrice(pendingEstimates, project)}</strong>
          </p>
        </div>
        <div className="summary__costs__item">
          <p>{I18n.t('estimate.total_request')}</p>
          <p>
            <strong>{estimatesPrice(estimateList, project)}</strong>
          </p>
        </div>
      </div>

      <div className="summary__list">
        {estimateArray.map((item, index) => {
          const request = requestMap.get(item.request_id) || {}
          const { reason = '', discipline = '' } = request
          const itemEstimateCost = item.estimate_cost

          return (
            <div className="summary__list__item" key={item.id}>
              <div className="summary__list__item__description">
                <span className="summary__list__item__id">{index + 1}</span>
                <div className="summary__list__item__text">
                  <div className="summary__list__item__reason">
                    {'AO: '}
                    {reason}
                  </div>
                  {discipline && (
                    <div className="summary__list__item__discipline">{discipline}</div>
                  )}
                </div>
              </div>
              <div className="summary__list__item__status">
                {renderStatusBadge(item.estimate_status_id, statuses)}
              </div>
              <div className="summary__list__item__cost">
                <CurrencyColumn value={itemEstimateCost} />
              </div>
            </div>
          )
        })}
      </div>
      <div className="page-break" />
    </div>
  )
}

EstimatesPDFSummary.propTypes = {
  requests: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      subproject: PropTypes.string
    })
  ).isRequired,
  estimates: PropTypes.objectOf(
    PropTypes.shape({
      id: PropTypes.number,
      estimate_cost: PropTypes.number,
      total_application_price: PropTypes.number,
      total_team_target_hours: PropTypes.number,
      request_id: PropTypes.number,
      estimate_status_id: PropTypes.number
    })
  ),
  statuses: PropTypes.objectOf(
    PropTypes.shape({
      description: PropTypes.string
    })
  ).isRequired
}

export default EstimatesPDFSummary
