import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { CustomI18n as I18n } from '/src/utils/custom_i18n'
import { MdAttachFile } from 'react-icons/md'
import AttachmentsModal from '/src/ui/domain/requests/attachments_modal'
import '/src/static/css/domain/requests/track_block.css'

export default function TrackBlock({ children, detail, actions, idText, iconAttachments }) {
  const { id, reason, comments, subproject_id: subprojectId } = detail
  const [isModalOpen, setModalOpen] = useState(false)

  const openModal = () => {
    setModalOpen(true)
  }

  const closeModal = () => setModalOpen(false)

  return (
    <div className="detail-summary">
      <div className="information">
        <div className="title-side-panel">
          {idText || I18n.t('track_block.id')}
          <span className="summary-request-id">{`#${id}`}</span>
        </div>
        {reason && (
          <div className="reference-side-panel">
            <div>
              <div className="summary-label">{I18n.t('requests.reason')}</div>
              <div className="summary-value">
                <p>{reason}</p>
                {iconAttachments && (
                  <div className="icon-attachment-container">
                    <MdAttachFile data-testid="icon-attachment" className="icon-attachment" onClick={openModal} />
                  </div>
                )}
              </div>
            </div>
          </div>
        )}
        <div className="comments-side-panel">
          <div className="summary-label">{I18n.t('requests.comments')}</div>
          <div className="summary-value">{comments || I18n.t('fields.na')}</div>
        </div>
        {children && <div className="children">{children}</div>}
      </div>
      <div className="summary-buttons">{actions}</div>
      {isModalOpen && <AttachmentsModal subprojectId={subprojectId} requestId={id} onClose={closeModal} />}
    </div>
  )
}

TrackBlock.propTypes = {
  children: PropTypes.oneOfType([PropTypes.element, PropTypes.node, PropTypes.array]),
  detail: PropTypes.shape({
    id: PropTypes.number,
    reason: PropTypes.string,
    comments: PropTypes.string,
    subproject_id: PropTypes.number
  }),
  actions: PropTypes.element,
  idText: PropTypes.string,
  iconAttachments: PropTypes.bool
}

TrackBlock.defaultProps = {
  children: undefined,
  actions: null,
  idText: null,
  iconAttachments: false
}
