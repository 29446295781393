/* eslint-disable max-lines-per-function */
import React, { useState, useCallback, useMemo } from 'react'
import I18n from '/src/utils/translations'
import Modal from '/src/ui/core/popups/modal'
import FormHeader from '/src/ui/core/forms/form_header'
// eslint-disable-next-line import/no-cycle
import '/src/static/css/domain/inspections/inspection_acceptance_popup.css'

/**
 * Custom hook that exposes renderModal() and openModal() functions
 * for inspection acceptance
 *
 * @param dataItem
 * @return {[*, *]}
 * @constructor
 */
export default function useInspectionAcceptanceError(inspectionsWithError) {
  const buildErrorMessages = useMemo(() => {
    if (!inspectionsWithError) return ''
    const errorMessages = []
    inspectionsWithError.forEach((inspection) => {
      if (!inspection) return
      const module = inspection.inspected_type.toLowerCase()
      const itemId = `${I18n.t(`inspections.inspected_modules.${module}`)} ${
        module === 'request' ? inspection.request_id : inspection.inspected_id
      } - ${inspection.number}`
      errorMessages.push(
        I18n.t('inspections.acceptance_error.error_description', {
          item: itemId,
          reason: inspection.errorReason
        })
      )
    })
    return errorMessages
  }, [inspectionsWithError])

  const buildModalHeader = useMemo(() => {
    return (
      <FormHeader
        label={I18n.t('inspections.acceptance_modal.items', { items: inspectionsWithError?.length })}
        title={I18n.t('inspections.acceptance_error.title')}
      />
    )
  },[inspectionsWithError])

  const [openModal, setOpenModal] = useState(false)

  const renderModal = () => {
    return (
      openModal && (
        <div className="error">
          <Modal
            title={I18n.t('inspections.acceptance')}
            header={buildModalHeader}
            height={500}
            width={440}
            sizeInPx
            onClose={() => setOpenModal(false)}
            closable
          >
            <div className="error-messages form-wrapper-subtitle">
              {buildErrorMessages.map((errorMessage) => (
                <p key={errorMessage}>{errorMessage}</p>
              ))}
            </div>
          </Modal>
        </div>
      )
    )
  }

  return [setOpenModal, renderModal]
}
