import React, { useState, useEffect, useCallback } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import { MdKeyboardArrowDown, MdKeyboardArrowUp, MdError, MdCheckCircle } from 'react-icons/md'
import { IntlService } from '@progress/kendo-react-intl'
import { estimatePrice, estimateIsReadOnly } from '/src/models/estimate'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { formattedLocaleDate } from '/src/utils/project_formatter'
import { requestTitle } from '/src/utils/constants/request'

const formatDate = (date, project) => {
  const opts = { project }
  opts.parser = new IntlService()
  opts.value = date

  return formattedLocaleDate(opts)
}

export default function EstimateRequestLabel({
  index,
  request,
  estimate,
  estimateLog,
  statuses,
  employee,
  onShowCallback
}) {
  const [project] = useStore('project')
  const [show, setShow] = useState(true)

  const onClick = () => setShow(!show)

  useEffect(() => onShowCallback(show), [onShowCallback, show])

  const isApproved = useCallback(
    () => estimateIsReadOnly(estimate, statuses) || estimateLog.status === 'success',
    [estimate, statuses, estimateLog]
  )

  const approveInfo = useCallback(() => {
    if (!isApproved() || !estimate.date_start) return ''

    return `${formatDate(estimate.date_start, project)}${employee ? ` - ${employee.full_name}` : ''}`
  }, [isApproved, estimate.date_start, employee, project])

  const logIcon = () => {
    if (estimateLog.status === 'error') return <MdError />
    if (estimateLog.status === 'success') return <MdCheckCircle />
    return null
  }

  const logStatusClass = estimateLog.status === 'error' ? 'error-log' : 'approved-log'

  return (
    <div className="request-label">
      {logIcon() && (
        <CustomTooltip position="auto" parentTitle key={`${request.id}-${estimateLog.status}`}>
          <span title={estimateLog.message}>
            <div className={`estimate-log-icon ${logStatusClass}`}>{logIcon()}</div>
          </span>
        </CustomTooltip>
      )}

      <div className="request-item-tag">{index + 1}</div>
      <div className="request-title">{requestTitle(request, 'AO: ')}</div>

      <CustomTooltip position="bottom" parentTitle key={request.id}>
        <span title={approveInfo()}>
          <div className={`status-badge ${isApproved()}`}>
            {isApproved() ? I18n.t('inspections.statuses.approved') : I18n.t('inspections.statuses.pending')}
          </div>
        </span>
      </CustomTooltip>

      <button type="button" className="visibility-button" onClick={onClick}>
        {/* eslint-disable-next-line max-len */}
        <div className="show-hide-text pdf-export-hidden">{show ? I18n.t('form.hide') : I18n.t('form.show')}</div>
        <div className="show-hide-icon pdf-export-hidden">{show ? <MdKeyboardArrowUp /> : <MdKeyboardArrowDown />}</div>
      </button>

      <div className="request-price">{estimatePrice(estimate, project)}</div>
    </div>
  )
}

EstimateRequestLabel.propTypes = {
  index: PropTypes.number.isRequired,
  request: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reason: PropTypes.string,
    discipline: PropTypes.string
  }).isRequired,
  estimate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    estimate_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date_start: PropTypes.string
  }),
  estimateLog: PropTypes.object,
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  employee: PropTypes.object,
  onShowCallback: PropTypes.func
}

EstimateRequestLabel.defaultProps = {
  estimate: {},
  estimateLog: {},
  statuses: {},
  employee: undefined,
  onShowCallback: () => {}
}
