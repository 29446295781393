import React from 'react'
import PropTypes from 'prop-types'
import { MdDelete, MdDeleteOutline, MdContentCopy } from 'react-icons/md'
import Colors from '/src/utils/constants/colors'
import MissingRequiredCellsIcon from '/src/ui/core/grid/editable/missing_required_cells_icon'

export default function RowActionsColumn({
  toBeDeleted,
  onChange,
  onDuplicate,
  allowDelete,
  allowDuplicate,
  header,
  missingRequiredFields
}) {
  const deleteIcon = toBeDeleted ? (
    <MdDelete size={22} color={Colors.KAEFER_RED} />
  ) : (
    <MdDeleteOutline size={22} color={Colors.KAEFER_SILVER} />
  )

  const renderInput = () => (
    <div className="bulk-actions-cell">
      {!header && <MissingRequiredCellsIcon missingRequiredCells={missingRequiredFields} />}
      {allowDuplicate && (
        <div className="duplicate-icon-container bulk-actions-cell__icon">
          {!header && (
            <MdContentCopy size={18} color={Colors.KAEFER_SILVER} onClick={onDuplicate} className="duplicate-icon" />
          )}
        </div>
      )}
      <div className="delete-icon-container bulk-actions-cell__icon">
        {allowDelete && (
          <>
            <input type="checkbox" className="delete-column-input" defaultChecked={toBeDeleted} onClick={onChange} />
            {deleteIcon}
          </>
        )}
      </div>
    </div>
  )

  return header ? renderInput() : <td>{renderInput()}</td>
}

RowActionsColumn.propTypes = {
  toBeDeleted: PropTypes.bool,
  header: PropTypes.bool,
  onChange: PropTypes.func,
  onDuplicate: PropTypes.func,
  allowDelete: PropTypes.bool,
  allowDuplicate: PropTypes.bool,
  missingRequiredFields: PropTypes.number
}

RowActionsColumn.defaultProps = {
  toBeDeleted: false,
  header: false,
  onChange: () => { },
  onDuplicate: () => { },
  allowDelete: true,
  allowDuplicate: true,
  missingRequiredFields: 0
}
