import React from 'react'
import PropTypes from 'prop-types'
import { MdOutlinePushPin, MdPushPin } from 'react-icons/md'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import I18n from '/src/utils/translations'
import useRequestsPinned from '/src/hooks/requests_pinned'
import { isEmpty } from '/src/utils/object'

export default function PinRequestIcon({ selectedRows }) {
  const { selectedRequests, setPinnedRequests, clearRequests, unpinRequests } = useRequestsPinned()
  const isPinned = !isEmpty(selectedRequests)
  const hasSelectedRows = selectedRows.length !== 0

  const pinRequests = () => {
    setPinnedRequests(selectedRows)
  }

  const unpinRequestss = () => {
    if (!hasSelectedRows) {
      clearRequests()
      return
    }

    unpinRequests(selectedRows.map(({ id }) => id))
  }

  const unpinTooltipText = () => {
    if (!hasSelectedRows) return I18n.t('actions.unpin_request')
    return I18n.t('actions.unpin_selected_request', {
      numSelectedRequests: selectedRows.length
    })
  }

  if (!isPinned && !hasSelectedRows) return null

  return (
    <CustomTooltip position="bottom" parentTitle>
      <button
        id="pin-request"
        type="button"
        title={isPinned ? unpinTooltipText() : I18n.t('actions.pin_request')}
        data-testid="pin-request"
        className="btn-icon grid-header-icon"
        onClick={isPinned ? unpinRequestss : pinRequests}
      >
        {isPinned ? <MdOutlinePushPin /> : <MdPushPin />}
      </button>
    </CustomTooltip>
  )
}

PinRequestIcon.propTypes = {
  selectedRows: PropTypes.arrayOf(PropTypes.object)
}

PinRequestIcon.defaultProps = {
  selectedRows: []
}
