/* eslint-disable max-lines-per-function */
import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import '/src/static/css/inputs.css'
import useInputChange from '/src/ui/core/inputs/input_change'
import useInputError from '/src/ui/core/inputs/input_error'

const MAX_MULTILINE_LENGTH = 10000

export default function InputMultiline({ inputProps }) {
  const { id, className, name, placeholder, required, readOnly, title, value, onChange, onBlur, onFocus, maxLength } =
    inputProps
  const [inputValue, onInputChange] = useInputChange({ id, value, onChange })
  const [actualValue, setActualValue] = useState(inputValue || '')
  const timer = useRef(null)
  const error = useInputError({ inputValue, title, required, id })
  const textMaxLength = maxLength || MAX_MULTILINE_LENGTH

  const textLength = () => (actualValue ? actualValue.length : 0)

  const inputClass = () => {
    const classes = ['form-input-multiline']
    if (className) classes.push(className)
    if (error) classes.push('input-error')
    return classes.join(' ')
  }

  useEffect(() => {
    timer.current = setTimeout(() => {
      onInputChange({ target: { value: actualValue } })
    }, 500)
    return () => {
      clearTimeout(timer.current)
    }
  }, [actualValue, onInputChange])

  return (
    <React.Fragment>
      <p className="form-input-multiline-counter" id={`${id}-counter`}>
        {textLength()}/{textMaxLength}
      </p>
      <textarea
        id={id}
        className={inputClass()}
        name={name}
        placeholder={placeholder}
        value={actualValue}
        onChange={(e) => setActualValue(e.target.value)}
        onBlur={onBlur}
        onFocus={onFocus}
        maxLength={textMaxLength}
        disabled={readOnly}
      />
      <div className="error-label">{error}</div>
    </React.Fragment>
  )
}

InputMultiline.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    className: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.oneOfType([PropTypes.bool, PropTypes.func]),
    name: PropTypes.string,
    placeholder: PropTypes.string,
    value: PropTypes.string,
    onChange: PropTypes.func,
    onBlur: PropTypes.func,
    onFocus: PropTypes.func,
    maxLength: PropTypes.number,
    readOnly: PropTypes.bool
  }).isRequired
}
