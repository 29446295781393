import React, { useState } from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import Tabs from '/src/ui/core/layouts/tabs'
import ScopesAllocation from '/src/ui/domain/sub_requests/scopes_allocation'
import SubRequestSummary from '/src/ui/domain/sub_requests/sub_request_summary'
import '/src/static/css/domain/sub_requests/sub_request.css'

export default function SubRequestPanel({ request, scopes, scopeCluster, label, templateId }) {
  const tabs = [
    { id: 1, title: I18n.t('sub_requests.left_panel.allocation') },
    { id: 2, title: I18n.t('sub_requests.left_panel.summary') }
  ]

  const [tabActive, setTabActive] = useState(tabs[0].id)

  return (
    <div className="sub-request-panel" data-testid="sub-request-panel">
      <div className="sub-request-title">
        <span className="request-description">
          {request.reason !== undefined
            ? `${I18n.t('requests.description')} ${request.reason}`
            : `${I18n.t('requests.description')}`}
        </span>
        <span className="label-filter">{request.id !== undefined && request.id}</span>
      </div>
      <Tabs tabsList={tabs} onTabChange={(tab) => setTabActive(tab.id)} initialTabId={tabActive} />
      {
      tabActive === 1
      ? <ScopesAllocation scopes={scopes} scopeCluster={scopeCluster} label={label} templateId={templateId} />
      : <SubRequestSummary request={request} />
      }
    </div>
  )
}

SubRequestPanel.propTypes = {
  request: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reason: PropTypes.string
  }).isRequired,
  scopes: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      number: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
      quantity: PropTypes.number,
      remaining_quantity: PropTypes.number,
      team_target_hours: PropTypes.number
    })
  ).isRequired,
  scopeCluster: PropTypes.shape({
    quantity: PropTypes.number,
    remaining_quantity: PropTypes.number
  }).isRequired,
  label: PropTypes.string
}
