import React from 'react'
import PropTypes from 'prop-types'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import InspectionForm from '/src/ui/domain/inspections/inspection_form'
import InspectedItem from '/src/ui/domain/inspections/inspected_item'

export default function InspectionFormModal({ modelParamName, templateId }) {
  return (
    <GridFormModal modelName={modelParamName} formSidePanel={<InspectedItem />}>
      <InspectionForm templateId={templateId} />
    </GridFormModal>
  )
}

InspectionFormModal.propTypes = {
  modelParamName: PropTypes.string,
  templateId: PropTypes.number
}

InspectionFormModal.defaultProps = {
  modelParamName: '',
  templateId: 0
}
