import React, { useCallback, useEffect, useMemo, useState } from 'react'
import PropTypes from 'prop-types'
import includeGeneralSection from '/src/ui/domain/eav_sections/include_general_section'
import useFetchSections from '/src/hooks/api/fetch_sections'
import InspectionModel from '/src/models/inspection'
import CreationInspectionModel from '/src/models/creation_inspection'
import FormWrapper from '/src/ui/core/forms/form_wrapper'
import FormButtons from '/src/ui/core/forms/form_buttons'
import Form from '/src/ui/core/forms/form'
import inspectedFormatter from '/src/ui/domain/inspections/inspected_formatter'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import '/src/static/css/domain/inspections/inspection_form.css'

const isTypeNewAndInternal = (type, opts) => {
  if (!opts) return type === 'new'
  return type === 'new' && !opts.fromExternalModule
}

export default function InspectionForm({ dataItem, templateId, type, opts }) {
  const inspectionTemplateId = templateId || opts.templateId
  const customDispatchEvent = opts?.customDispatchEvent
  const { fetch } = useFetch()
  const sections = useFetchSections(inspectionTemplateId)
  const [inspectedObj, setInspectedObj] = useState(dataItem.inspected ?? {})

  const isInBatch = !!dataItem.items

  const defaultFormatter = useCallback(
    (item) => {
      const { inspected_type: inspectedType } = item
      return inspectedFormatter(inspectedObj, inspectedType)
    },
    [inspectedObj]
  )

  const model = useMemo(() => {
    return isTypeNewAndInternal(type, opts)
      ? new CreationInspectionModel()
      : new InspectionModel(defaultFormatter, isInBatch, dataItem?.inspected_type)
  }, [type, opts, defaultFormatter, isInBatch, dataItem])

  const [includeOnForm, setIncludeOnForm] = useState({})
  const readOnlyColumns = ['request_id', 'inspected_type']
  const readOnlyTypes = ['attachment', 'picture']
  const isDuplicate = () => type === 'duplicate'

  const sectionsWithGeneral = useMemo(() => includeGeneralSection(sections), [sections])
  const disabledColumns = (column) => {
    let result = readOnlyColumns.includes(column.description)
    if (isDuplicate()) {
      result = result || readOnlyTypes.includes(column.type)
    }
    return result
  }

  const formLabel = (isInBatch && `${dataItem.items.length} ${I18n.t('form.items')}`) || dataItem?.request_id

  useEffect(() => {
    if (dataItem && dataItem.inspected_id) {
      setIncludeOnForm({ eav_template_id: inspectionTemplateId, inspected_id: dataItem.inspected_id })
    } else setIncludeOnForm({ eav_template_id: inspectionTemplateId })
  }, [dataItem])

  useEffect(() => {
    if (dataItem.inspected_type === 'Scaffolding') {
      const params = {
        requestAction: 'READ',
        httpAction: 'get',
        query: { where: { id: dataItem.inspected_id } }
      }
      fetch('scaffoldings', params, {
        onSuccess: ({ data }) => {
          const { description, tag_number: tagNumber } = data.data[0]
          setInspectedObj({ tagNumber, description })
        }
      })
    }
  }, [dataItem, fetch])

  const formReady = () => (
    <FormWrapper
      model={model}
      dataItem={dataItem}
      label={formLabel}
      badge={dataItem && <InspectionStatusLabel dataItem={dataItem} />}
      type={type}
      sidePanelSections={sectionsWithGeneral}
      isInBatch={isInBatch}
    >
      <Form
        key="main-form"
        model={model}
        sections={sections}
        dataItem={dataItem}
        includeOnForm={includeOnForm}
        templateId={inspectionTemplateId}
        type={isDuplicate() ? 'new' : type}
        isDuplicate={isDuplicate()}
        disableColumnEdition={disabledColumns}
        customDispatchEvent={customDispatchEvent}
      />
      <FormButtons
        model={model}
        awaitFormSuccess
      />
    </FormWrapper>
  )
  return formReady()
}
InspectionForm.propTypes = {
  dataItem: PropTypes.oneOfType([
    PropTypes.shape({
      inspected: PropTypes.oneOfType([PropTypes.object]),
      inspected_type: PropTypes.string
    }),
    PropTypes.shape({
      items: PropTypes.oneOfType([PropTypes.array])
    })
  ]),
  templateId: PropTypes.number.isRequired,
  type: PropTypes.string,
  opts: PropTypes.oneOfType([PropTypes.object])
}
InspectionForm.defaultProps = {
  dataItem: {
    inspected: {},
    inspected_type: 'Request'
  },
  opts: null
}
