/* eslint-disable max-lines-per-function */
import React, { useState, useMemo, useCallback } from 'react'
import { useStore } from 'react-context-hook'
import { MdThumbUp, MdThumbDown } from 'react-icons/md'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import useFormModal from '/src/ui/core/popups/form_modal'
// eslint-disable-next-line import/no-cycle
import useInspectionAcceptancePopupFields from '/src/ui/domain/inspections/inspection_acceptance_popup_fields'
import useInspectionAcceptanceError from '/src/ui/domain/inspections/inspection_acceptance_error'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import '/src/static/css/domain/inspections/inspection_acceptance_popup.css'

/**
 * Custom hook that exposes renderModal() and openModal() functions
 * for inspection acceptance
 *
 * @param dataItem
 * @return {[*, *]}
 * @constructor
 */
export default function useInspectionAcceptancePopup() {
  const { fetch } = useFetch()
  const [selectedItems, setSelectedItems] = useState([])
  const [inspectionsWithError, setInspectionsWithError] = useState()
  const [employees] = useStore('employees')

  const inspectionsHaveSameResponsible =
    selectedItems.every((item) => item.responsible_id === selectedItems[0].responsible_id)

  const isResponsibleForInspection = useCallback((responsibleId) => {
    return Object.values(employees).find((employee) => employee.id === responsibleId)?.is_inspection_responsible
  }, [employees])

  const buildDefaultValues = useMemo(() => {
    if (selectedItems.length === 1) {
      if (!isResponsibleForInspection(selectedItems[0].responsible_id))
        return { ...selectedItems[0], responsible_id: null } 
      return selectedItems[0]
    }
    if (selectedItems.length > 1 && inspectionsHaveSameResponsible)
      return { responsible_id: selectedItems[0].responsible_id }
    return null
  }, [inspectionsHaveSameResponsible, selectedItems, isResponsibleForInspection])

  const [formData, sections] = useInspectionAcceptancePopupFields(buildDefaultValues, selectedItems.length)
  const [inspectStatuses] = useStore('inspect_statuses')
  const inspectionStatusesArray = inspectStatuses ? Object.values(inspectStatuses) : []
  const pendingInspection = inspectionStatusesArray.find((e) => e.i18n_id === 'pending')
  const [setOpenErrorModal, renderErrorModal] = useInspectionAcceptanceError(inspectionsWithError)

  const getinspectionsWithError = useCallback((inspectionErrors) => {
    if (!inspectionErrors) return null

    return selectedItems.map((item) => {
      if (Object.keys(inspectionErrors).includes(item.id.toString()))
        return { ...item, errorReason: inspectionErrors[item.id] }
      return null
    }).filter((item) => item)
  }, [selectedItems])

  const denyAcceptance = useCallback(() => {
    return (
      !pendingInspection ||
      !formData ||
      !selectedItems ||
      !formData.inspect_date ||
      !formData.responsible_id
    )
  }, [formData, pendingInspection, selectedItems])

  const dispatchErrorModal = useCallback((wrongInspections) => {
    dispatch(BusEvents.HIDE_DIALOG)
    dispatch(BusEvents.FORM_SUCCESS)
    setInspectionsWithError(getinspectionsWithError(wrongInspections))
    setOpenErrorModal(true)
  }, [getinspectionsWithError, setOpenErrorModal])

  const updateSingleInspection = useCallback((action) => {
    const params = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: selectedItems[0]?.id,
      additionalResource: { path: action }
    }

    fetch(`inspections`, params, {
      onSuccess: () => {
        dispatch(BusEvents.HIDE_DIALOG)
        notifySuccess(I18n.t(`inspections.notifications.${action}_successful`))
        dispatch(BusEvents.FORM_SUCCESS)
        dispatch(BusEvents.RELOAD_GRID)
      },
      onError: () => {
        dispatch(BusEvents.HIDE_DIALOG)
      }
    })
  }, [fetch, selectedItems])

  const updateInspectionData = useCallback((action) => {
    const params = {
      requestAction: 'UPDATE',
      httpAction: 'put',
      resourceId: selectedItems[0]?.id,
      data: {
        responsible_id: formData.responsible_id,
        inspect_date: formData.inspect_date
      }
    }

    fetch(`inspections`, params, {
      onSuccess: () => {
        updateSingleInspection(action)
      },
      onError: () => {
        dispatch(BusEvents.HIDE_DIALOG)
      }
    })
  }, [fetch, formData, selectedItems, updateSingleInspection])

  
  const updateBatchInspections = useCallback((action) => {
    const params = {
      requestAction: 'UPDATE',
      httpAction: 'post',
      data: {
        inspection_ids: selectedItems.map((item) => item.id),
        responsible_id: formData.responsible_id,
        inspect_date: formData.inspect_date
      }
    }

    fetch(`inspections/${action}_in_batch`, params, {
      onSuccess: ({ data, status }) => {
        if (status === 200) {
          dispatch(BusEvents.HIDE_DIALOG)
          notifySuccess(I18n.t(`inspections.notifications.${action}_successful`))
          dispatch(BusEvents.FORM_SUCCESS)
          dispatch(BusEvents.RELOAD_GRID)
        }
        else {
          dispatchErrorModal(data)
        }
      },
      onError: () => {
        dispatch(BusEvents.HIDE_DIALOG)
      }
    })
  }, [dispatchErrorModal, fetch, formData, selectedItems])

  const acceptanceCall = useCallback((e, button) => {
    e.preventDefault()
    dispatch(BusEvents.SHOW_LOADING_DIALOG)
    if (selectedItems.length === 1) 
      updateInspectionData(button.type)
    else updateBatchInspections(button.type)
  }, [selectedItems, updateBatchInspections, updateInspectionData])

  const buttons = useMemo(() => {
    return [
      {
        id: 1,
        text: I18n.t('actions.reject'),
        type: 'reject',
        onClick: acceptanceCall,
        halfWidth: true,
        disabled: denyAcceptance(),
        class: 'reject-button',
        icon: <MdThumbDown />
      },
      {
        id: 2,
        text: I18n.t('actions.approve'),
        type: 'approve',
        onClick: acceptanceCall,
        halfWidth: true,
        disabled: denyAcceptance(),
        class: 'action-button',
        icon: <MdThumbUp />
      }
    ]
  }, [acceptanceCall, denyAcceptance])

  const modalLabel = useCallback(() => {
    if (selectedItems.length === 1) {
      if (selectedItems[0].inspected_type === 'Scaffolding') {
        if (selectedItems[0].tag_number) return `${selectedItems[0].tag_number} - #${selectedItems[0].number}`

        return `#${selectedItems[0].number}`
      }

      return `${selectedItems[0]?.request_id} - #${selectedItems[0]?.number}`
    }
    return I18n.t('inspections.acceptance_modal.items', { items: selectedItems.length })
  }, [selectedItems])

  const modalProps = useMemo(() => {
    return {
      className: 'inpection-acceptance-popup',
      modalTitle: I18n.t('inspections.acceptance'),
      formTitle: I18n.t('inspections.acceptance_modal.inspection_acceptance'),
      formSubtitle: I18n.t('inspections.acceptance_modal.subtitle'),
      label: modalLabel(),
      sections,
      buttons,
      dataItem: formData,
      model: {},
      width: 30.5,
      height: 60,
      sectionable: false,
      closable: true,
      onClose: () => setSelectedItems([]),
    }
  }, [buttons, formData, sections, modalLabel])

  const [openModal, renderModal] = useFormModal(modalProps)

  useBus(
    BusEvents.OPEN_MODAL,
    ({ payload }) => {
      if (payload && payload.type === 'acceptance' && payload.data.length > 0) {
        setSelectedItems(() => payload.data)
        openModal()
      }
    },
    [setSelectedItems]
  )

  const renderAcceptanceAndError = () => {
    return (
      <React.Fragment>
        {renderModal()}
        {renderErrorModal()}
      </React.Fragment>
    )
  }

  return renderAcceptanceAndError
}
