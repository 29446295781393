import React, { useCallback, useState } from 'react'
import { MdChair, MdLocationOn, MdPinDrop, MdOutlineLabel } from 'react-icons/md'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { sortColumnsByPosition } from '/src/models/concerns/eav_column'
import getFirstStringOrFormula from '/src/ui/domain/eav_columns/get_first_string_or_formula'
import RequestStatusLabel from '/src/ui/domain/requests/request_status_label'
import ScaffoldingStatusLabel from '/src/ui/domain/scaffoldings/scaffolding_status_label'
import InspectedTypeLabel from '/src/ui/domain/inspections/inspected_status_label'
import useInspectedUtils from '/src/ui/domain/inspections/inspected_utils'
import '/src/static/css/domain/inspections/inspected_track_block.css'

export default function InspectedTrackBlock({ dataItem, inspectedSections, isLoading }) {
  const [showLoading, setShowLoading] = useState(false)
  const [inspectedItem, setInspectedItem] = useState({})
  const [inspectedRequest, setInspectedRequest] = useState({})

  const restrainInspectedType = (types) => {
    return types.includes(dataItem.inspected_type)
  }

  const buildDpmsidTag = (isLabel) => {
    if (isLabel) return !restrainInspectedType(['Scaffolding']) ? I18n.t('track_block.id') : I18n.t('scaffoldings.tag')

    return !restrainInspectedType(['Scaffolding']) ? `#${inspectedRequest.id}` : `#${inspectedItem.tag_number}`
  }

  const buildReasonComments = (isLabel = false) => {
    return isLabel
      ? `${I18n.t('inspections.inspected_track.reason')} - ${I18n.t('inspections.inspected_track.comments')}`
      : `${inspectedRequest.reason}${inspectedRequest.comments ? ` - ${inspectedRequest.comments}` : ''}`
  }

  const getModuleDescription = useCallback(() => {
    if (!inspectedItem || !Array.isArray(inspectedSections) || inspectedSections.length === 0) return ''
    let unsectionedEavColumns = []
    inspectedSections.forEach((section) => {
      unsectionedEavColumns = unsectionedEavColumns.concat(section.eav_columns)
    })
    const firstColumn = getFirstStringOrFormula(sortColumnsByPosition(unsectionedEavColumns))
    return firstColumn ? { title: firstColumn.title, value: inspectedItem[firstColumn.description] } : null
  }, [inspectedSections, inspectedItem])

  const moduleDescription = getModuleDescription()

  useInspectedUtils({
    inspectedType: 'Request',
    inspectedId: dataItem.request_id,
    setShowLoading,
    setInspectedItem: setInspectedRequest
  })

  useInspectedUtils({
    inspectedType: dataItem.inspected_type,
    inspectedId: dataItem.inspected_id,
    setShowLoading,
    setInspectedItem
  })

  return showLoading || isLoading ? (
    <ThreeDotsLoader />
  ) : (
    <div className="detail-summary">
      {(restrainInspectedType(['Request']) && inspectedRequest.id) || inspectedItem.id ? (
        <div className="information">
          <div className="title-wrapper">
            <div className="title-side-panel">
              {buildDpmsidTag(true)}
              <span className="summary-request-id">{buildDpmsidTag(false)}</span>
            </div>

            {restrainInspectedType(['Scope', 'Progress', 'Scaffolding']) && (
              <span className="summary-item scaffolding">
                {!restrainInspectedType(['Scaffolding']) ? (
                  I18n.t('inspections.inspected_track.item', { item: inspectedItem.number })
                ) : (
                  <>
                    <img src="/static/svg/scaffolding-measuring-tape.svg" alt="scaffolding-measuring-tape" />
                    {`${inspectedItem.total_m3}m³`}
                  </>
                )}
              </span>
            )}
          </div>
          {!restrainInspectedType(['Scaffolding']) ? (
            <div className="discipline-block">
              <MdChair />
              <span className="discipline-label">{inspectedRequest.discipline}</span>
            </div>
          ) : (
            <div className="discipline-block">
              {inspectedItem.wind_exposed && (
                <>
                  <img src="/static/svg/wind-exposed.svg" alt="scaffolding-measuring-tape" />
                  <span className="discipline-label">{I18n.t('scaffoldings.wind_exposed')}</span>
                </>
              )}
            </div>
          )}
          <div className="status-block">
            <InspectedTypeLabel
              icon={
                <img
                  src={`/static/svg/inspected-${dataItem.inspected_type.toLowerCase()}.svg`}
                  alt={`inspected-${dataItem.inspected_type.toLowerCase()}`}
                />
              }
              label={I18n.t(`inspections.inspected_modules.${dataItem.inspected_type.toLowerCase()}`)}
            />
            {restrainInspectedType(['Request', 'Scope', 'Progress']) ? (
              <RequestStatusLabel model="request" dataItem={inspectedRequest} />
            ) : (
              <ScaffoldingStatusLabel dataItem={inspectedItem} />
            )}
          </div>
          {restrainInspectedType(['Scaffolding']) ? (
            <>
              <div>
                <div className="summary-label">{I18n.t('inspections.inspected_track.description')}</div>
                <div className="summary-value limited">
                  <CustomTooltip position="bottom" parentTitle>
                    <span title={inspectedItem.description}>{inspectedItem.description}</span>
                  </CustomTooltip>
                </div>
              </div>
              {inspectedItem.comment && (
                <div>
                  <div className="summary-label">{I18n.t('inspections.inspected_track.comments')}</div>
                  <div className="summary-value limited">
                    <CustomTooltip position="bottom" parentTitle>
                      <span title={inspectedItem.comment}>{inspectedItem.comment}</span>
                    </CustomTooltip>
                  </div>
                </div>
              )}
            </>
          ) : (
            <div>
              <div className="summary-label">{buildReasonComments(true)}</div>
              <div className="summary-value limited">
                <CustomTooltip position="bottom" parentTitle>
                  <span title={buildReasonComments()}>{buildReasonComments()}</span>
                </CustomTooltip>
              </div>
            </div>
          )}
          {restrainInspectedType(['Scope', 'Progress']) && moduleDescription && (
            <div>
              <div className="summary-label">{moduleDescription.title}</div>
              <div className="summary-value limited">
                <span>{moduleDescription.value}</span>
              </div>
            </div>
          )}
          <div className="side-tags">
            {(inspectedRequest.area || (restrainInspectedType(['Scaffolding']) && inspectedItem.area)) && (
              <div>
                <div className="summary-label">{I18n.t('inspections.inspected_track.area')}</div>
                <div className="summary-value labeled">
                  <MdLocationOn />
                  {!restrainInspectedType(['Scaffolding']) ? inspectedRequest.area : inspectedItem.area}
                </div>
              </div>
            )}
            {(inspectedRequest.subarea || (restrainInspectedType(['Scaffolding']) && inspectedItem.subarea)) && (
              <div>
                <div className="summary-label">{I18n.t('inspections.inspected_track.subarea')}</div>
                <div className="summary-value labeled">
                  <MdPinDrop />
                  {!restrainInspectedType(['Scaffolding']) ? inspectedRequest.subarea : inspectedItem.subarea}
                </div>
              </div>
            )}
            {restrainInspectedType(['Scaffolding']) && inspectedItem.number_of_tags && (
              <div>
                <div className="summary-label">{I18n.t('inspections.inspected_track.number_of_tags')}</div>
                <div className="summary-value labeled">
                  <MdOutlineLabel />
                  {inspectedItem.number_of_tags}
                </div>
              </div>
            )}
          </div>
        </div>
      ) : (
        <span className="summary-label">{I18n.t('inspections.inspected_track.no_load')}</span>
      )}
    </div>
  )
}

InspectedTrackBlock.propTypes = {
  dataItem: PropTypes.shape({
    inspected_type: PropTypes.string,
    inspected_id: PropTypes.number,
    request_id: PropTypes.number
  }).isRequired,
  inspectedSections: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  isLoading: PropTypes.bool
}
InspectedTrackBlock.defaultProps = {
  inspectedSections: [],
  isLoading: false
}
