import React, { useCallback, useMemo, useState } from 'react'
import ConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file_modal'
import { isColumnPrintable } from '/src/models/concerns/grid'

export default function useConfigurePrintGridFile(gridColumns, gridDataSource, model) {
  const [isDialogOpened, setIsDialogOpened] = useState(false)

  const hasColumns = gridColumns && gridColumns.length > 0
  const hasItems = gridDataSource && gridDataSource.total > 0

  const isPrintable = hasColumns && hasItems

  const openPrintGridCofiguration = useCallback(() => setIsDialogOpened(true), [])

  const printableColumns = useMemo(() => gridColumns && gridColumns.filter(isColumnPrintable), [gridColumns])

  const popupComponent = isDialogOpened && (
    <ConfigurePrintGridFile onClose={() => setIsDialogOpened(false)} columns={printableColumns} model={model} />
  )

  return [openPrintGridCofiguration, popupComponent, isPrintable]
}
