import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import ScaffoldingModel from '/src/models/scaffolding'
import ScaffoldingDetailSummary from '/src/ui/domain/scaffoldings/scaffolding_detail_summary'
import { transformSectionsWithColumns, filterInfoTabSections } from '/src/models/concerns/transform_sections'
import GeneralBlock from '/src/ui/core/blocks/general_block'
import I18n from '/src/utils/translations'
import ScaffoldingCellFactory from '/src/ui/domain/scaffoldings/scaffolding_cell_factory'
import { getGalleryTab, getInfoTab, getInspectionsTab } from '/src/ui/core/tabs/get_tabs'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import '/src/static/css/model_side_panel.css'

const model = new ScaffoldingModel()

export default function ScaffoldingsSidePanel({ dataItem, onClose, hideSummaryButtons }) {
  const [subproject] = useStore('subproject')

  const isColumnVisible = useCallback(
    (column) => {
      return !(column.hideOnSidePanel || (column.hideOnSubProject && column.hideOnSubProject(subproject)))
    },
    [subproject]
  )

  const scaffoldingSections = useMemo(() => {
    const { sections, columns } = model
    return transformSectionsWithColumns(isColumnVisible, filterInfoTabSections(sections), columns)
  }, [isColumnVisible])

  const infoTab = useMemo(() => {
    const generalBlocks = scaffoldingSections.map(({ id, title, columns }) => ({
      component: (
        <GeneralBlock
          model={{
            columns: columns.filter(({ visible_on_web }) => visible_on_web)
          }}
          dataItem={dataItem}
          columnFactory={<ScaffoldingCellFactory dataItem={dataItem} />}
        />
      ),
      title,
      type: 'section',
      key: id
    }))

    const summaryBlock = dataItem
      ? [
          {
            component: <ScaffoldingDetailSummary dataItem={dataItem} hideButtons={hideSummaryButtons} />,
            title: '',
            type: 'track'
          }
        ]
      : []
    return getInfoTab([...summaryBlock, ...generalBlocks])
  }, [dataItem, scaffoldingSections, hideSummaryButtons])

  const galleryTab = useMemo(
    () =>
      getGalleryTab({
        modules: [
          {
            moduleName: 'scaffolding',
            sections: model.galleryTabSection,
            dataItem
          }
        ],
        loading: false,
        defaultExpand: 0
      }),
    [dataItem]
  )

  const inspectionsTab = getInspectionsTab(dataItem, "scaffolding", !hideSummaryButtons)

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('scaffoldings.scaffolding_detail.side_panel_title')}
        closable={!hideSummaryButtons}
        onClose={onClose}
      >
        <SidePanelTabs tabs={[infoTab, galleryTab, inspectionsTab]} />
      </BlocksSidePanel>
    </div>
  )
}

ScaffoldingsSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  onClose: PropTypes.func.isRequired,
  hideSummaryButtons: PropTypes.bool
}

ScaffoldingsSidePanel.defaultProps = {
  dataItem: null,
  hideSummaryButtons: false
}
