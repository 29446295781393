import React, { useEffect, useState } from 'react'
import { useStore } from 'react-context-hook'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { MdAddCircle, MdDelete, MdModeEdit } from 'react-icons/md'
import { pluralize } from 'inflected'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useFetchById from '/src/hooks/api/fetch_by_id'
import DottedButton from '/src/ui/core/buttons/dotted_button'
import HistoriesFeedItem from '/src/ui/domain/progresses/histories_feed_item'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import HistoryPopup from '/src/ui/domain/progresses/history_popup'
import ThreeDotsLoader from '/src/ui/core/loaders/three_dots_loader'
import '/src/static/css/domain/progresses/histories_feed.css'

export default function HistoriesFeed({ level, itemId, onOpenForm, onChangeHistoryItem }) {
  const historiesRoute = `${level}_histories`

  const [feedItems, setFeedItems] = useState([])
  const [quantityUnit, setQuantityUnit] = useState('')
  const [openHistoryPopup, setOpenHistoryPopup] = useState(false)
  const entityItem = useFetchById(pluralize(level), itemId)
  const [historyItem, setHistoryItem] = useState({})
  const [deletePopupEnabled, setDeletePopupEnabled] = useState(false)
  const [selectedItem, setSelectedItem] = useState(null)
  const { status, responseData, fetchAPI } = useFetchAPI(historiesRoute)
  const [actionVisibility, setActionVisibility] = useState(true)

  const statusFetch = useFetchAPI(`${level}_statuses`)
  const [canceledProgress, setCanceledProgress] = useState()
  const [doneProgress, setDoneProgress] = useState()

  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  useEffect(() => {
    statusFetch.fetchAPI(level)
  }, [])

  useEffect(() => {
    if (statusFetch.status !== 'SUCCESS') return

    const statuses = statusFetch.responseData.data
    setCanceledProgress(statuses.find((e) => e.i18n_id === 'canceled'))
    setDoneProgress(statuses.find((e) => e.i18n_id === 'done'))
  }, [statusFetch.status, statusFetch.responseData])

  const fetchHistoryItems = () => {
    if (!itemId) return

    const formattedLevelName = `${level}_id`
    const requestQueryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { [formattedLevelName]: itemId } }
    }

    fetchAPI(requestQueryParams)
  }

  useBus(BusEvents.RELOAD_GRID, () => fetchHistoryItems(), [fetchHistoryItems])

  useEffect(() => {
    fetchHistoryItems()
  }, [itemId, level])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const sortedFeed = responseData.data.sort((item1, item2) => {
      return Date.parse(item2.date) - Date.parse(item1.date)
    })

    setFeedItems(sortedFeed)
  }, [status, responseData])

  useEffect(() => {
    if (!entityItem) return

    if (level === 'progress_service') setQuantityUnit(entityItem.contract_service.unit.description)
  }, [entityItem, level])

  useEffect(() => {
    if (!entityItem || !canceledProgress || !doneProgress) return

    const statusId = `${level}_status_id`
    setActionVisibility(
      entityItem[statusId] !== canceledProgress.id &&
        entityItem[statusId] !== doneProgress.id &&
        entityItem.quantity !== 0
    )
  }, [entityItem, level, canceledProgress, doneProgress])

  const onAddNewProgress = () => {
    if (onOpenForm) {
      onOpenForm()
      return
    }
    setOpenHistoryPopup(true)
  }

  const onSuccessAction = () => {
    notifySuccess()
    setDeletePopupEnabled(false)
    fetchHistoryItems()
    dispatch(BusEvents.RELOAD_GRID)
  }

  const createItemActions = (item) => [
    {
      text: I18n.t('actions.edit'),
      icon: <MdModeEdit />,
      onClick: () => {
        if (onChangeHistoryItem) onChangeHistoryItem(item)
        setHistoryItem(item)
        onAddNewProgress()
      }
    },
    {
      text: I18n.t('actions.delete'),
      icon: <MdDelete />,
      onClick: () => {
        setDeletePopupEnabled(true)
        setSelectedItem(item.id)
        const confirmationParams = {
          title: I18n.t('delete_modal.title'),
          description: I18n.t('delete_modal.description', { model: `${level}_histories`, id: item.id }),
          actionButtonText: I18n.t('delete_modal.ok'),
          cancelButtonText: I18n.t('delete_modal.cancel'),
          buttonColor: '#B33939',
          onSuccess: () => onSuccessAction,
          onCancel: () => () => setDeletePopupEnabled(false),
          requestParams: {
            resourcePath: `${level}_histories`,
            requestAction: 'DELETE',
            httpAction: 'delete',
            resourceId: item.id
          }
        }
        showConfirmation(confirmationParams)
      }
    }
  ]

  return !entityItem || status !== 'SUCCESS' || statusFetch.status !== 'SUCCESS' ? (
    <div className="loading-feed">
      <ThreeDotsLoader />
    </div>
  ) : (
    <div className="histories-feed">
      <HistoryPopup
        parentDataItem={entityItem}
        open={openHistoryPopup}
        onClose={() => {
          if (onOpenForm) {
            onOpenForm()
            return
          }
          setOpenHistoryPopup(false)
          setHistoryItem({})
        }}
        level={level}
        historyDataItem={historyItem}
      />
      {actionVisibility && (
        <DottedButton
          title={I18n.t('progress_histories.add_new_progress')}
          icon={<MdAddCircle size={20} />}
          onClick={onAddNewProgress}
        />
      )}
      {!actionVisibility && entityItem.quantity === 0 && (
        <div className="empty-historic">{I18n.t('progress_histories.empty_historic')}</div>
      )}
      <div className="line-separator" />
      <div className="feed-container">
        <div className="feed-wrapper">
          <div className="feed-vertical-bar" />
          <div className="feed-items">
            {renderConfirmation()}
            {feedItems.map((item, index) => (
              <HistoriesFeedItem
                key={item.id}
                level={level}
                item={item}
                entityDataItem={entityItem}
                itemActions={createItemActions(item)}
                markerDisabled={index !== 0}
                deletePopupEnabled={selectedItem === item.id && deletePopupEnabled}
                contractServiceUnit={quantityUnit}
                actionsVisible={actionVisibility}
              />
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

HistoriesFeed.propTypes = {
  level: PropTypes.string.isRequired,
  itemId: PropTypes.number.isRequired
}
