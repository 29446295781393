import React, { useState, useMemo } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import useTemplatesCardsBlocks from '/src/ui/domain/estimates/templates_cards_blocks'
import useEstimateServicesCardsBlocks from '/src/ui/domain/estimates/estimate_services_cards_blocks'
import ExpandableCardList from '/src/ui/core/cards/expandable_card_list'
import CardList from '/src/ui/core/cards/card_list'
import EstimateRequestLabel from '/src/ui/domain/estimates/estimate_request_label'
import I18n from '/src/utils/translations'
import '/src/static/css/domain/estimates/estimate_services_list.css'

export default function EstimateServicesList({
  index,
  request,
  estimate,
  estimateLog,
  estimateServices,
  statuses,
  templates,
  columnsByTemplate,
  units,
  expanded,
  employee,
  abbreviatedCard
}) {
  const [showServices, setShowServices] = useState(true)

  const estimatesListClassName = classNames('estimate-list-on-page', {
    'page-break': index > 0
  })

  const newServices = useMemo(
    () =>
      estimateServices.map((service) => {
        const newService = { ...service }

        const { contract_service: contractService } = newService
        newService.unit = contractService ? units[contractService.unit_id] : { description: '' }
        newService.columns = columnsByTemplate[newService.eav_template_id] || []
        return newService
      }),
    [estimateServices, units]
  )

  const servicesCardsParts = useEstimateServicesCardsBlocks(newServices, abbreviatedCard)

  const newTemplates = useMemo(() => {
    const updatedTemplates = { ...templates }

    const updatedTemplatesValues = Object.values(updatedTemplates).map((template) => {
      const updatedTemplate = { ...template }
      updatedTemplate.items = []
      return updatedTemplate
    })

    if (servicesCardsParts.length > 0) {
      servicesCardsParts.forEach((cardParts) => {
        if (!cardParts) return

        const template = updatedTemplatesValues.find((t) => t.id === cardParts.templateId)

        if (template) {
          template.items.push(cardParts)
        }
      })
    }

    return updatedTemplatesValues.filter((template) => template.items.length > 0)
  }, [servicesCardsParts, templates])

  const templatesCardsParts = useTemplatesCardsBlocks(newTemplates)

  const getCardLabels = (items) => {
    return items.map((item) => {
      const { templateId } = item
      const columns = columnsByTemplate[templateId]
      if (!columns) return item

      const visibleColumns = columns.filter((column) => column.preview_on_mobile)
      if (!visibleColumns) return item

      const labels = visibleColumns
        .map((column) => {
          const estimateService = estimateServices.find((service) => {
            return service.eav_template_id === templateId
          })

          if (!estimateService) return null

          const value = estimateService[column.description] ?? I18n.t('fields.na')
          return { columnTitle: column.title, columnValue: value }
        })
        .filter((label) => label !== null)

      return labels.length ? { ...item, labels } : item
    })
  }

  const blocks = useMemo(() => {
    if (templatesCardsParts.length === 0) {
      return []
    }
    return templatesCardsParts.map((cardParts) => {
      const { items, key } = cardParts
      if (items && items.length > 0) {
        const itemsWithLabels = getCardLabels(items)
        const body = <CardList blocksCollection={itemsWithLabels} key={key} />
        return { ...cardParts, body }
      }
      return cardParts
    })
  }, [templatesCardsParts])

  const onShowCallback = (value) => setShowServices(value)

  return (
    <div key={request.id} className={estimatesListClassName}>
      <EstimateRequestLabel
        index={index}
        request={request}
        estimate={estimate}
        estimateLog={estimateLog}
        statuses={statuses}
        employee={employee}
        onShowCallback={onShowCallback}
      />
      <div className={`estimate-service-list ${!showServices ? 'no-display' : ''}`}>
        <ExpandableCardList key={request.id} blocksCollection={blocks} expanded={expanded} />
      </div>
    </div>
  )
}

EstimateServicesList.propTypes = {
  index: PropTypes.number.isRequired,
  request: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    reason: PropTypes.string,
    discipline: PropTypes.string
  }).isRequired,
  estimate: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    estimate_cost: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    date_start: PropTypes.string
  }),
  estimateLog: PropTypes.object,
  estimateServices: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
    })
  ),
  statuses: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
  }),
  templates: PropTypes.object,
  columnsByTemplate: PropTypes.object,
  units: PropTypes.object,
  expanded: PropTypes.bool,
  employee: PropTypes.object,
  abbreviatedCard: PropTypes.bool
}

EstimateServicesList.defaultProps = {
  estimate: {},
  estimateLog: {},
  estimateServices: [],
  statuses: {},
  templates: {},
  columnsByTemplate: {},
  units: {},
  expanded: false,
  employee: undefined,
  abbreviatedCard: true
}
