import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import { Tooltip } from '@progress/kendo-react-tooltip'

export default function TooltipIconButton({ children, onClick, title, className }) {
  return (
    <Tooltip position="bottom" anchorElement='target' content={() => title} parentTitle>
      <button
        type="button"
        title={title}
        data-testid="tooltip-icon-button"
        className={classnames(className, 'hover-brighten')}
        onClick={onClick}
      >
        {children}
      </button>
    </Tooltip>
  )
}

TooltipIconButton.propTypes = {
  onClick: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
  children: PropTypes.element.isRequired
}

TooltipIconButton.defaultProps = {
  onClick: () => {},
  className: '',
  title: ''
}
