import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import { useStore } from 'react-context-hook'
import CircleLabel from '/src/ui/core/fields/circle_label'
import { MdOutlineSearchOff } from 'react-icons/md'

export default function MissingColumn({ dataItem }) {
  return dataItem.has_inspection ? (
    ''
  ) : (
    <CircleLabel
      className={'rounded-label-caramel'}
      value={I18n.t('scaffoldings.missing_initial_control')}
      icon={<MdOutlineSearchOff />}
    />
  )
}

MissingColumn.propTypes = {
  dataItem: PropTypes.object
}

MissingColumn.defaultProps = {
  dataItem: {}
}
