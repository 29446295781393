import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import BlocksSidePanel from '/src/ui/core/grid/side_panel/blocks_side_panel'
import SidePanelTabs from '/src/ui/core/grid/side_panel/side_panel_tabs'
import { getGalleryTab, getInfoTab } from '/src/ui/core/tabs/get_tabs'
import useSectionBlock from '/src/ui/core/blocks/section_block_hook'
import useInspectedData from '/src/hooks/inspected_data'
import InspectionTrackBlock from '/src/ui/domain/requests/inspection_track_block'
import InspectionStatusLabel from '/src/ui/domain/inspections/inspection_status_label'
import InspectionActionButtons from '/src/ui/domain/inspections/inspection_action_buttons'
import InspectedTrackBlock from '/src/ui/domain/inspections/inspected_track_block'
import RelatedInspectionsBlock from '/src/ui/domain/inspections/related_inspections_timeline'
import '/src/static/css/model_side_panel.css'

export default function InspectionsSidePanel({ dataItem, onClose, sections, actions }) {
  const [inspectedModuleName, inspectedData, inspectedSections, isLoading] = useInspectedData(dataItem)

  const buildSummary = () => {
    const actionButtons = () => <InspectionActionButtons dataItem={dataItem} actions={actions} />

    const inspectDate = dataItem.inspect_date
      ? dataItem.inspect_date
      : I18n.t('inspections.inspection_block.missing_date')

    const responsibleFullName = dataItem.responsible?.full_name
      ? dataItem.responsible.full_name
      : I18n.t('inspections.inspection_block.not_selected_yet')

    return {
      component: (
        <InspectionTrackBlock
          detail={{
            inspect_date: inspectDate,
            number: dataItem.number,
            responsible_full_name: responsibleFullName
          }}
          actions={actionButtons()}
        >
          <InspectionStatusLabel dataItem={dataItem} />
        </InspectionTrackBlock>
      ),
      title: '',
      type: 'track',
      key: 'inspection-track-block'
    }
  }

  const buildInspected = () => {
    return {
      component: (
        <InspectedTrackBlock dataItem={dataItem} inspectedSections={inspectedSections} isLoading={isLoading} />
      ),
      title: '',
      type: 'track',
      key: 'inspected-track-block'
    }
  }

  const buildRelatedInspections = () => {
    return {
      component: <RelatedInspectionsBlock dataItem={dataItem} isLoading={isLoading} sections={sections} />,
      title: I18n.t('inspections.related_inspections.title'),
      type: 'track',
      key: 'related-inspections-block',
      collapsible: true
    }
  }

  const getBlocks = (blocks) => {
    if (!dataItem) return null

    return [buildSummary(), buildInspected(), buildRelatedInspections(), ...blocks]
  }

  const initialSectionBlocks = useSectionBlock({ sections, dataItem })
  const infoBlocks = getBlocks(initialSectionBlocks)

  const infoTab = getInfoTab(infoBlocks)

  const galleryModules = [
    { moduleName: inspectedModuleName, sections: inspectedSections, dataItem: inspectedData },
    { moduleName: 'inspection', sections, dataItem }
  ]

  const galleryTab = getGalleryTab({ modules: galleryModules, loading: isLoading })

  const inspectionTabs = [infoTab, galleryTab]

  return (
    <div className="model-side-panel">
      <BlocksSidePanel
        title={I18n.t('inspections.inspections_detail.side_panel_title')}
        closable
        onClose={onClose}
        blocks={infoBlocks}
      >
        <SidePanelTabs tabs={inspectionTabs} />
      </BlocksSidePanel>
    </div>
  )
}

InspectionsSidePanel.propTypes = {
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  actions: PropTypes.arrayOf(PropTypes.object),
  sections: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClose: PropTypes.func.isRequired
}

InspectionsSidePanel.defaultProps = {
  dataItem: undefined,
  actions: []
}
