/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { pluralize, underscore } from 'inflected'
import { MdClose } from 'react-icons/md'
import { useStore } from 'react-context-hook'
import useCookie from '/src/hooks/cookie'
import useFetch from '/src/hooks/api/fetch'
import I18n from '/src/utils/translations'
import { notifySuccess } from '/src/ui/core/dialogs/notifications'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function Image({ image, onClick, showComment, columnId, onDelete, allowDelete }) {
  const [error, setError] = useState(false)
  const [getToken] = useCookie('authentication_token')
  const [project] = useStore('project')
  const [subproject] = useStore('subproject')
  const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`
  const initialSource = `${SERVER_URL + image.file_path}?remember_token=${getToken()}${subprojectQuery}`
  const [imageSource, setImageSource] = useState(initialSource)
  const [isHovered, setIsHovered] = useState(false)
  const [showConfirmation, renderConfirmation] = useConfirmationModal(true)

  const hasCommentAndShows = image.comment?.trim() && showComment
  const extraImageClass = hasCommentAndShows ? '' : 'round-overlay'

  const { fetch } = useFetch()

  const placeholderSource = '/static/svg/image-placeholder.svg'

  const deletePicture = () => {
    let path = 'pictures/remove'
    const queryParams = {
      requestAction: 'DESTROY',
      httpAction: 'delete',
    }

    if (columnId) {
      queryParams.resourceId = image.imageable_id
      queryParams.additionalResource = { path: 'delete_photo' }
      queryParams.data = {
        eav_column_id: columnId,
        uuid: image.uuid
      }
      path = pluralize(underscore(image.imageable_type))
    } else {
      queryParams.data = {
        uuid: image.uuid
      }
    }

    fetch(path, queryParams, {
      onSuccess: () => {
        notifySuccess(I18n.t('delete_picture_modal.delete_successful'))
        onDelete(image)
        dispatch(BusEvents.RELOAD_GRID)
      }
    })
  }

  const confirmationModalParams = {
    title: I18n.t('delete_picture_modal.title'),
    description: I18n.t('delete_picture_modal.description'),
    actionButtonText: I18n.t('delete_picture_modal.ok'),
    cancelButtonText: I18n.t('delete_picture_modal.cancel'),
    buttonColor: '#B33939',
    onSuccess: () => deletePicture
  }

  const handleDelete = () => {
    setIsHovered(false)
    showConfirmation(confirmationModalParams)
  }

  useEffect(() => {
    if (error) setImageSource(placeholderSource)
  }, [error])

  return (
    <>
      {renderConfirmation()}
      <div
        className="side-panel-image"
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <img
          className={error ? 'image-placeholder' : ''}
          src={imageSource}
          alt={image.uuid}
          onClick={() => {
            if (!error) onClick(image)
          }}
          onError={() => setError(true)}
        />
        {isHovered && (
          <React.Fragment>
            {hasCommentAndShows && (
              <div
                className="image-hover-comment"
                onClick={() => {
                  if (!error) onClick(image)
                }}
              >
                <p>{image.comment}</p>
              </div>
            )}
            {allowDelete && (
              <div
                className={`image-hover-delete ${extraImageClass}`}
                onClick={handleDelete}
                data-testid="delete-picture-x"
              >
                <MdClose />
              </div>
            )}
          </React.Fragment>
        )}
      </div>
    </>
  )
}

Image.propTypes = {
  image: PropTypes.shape({
    file_path: PropTypes.string.isRequired,
    uuid: PropTypes.string,
    comment: PropTypes.string,
    imageable_id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    imageable_type: PropTypes.string
  }).isRequired,
  onClick: PropTypes.func,
  onDelete: PropTypes.func,
  showComment: PropTypes.bool,
  allowDelete: PropTypes.bool,
  columnId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
}

Image.defaultProps = {
  onClick: () => { },
  onDelete: () => { },
  showComment: false,
  columnId: null,
  allowDelete: true
}
