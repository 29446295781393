import { useCallback, useState } from 'react'

const useEditableGridEditedItems = (getDataItemKey) => {
  const [editedItems, setEditedItems] = useState([])

  const updateEditedItems = useCallback(
    (nextDataSource) => {
      setEditedItems(
        nextDataSource.filter(
          (item) =>
            (item.to_be_deleted && item.id !== undefined) ||
            item.dirty ||
            (!item.to_be_deleted && item.id === undefined)
        )
      )
    },
    [setEditedItems]
  )

  const isItemInEdition = useCallback((item) => (
    editedItems.find((editedItem) => editedItem[getDataItemKey(editedItem)] === item[getDataItemKey(item)])
  ), [getDataItemKey, editedItems])

  return [editedItems, setEditedItems, updateEditedItems, isItemInEdition]
}

export default useEditableGridEditedItems
