import React, { useCallback, useEffect, useMemo, useState } from 'react'
import HistoriesFeed from '/src/ui/domain/progresses/histories_feed'
import HistoryForm from '/src/ui/domain/progresses/history_form'
import useBus from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'

export default function useProgressHistoryTab(level, dataItem) {
  const [isFormOpened, setIsFormOpened] = useState(false)
  const [historyItem, setHistoryItem] = useState()
  
  const isServiceCompleted = dataItem.progress_service_status_id === 5

  const onOpenForm = useCallback(() => {
    if (dataItem.quantity === 0 || isServiceCompleted) return
    setIsFormOpened(true)
  }, [dataItem.quantity, isServiceCompleted])

  useEffect(() => {
    setIsFormOpened(false)
  }, [dataItem])

  const tabBody = useMemo(() => {
    if (isFormOpened && !isServiceCompleted) return (
      <div className='progress-history-tab'>
        <HistoryForm
          parentDataItem={dataItem}
          historyDataItem={historyItem}
          onClose={() => {
            setIsFormOpened(false)
            setHistoryItem()
          }}
          level={level}
        />
      </div>
    )

    return (
      <div className="progress-history-tab">
        <HistoriesFeed
          level={level}
          itemId={dataItem?.id}
          onOpenForm={onOpenForm}
          onChangeHistoryItem={setHistoryItem}
        />
      </div>
    )
  }, [isFormOpened, historyItem, level, dataItem, isServiceCompleted, onOpenForm])

  useBus(BusEvents.OPEN_SIDE_PANEL_TAB, ({ payload }) => {
    const { name: tabName, openForm } = payload
    if (tabName === 'progress' && openForm && !isServiceCompleted) onOpenForm()
  }, [])

  useEffect(() => {
    const { dispatchProgressFormTab } = dataItem
    if (!dispatchProgressFormTab || isServiceCompleted) return
    onOpenForm()
  }, [dataItem.dispatchProgressFormTab, isServiceCompleted, onOpenForm, dataItem])

  return {
    name: 'progress',
    expandSidePanelWidth: true,
    body: tabBody
  }
}