import React from 'react'
import PropTypes from 'prop-types'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { MdFlipToFront } from 'react-icons/md'
import '/src/static/css/tooltip.css'
import I18n from '/src/utils/translations'

export default function SubRequestBadge({ show }) {
  return (
    show ? (
      <CustomTooltip
        position="top"
        anchorElement="target"
        openDelay={1}
        parentTitle
      >
        <div className='badge-subrequest-wrapper'>
          <span>
            <span
              title={I18n.t('requests.sub_request')}
              className='badge-subrequest'
              data-testid='subrequest-badge'
            >
              <MdFlipToFront />
            </span>
          </span>
        </div>
      </CustomTooltip>
    ) : null
  )
}

SubRequestBadge.propTypes = {
  show: PropTypes.bool
}

SubRequestBadge.defaultProps = {
  show: true
}
