import { useMemo, useCallback } from 'react'
import { addRequestByDisciplineQueryToColumns } from '/src/models/concerns/form'
import useAccessSolutionDisciplines from '/src/hooks/access_solution_disciplines'

const addMinimalDate = (columns, scaffolding) => {
  const updatedColumns = columns

  if (scaffolding) {
    const index = updatedColumns.findIndex((column) => column.description === 'dismantle_date')
    updatedColumns[index].min = scaffolding.erect_date
  }

  return updatedColumns
}

export default function useUpdateScaffoldingFormModel(formModel, scaffolding) {
  const accessSolutionDisciplines = useAccessSolutionDisciplines()

  const addedMinimalDate = useCallback(
    (columns) => addMinimalDate(columns, scaffolding),
    [scaffolding]
  )

  const model = useMemo(() => {
    let columnsUpdated = addRequestByDisciplineQueryToColumns(formModel.columns, null, accessSolutionDisciplines)
    columnsUpdated = addedMinimalDate(columnsUpdated)

    return { ...formModel, columns: columnsUpdated }
  }, [accessSolutionDisciplines, formModel, addedMinimalDate])

  return [model]
}
