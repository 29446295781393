/* eslint-disable react/jsx-one-expression-per-line */
/* eslint-disable react/no-array-index-key */
/* eslint-disable no-param-reassign */
import React, { useState, useEffect, useRef, useMemo, useCallback } from 'react'
import { useSetStoreValue } from 'react-context-hook'
import { Upload, ExternalDropZone } from '@progress/kendo-react-upload'
import { guid } from '@progress/kendo-react-common'
import PropTypes from 'prop-types'
import codes from 'http-status-codes'
import lodash from 'lodash'
import { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useCookie from '/src/hooks/cookie'
import I18n from '/src/utils/translations'
import KendoFileStatus from '/src/utils/kendo_file_status'
import { byString } from '/src/utils/object'
import { merge, remove } from '/src/utils/array'
import { defaultPictureRestrictions, PICTURE_CATEGORY_ID, formTypes } from '/src/models/concerns/attachment'
import ImageModal from '/src/ui/core/popups/image_modal'
import ImageThumbnail from '/src/ui/core/inputs/image_thumbnail/image_thumbnail'
import CommentSection from '/src/ui/core/inputs/image_thumbnail/comment_section'
import useInputError from '/src/ui/core/inputs/input_error'
import fixedPictureHintNote from '/src/ui/core/inputs/attachments/fixed_picture_hint_note'
import '/src/static/css/core/inputs/input_fixed_picture.css'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL
const MODULE = 'Scaffolding'
const uploadUrl = `${SERVER_URL}/api/v1/pictures`
const filesUuids = (item) => {
  if (!item || !item?.pictures) return []
  return item.pictures.map((pic) => pic.uuid)
}

function splitByValidationErrors(files) {
  const withErrors = []
  const withoutErrors = []

  files.forEach((file) => {
    if (file?.validationErrors?.length > 0) {
      withErrors.push(file)
    } else {
      withoutErrors.push(file)
    }
  })

  return { withErrors, withoutErrors }
}

const dropZoneClass = 'input-fixed-picture__drop-zone'
const dropZoneProgressClass = 'input-fixed-picture__drop-zone--progress'
const message = (status, body, customBody = undefined) => ({
  title: I18n.t(`form.inputs.fixed_picture.upload.${status}.title`),
  body: customBody || (body && I18n.t(`form.inputs.fixed_picture.upload.${status}.${body}`)),
  status,
  closable: true,
  closeTimeout: 10
})

export default function InputFixedPicture({ inputProps }) {
  const { id, readOnly, title, required, onChange, dataItem, className } = inputProps
  const [fullScreenOpen, setFullScreenOpen] = useState(false)
  const [selectedImageIndex, setSelectedImageIndex] = useState(0)
  const [getToken] = useCookie('authentication_token')
  const [innerFiles, setInnerFiles] = useState([])
  const setNotification = useSetStoreValue('notification')
  const isCreationForm = formTypes(dataItem) === 'new' || !dataItem?.id
  const { uniqBy } = lodash
  const timer = useRef(null)
  const [uuids, setUuids] = useState(filesUuids(dataItem))
  const uploadRef = useRef()
  const uploadContextRef = useRef()
  const [maxProgress, setMaxProgress] = useState(0)
  const [totalFilesSize, setTotalFilesSize] = useState(0)
  const [currentProgress, setCurrentProgress] = useState(0)

  const initFiles = () => {
    const itemFiles = dataItem?.pictures || []
    const kendoFiles = []

    if (itemFiles) {
      itemFiles.forEach((file) => {
        const fileName = file.photo_file_name
        const fileSize = file.photo_file_size

        kendoFiles.push({
          name: fileName,
          size: fileSize,
          file_category_id: file.file_category_id || PICTURE_CATEGORY_ID,
          uid: file.uuid,
          uuid: file.uuid,
          status: KendoFileStatus.uploaded,
          progress: 100
        })
      })
    }

    return kendoFiles
  }

  const [files, setFiles] = useState(initFiles())
  const anyFileUploaded = !!files.length
  const error = useInputError({ inputValue: files, title, required, type: 'array' })

  useEffect(() => {
    return () => {
      clearTimeout(timer.current)
    }
  }, [])

  useEffect(() => {
    onChange(id, uuids)
  }, [id, onChange, uuids])

  useEffect(() => {
    if (!innerFiles || innerFiles?.length === 0) {
      setCurrentProgress(0)
      setMaxProgress(0)
      setTotalFilesSize(0)
    }
    if (innerFiles.length && innerFiles.every((e) => e.status === KendoFileStatus.uploaded)) {
      setFiles((prevFiles) => uniqBy([...prevFiles, ...innerFiles], 'uuid'))

      setInnerFiles([])
    }
  }, [innerFiles, uniqBy, setFiles])

  const validateFiles = useCallback(
    (event) => {
      const { withErrors: invalidFiles, withoutErrors: allowedFiles } = splitByValidationErrors(event.affectedFiles)

      if (allowedFiles?.length) {
        setMaxProgress((prevMax) => Math.max(prevMax, allowedFiles?.length))
        setTotalFilesSize(allowedFiles.reduce((total, file) => total + (file.size || 0), 0))
        setInnerFiles(allowedFiles)
      }

      const invalids = invalidFiles?.length
      if (invalids) {
        if (invalids === 1 && !allowedFiles?.length) setNotification(message('error', 'invalid_picture'))
        else if (allowedFiles?.length) setNotification(message('error', 'multiple_files'))
        else setNotification(message('error', 'multiple_fails'))
      }
    },
    [setInnerFiles, setNotification]
  )

  const onStatusChange = useCallback(
    (event) => {
      if (!event.response) return

      if ([codes.OK, codes.CREATED].includes(event.response.status)) {
        // the way we input files on tests (by inserting on input-file directly)
        // will not wrapper they on newState
        let affectedIndex = event.newState.findIndex((file) => {
          return file.uid === event.affectedFiles[0].uid
        })

        const updatedEvent = { ...event }
        if (!updatedEvent.newState || !updatedEvent.newState.length) {
          updatedEvent.newState = [{ ...event.response.response, status: KendoFileStatus.uploaded }]
          affectedIndex = 0
        }

        updatedEvent.newState[affectedIndex].uuid = event.response.response.uuid
        updatedEvent.newState[affectedIndex].file_category_id =
          event.response.response.file_category_id || PICTURE_CATEGORY_ID

        const { withoutErrors: allowedFiles } = splitByValidationErrors(updatedEvent.newState)

        setInnerFiles(allowedFiles)
        const newUuid = event.response.response.uuid
        setUuids((oldUuids) => merge(oldUuids, [newUuid]))

        if (!isCreationForm) dispatch(BusEvents.RELOAD_GRID)

        if (timer.current) {
          clearTimeout(timer.current)
          timer.current = setTimeout(() => {
            setNotification(message('success', 'multiple_files'))
            timer.current = null
          }, 300)
        } else {
          clearTimeout(timer.current)
          timer.current = setTimeout(() => {
            setNotification(message('success', undefined))
            timer.current = null
          }, 300)
        }

        return
      }

      setInnerFiles(event.newState)

      const responseData = byString(event.response, 'response.data')
      if (responseData && responseData.error) {
        setNotification(message('error', undefined, event.response.response.data.error))
        return
      }

      setNotification(message('error', undefined))
    },
    [setNotification, setInnerFiles, setUuids, isCreationForm]
  )

  const openImageFullScreen = useCallback(
    (index) => {
      setSelectedImageIndex(index)
      setFullScreenOpen(true)
    },
    [setSelectedImageIndex, setFullScreenOpen]
  )

  const closeImageFullScreen = useCallback(() => {
    setFullScreenOpen(false)
  }, [setFullScreenOpen])

  const onDelete = useCallback(
    (picture) => {
      setFiles((prevFiles) => prevFiles.filter((file) => file.uuid !== picture.uuid))
      setUuids((prevUuids) => remove(prevUuids, [picture.uuid]))
    },
    [setFiles, setUuids]
  )

  const imagesWithComments = useMemo(() => {
    return (
      files &&
      files.map((file) => {
        const pictures = dataItem?.pictures || []
        let comment = file?.comment
        if (pictures?.length) comment = Object.values(pictures).find((pic) => pic.uuid === file.uuid)?.comment
        const image = {
          ...file,
          file_path: `/api/v1/pictures/${file.uuid}`,
          comment
        }
        if (!isCreationForm) {
          image.imageable_id = dataItem.id
          image.imageable_type = MODULE
        }
        return { image, comment }
      })
    )
  }, [files, dataItem?.id, dataItem?.pictures, isCreationForm])

  const uploadedFiles = useMemo(
    () => (
      <React.Fragment>
        {imagesWithComments.map(({ image }, index) => (
          <ImageThumbnail
            key={`${image.uuid}-${index}`}
            image={image}
            onDelete={onDelete}
            columnId={undefined}
            onClick={() => openImageFullScreen(index)}
          >
            <CommentSection image={image} />
          </ImageThumbnail>
        ))}
      </React.Fragment>
    ),
    [imagesWithComments, onDelete, openImageFullScreen]
  )

  const onBeforeUpload = useCallback(
    (event) => {
      event.additionalData.uuid = guid()
      event.headers.authorization = `Token ${getToken()}`
      if (!isCreationForm) {
        event.additionalData.imageable_id = dataItem?.id
        event.additionalData.imageable_type = MODULE
      }
    },
    [dataItem?.id, getToken, isCreationForm]
  )

  const onUploadingFile = useCallback(
    (event) => {
      if (event.affectedFiles[0].progress === 100) setCurrentProgress((prevCurrent) => prevCurrent + 1)
    },
    [setCurrentProgress]
  )

  const triggerFileSelect = useCallback(() => {
    if (uploadContextRef.current && !readOnly) uploadContextRef.current.querySelector('input[type="file"]').click()
  }, [readOnly])

  const { pictureHint, pictureNote } = fixedPictureHintNote({
    currentProgress,
    maxProgress,
    triggerFileSelect,
    totalFilesSize
  })

  const uploading = !!maxProgress

  return (
    <React.Fragment>
      <div className={`input-fixed-picture ${className}`}>
        <div ref={uploadContextRef}>
          <ExternalDropZone
            className={`${dropZoneClass} ${uploading && dropZoneProgressClass}`}
            uploadRef={uploadRef}
            disabled={readOnly}
            customHint={pictureHint}
            customNote={pictureNote}
          />
          <Upload
            id="fixed-picture-upload"
            ref={uploadRef}
            className="no-display"
            multiple
            autoUpload
            files={innerFiles}
            onBeforeUpload={onBeforeUpload}
            saveUrl={uploadUrl}
            showFileList={false}
            saveField="picture"
            withCredentials
            onAdd={validateFiles}
            onProgress={onUploadingFile}
            onStatusChange={onStatusChange}
            restrictions={defaultPictureRestrictions}
          />
        </div>
        {anyFileUploaded && uploadedFiles}
        {fullScreenOpen && (
          <ImageModal
            images={imagesWithComments.map(({ image }) => ({
              file_path: image.file_path,
              comment: image.comment
            }))}
            extraClass="full-screen form-image"
            openImage={selectedImageIndex}
            onClose={closeImageFullScreen}
          />
        )}
      </div>
      <div className="error-label error-label-attachment">{error}</div>
    </React.Fragment>
  )
}

InputFixedPicture.propTypes = {
  inputProps: PropTypes.shape({
    id: PropTypes.string,
    title: PropTypes.string,
    required: PropTypes.bool,
    value: PropTypes.array,
    dataItem: PropTypes.oneOfType([PropTypes.object]),
    columnId: PropTypes.number,
    readOnly: PropTypes.bool,
    onChange: PropTypes.func,
    className: PropTypes.string
  }).isRequired
}
