import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { MdContentCopy } from 'react-icons/md'
import { Upload } from '@progress/kendo-react-upload'
import { ProgressBar } from '@progress/kendo-react-progressbars'
import useCookie from '/src/hooks/cookie'
import usePollingAPI, { pollingStatus } from '/src/hooks/api/polling_api'
import '/src/static/css/form.css'

const UPLOAD_ACTION = '/import_excel'
const ALLOWED_EXTENSIONS = ['.xlsx']
const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL
const MAX_PROGRESS = 100

// eslint-disable-next-line max-lines-per-function
export default function ProgressUploader({ modelRoute, templateId, onImportationStatus, onError, setImportedFile }) {
  const [globalProject] = useStore('project')
  const [globalSubproject] = useStore('subproject')
  const [getToken] = useCookie('authentication_token')
  const backgroundJobPollingAPI = usePollingAPI()
  const [showKendoUpload, setshowKendoUpload] = useState(true)
  const [importationProgress, setImportationProgress] = useState(0)
  const [fileName, setFileName] = useState()

  useEffect(() => {
    if (backgroundJobPollingAPI.response === undefined) return
    if (backgroundJobPollingAPI.response.data.length === 0) return
    setImportationProgress(backgroundJobPollingAPI.response.data[0].job_progress)
  }, [backgroundJobPollingAPI.response])

  useEffect(() => {
    if (onImportationStatus === undefined) return
    onImportationStatus(backgroundJobPollingAPI.status)
    if (backgroundJobPollingAPI.status === pollingStatus.ERROR) {
      if (onError) onError(backgroundJobPollingAPI.response.data[0].error_log)
      setshowKendoUpload(!showKendoUpload)
    }
  }, [backgroundJobPollingAPI.status])

  useEffect(() => {
    if (!showKendoUpload) return
    setImportationProgress(0)
  }, [showKendoUpload])

  const onImportStatusChange = (e) => {
    if (e.response === undefined) return
    if (e.response.response === undefined) return
    if (e.response.response.background_job_id === undefined) {
      if (onImportationStatus === undefined) return
      onImportationStatus(pollingStatus.ERROR)
      return
    }
    if (setImportedFile) setImportedFile(e.affectedFiles[0])
    setFileName(e.affectedFiles[0].name)
    setshowKendoUpload(!showKendoUpload)

    startPolling(e)
  }

  const startPolling = (e) => {
    const backgroundJobId = e.response.response.background_job_id
    backgroundJobPollingAPI.startPolling(backgroundJobId)
  }

  return (
    <div className="importation-with-progress">
      {showKendoUpload ? (
        <div data-testid="upload-component">
          <div data-testid="kendo-upload">
            <Upload
              onStatusChange={onImportStatusChange}
              className="file-area"
              batch={false}
              autoUpload={false}
              multiple={false}
              showActionButtons
              class="form-upload"
              defaultFiles={[]}
              withCredentials={false}
              restrictions={{ allowedExtensions: ALLOWED_EXTENSIONS }}
              saveField="file"
              saveUrl={`${SERVER_URL}/api/v1/${modelRoute}${UPLOAD_ACTION}?eav_template_id=${templateId}&project_id=${globalProject.id}&subproject_id=${globalSubproject.id}&remember_token=${getToken()}`}
            />
          </div>
        </div>
      ) : (
        <div className="importation-progress-area">
          <div className="importation-progress-header">
            <MdContentCopy />
            <span className="importation-progress-title">{fileName}</span>
          </div>
          <div data-testid="progress-bar-component">
            <ProgressBar max={MAX_PROGRESS} value={importationProgress} labelVisible={false} />
            <span>{importationProgress}%</span>
          </div>
        </div>
      )}
    </div>
  )
}

ProgressUploader.propTypes = {
  modelRoute: PropTypes.string.isRequired,
  templateId: PropTypes.number.isRequired,
  onImportationStatus: PropTypes.func,
  onError: PropTypes.func,
  setImportedFile: PropTypes.func
}

ProgressUploader.defaultProps = {
  onImportationStatus: undefined,
  onError: undefined,
  setImportedFile: undefined
}
