import React, { useCallback, useEffect, useState } from 'react'
import lodash from 'lodash'
import PropTypes from 'prop-types'
import { useStore } from 'react-context-hook'
import { useHistory } from 'react-router-dom'
import { NumberParam, useQueryParam } from 'use-query-params'
import useFetch from '/src/hooks/api/fetch'
import useFetchAPI from '/src/hooks/api/fetch_api'
import useTranslations from '/src/hooks/api/translations'
import InspectionModel from '/src/models/inspection'
import ScopeModel from '/src/models/scope'
import {
  createInspectionModalMenuItem,
  deleteMenuItem,
  duplicateModalMenuItem,
  duplicateServicesMenuItem,
  editModalMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import Layout from '/src/ui/core/layouts/layout'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import ScopesSidePanel from '/src/ui/domain/scopes/scopes_side_panel'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import useRequestsPinned from '/src/hooks/requests_pinned'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import hasParent from '/src/models/concerns/parent_checker'
import { templateType } from '/src/models/concerns/template_types'
import EstimateModel from '/src/models/estimate'
import EstimateServiceModel from '/src/models/estimate_service'
import EstimateServicePackageableModel from '/src/models/estimate_service_packageable'
import { notifyError } from '/src/ui/core/dialogs/notifications'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import GridFormModal from '/src/ui/core/popups/grid_form_modal'
import ChooseEstimateFormModal from '/src/ui/domain/estimate_services/choose_estimate_form_modal'
import EstimateServiceForm from '/src/ui/domain/estimate_services/estimate_service_form'
import EstimateServicePackageableForm from '/src/ui/domain/estimate_services/estimate_service_packageable_form'
import EstimateServicesGrid from '/src/ui/domain/estimate_services/estimate_services_grid'
import useGetEstimate from '/src/ui/domain/estimate_services/get_estimate_hook.jsx'
import InspectionFormModal from '/src/ui/domain/inspections/inspection_form_modal'
import ChooseInspectionTemplateModal from '/src/ui/domain/requests/choose_inspection_template_modal'
import ScopeForm from '/src/ui/domain/scopes/scope_form'
import ScopesClusteredGrid from '/src/ui/domain/scopes/scopes_clustered_grid'
import ScopesGrid from '/src/ui/domain/scopes/scopes_grid'
import ScopingServicesSidePanel from '/src/ui/domain/scopes/scoping_services_side_panel'
import useDuplicateServices from '/src/ui/domain/scopes/duplicate_services'
import { contractServiceToEditableGrid, dpmsIdToEditableGrid, hideColumnsOnSubgrid } from '/src/utils/columns_formatter'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import { canCreateScope, canEditScope } from '/src/utils/constants/request'
import { indexify } from '/src/utils/array'
import { byString, isEmpty } from '/src/utils/object'
import { CONTEXT_MENU_HIDDEN_STATUS } from '/src/utils/constants/scope'
import I18n from '/src/utils/translations'
import '/src/static/css/core/layouts/shadowed_page.css'

// TODO: move ScopesClusteredGrid to another 'page component'.
// eslint-disable-next-line max-lines-per-function
export default function ScopesPage({ match }) {
  const scopeModel = new ScopeModel()
  const inspectionModelParamName = InspectionModel.paramName
  const itemTemplateId = useQueryParamWithPrevious('eav_template_id')
  const [scopeTemplateId] = useQueryParam('eav_template_id', NumberParam)
  const { requestId, requestIds } = useRequestsPinned()
  const history = useHistory()
  const [inspectionTemplateId, setInspectionTemplateId] = useState(0)
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const [templateTitle, setTemplateTitle] = useState()
  const [showConfirmation, renderConfirmation] = useConfirmationModal()

  const loadingTranslations = useTranslations()
  const [panelModel, setPanelModel] = useState(null) // can be 'scopes', 'scoping_services' or null

  const [selectItemId, setSelectItemId] = useQueryParam('select_item_id', NumberParam)
  const [selectServiceId, setSelectServiceId] = useQueryParam('select_service_id', NumberParam)

  const [globalSubproject] = useStore('subproject')

  // Scope Related
  const scopeGetSections = useFetchAPI('eav_sections')
  const [scopeSelectedItem, setScopeSelectedItem] = useState()
  const [scopeSidePanelDataItem, setScopeSidePanelDataItem] = useState()
  const [scopeSectionsColumns, setScopeSectionsColumns] = useState([])
  const [scopeTemplate, setScopeTemplate] = useState()

  // Service Related
  const { fetch } = useFetch()
  const serviceGetSections = useFetchAPI('eav_sections')
  const [serviceSelectedItem, setServiceSelectedItem] = useState()
  const [serviceSidePanelDataItem, setServiceSidePanelDataItem] = useState()
  const [serviceSectionsColumns, setServiceSectionsColumns] = useState([])
  const [serviceTemplate, setServiceTemplate] = useState()
  const [modalEstimateService, setModalEstimateService] = useState(false)
  const estimateServiceModel = new EstimateServiceModel()
  const estimateServicePackageableModel = new EstimateServicePackageableModel()
  const showSubGrid = serviceTemplate && serviceTemplate.id ? true : false
  const [duplicateServicesModals, setScopeForDuplicate] = useDuplicateServices(
    scopeTemplate?.discipline_id,
    scopeSectionsColumns
  )

  // Request Related
  const [requestStatuses] = useStore('request_statuses')
  const [authorizedScopeCreation, setAuthorizedScopeCreation] = useState()
  const requestFetch = useFetchAPI('requests')
  const [request, setRequest] = useState()
  const estimate = useGetEstimate()

  const getRequest = (idRequest) => {
    const requestsParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: { where: { id: idRequest } }
    }

    requestFetch.fetchAPI(requestsParams)
  }

  // Estimate Related
  const [estimateStatuses] = useStore('estimate_statuses')
  const estimateModel = new EstimateModel()

  // Editable Grid
  const restablishGridSettings = () => ({
    selectedItem: scopeSidePanelDataItem,
    selectedService: serviceSidePanelDataItem
  })

  const onCreateNewItemTop = (newDataItem) => {
    if (requestId && !authorizedScopeCreation) {
      notifyError(I18n.t('scopes.request_status_restriction'))
      return
    }

    newDataItem.request_id = requestId
    return newDataItem
  }

  const onCreateNewItemBottom = (dataItem) => ({
    ...dataItem,
    scope_id: scopeSelectedItem.id,
    discipline_id: serviceTemplate.discipline_id,
    eav_template_id: serviceTemplate.id,
    estimate_id: estimate.id
  })

  const isRowEditableTop = (row) => {
    if (!requestStatuses || isEmpty(requestStatuses)) return false

    const requestStatusId = byString(row, 'request.request_status_id')
    return !hasParent(row) && (requestStatusId ? canEditScope(requestStatuses[requestStatusId].i18n_id) : true)
  }

  const editableGridProps = {
    topEntity: {
      model: scopeModel,
      onCreateNewItem: onCreateNewItemTop,
      shouldAllowCell: (column, dataItem) => isRowEditableTop(dataItem),
      isRowEditable: isRowEditableTop
    },
    bottomEntity: {
      model: estimateServiceModel,
      onCreateNewItem: onCreateNewItemBottom
    },
    recoverSettings: restablishGridSettings,
    allowCreate: true
  }

  const [
    editableGrid,
    setInEditMode,
    editableTopGridColumns,
    editableTopGridDataSource,
    editableBottomGridColumns,
    editableBottomGridDataSource
  ] = useEditableGrid(editableGridProps)

  const onSetTopGridColumns = (columns) => {
    editableTopGridColumns.set(dpmsIdToEditableGrid(columns, requestId))
  }

  const onSetBottomGridColumns = (columns) => {
    const formattedHideOnSubGridColumns = hideColumnsOnSubgrid(columns)
    editableBottomGridColumns.set(
      contractServiceToEditableGrid(formattedHideOnSubGridColumns, {
        discipline_id: byString(serviceTemplate, 'discipline_id')
      })
    )
  }

  const handleCloseSidePanel = () => {
    setScopeSelectedItem()
    setServiceSelectedItem()
    setScopeSidePanelDataItem()
    setServiceSidePanelDataItem()
    setPanelModel(null)

    dispatch(BusEvents.SIDE_PANEL_CLOSED)

    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const fetchRequests = useCallback(({ data: dataSource }) => {

    if (
      !dataSource ||
      dataSource.length === 0 ||
      dataSource.every((dataItem) => dataItem.request_id ? dataItem.request?.id : true)
    ) return

    const queryParams = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: dataSource.map((requestItem) => requestItem.request_id) }
      }
    }

    fetch('requests', queryParams, {
      onSuccess: ({ data: { data } }) => {
        const modelName = scopeModel.paramName

        const requestsById = indexify(data, 'id')
        const onSetDataSource = (prevDataSource) => ({
          ...prevDataSource,
          data: prevDataSource.data.map((item) => ({
            ...item, 
            request: requestsById[item.request_id]
          }))
        })

        dispatch({  
          type: BusEvents.UPDATE_GRID_DATA_SOURCE,
          payload: { modelName, onSetDataSource }
        })

      }
    })
  }, [fetch, scopeModel])

  const onScopeGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableTopGridDataSource.set(dataSource)
    fetchRequests(dataSource)

    const newSelectedItem = dataSource.data.filter(
      (item) => item.id === selectItemId || (scopeSelectedItem && item.id === scopeSelectedItem.id)
    )[0]

    if (!isEqual(scopeSelectedItem, newSelectedItem)) {
      if (!newSelectedItem) handleCloseSidePanel()
      else {
        if (selectItemId) setSelectItemId()
        if (!selectServiceId && !serviceSidePanelDataItem) setPanelModel('scopes')
        setScopeSelectedItem(newSelectedItem)
        setScopeSidePanelDataItem({ ...newSelectedItem, request })
        setColumnStyles(SIDE_PANEL_OPENED)
      }
    } else if (scopeSelectedItem) {
      getRequest(scopeSelectedItem.request_id)
    }
  }

  const onServiceGridDataSource = (dataSource) => {
    const { isEqual } = lodash

    editableBottomGridDataSource.set(dataSource)

    const newSelectedService = dataSource.data.filter(
      (item) => item.id === selectServiceId || (serviceSelectedItem && item.id === serviceSelectedItem.id)
    )[0]

    if (!isEqual(serviceSelectedItem, newSelectedService)) {
      if (selectServiceId) setSelectServiceId()
      setServiceSelectedItem(newSelectedService)
      setServiceSidePanelDataItem(newSelectedService)

      setPanelModel(!newSelectedService ? 'scopes' : 'scoping_services')
      setColumnStyles(SIDE_PANEL_OPENED)
    }
  }

  const defineScopesButtonVisibility = (item) => {
    return !hasParent(item) && !CONTEXT_MENU_HIDDEN_STATUS.includes(item?.request?.request_status_id)
  }

  const scopesMenuItems = {
    edit: editModalMenuItem(
      (dataItem) => defineScopesButtonVisibility(dataItem),
      (e, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: scopeModel.paramName,
          payload: {
            dataItem,
            formType: 'edit',
            opts: { templateId: dataItem.eav_template_id }
          }
        })
    ),
    duplicateServices: duplicateServicesMenuItem(
      () => true,
      (_, dataItem) =>
        setScopeForDuplicate(dataItem)
    ),
    duplicate: duplicateModalMenuItem(
      (dataItem) => defineScopesButtonVisibility(dataItem),
        (e, dataItem) =>
          dispatch({
            type: BusEvents.OPEN_GRID_FORM_MODAL,
            modelName: scopeModel.paramName,
            payload: {
              dataItem,
              formType: 'duplicate',
              opts: { templateId: dataItem.eav_template_id }
            }
          })
    ),
    createInspection: createInspectionModalMenuItem(
      () => true,
      (_, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_MODAL,
          triggeredModelName: inspectionModelParamName,
          payload: { dataItem }
        })
      }
    ),
    remove: deleteMenuItem(history, scopeModel, showConfirmation, handleCloseSidePanel, (dataItem) =>
      defineScopesButtonVisibility(dataItem)
    )
  }

  const handleDeletedEstimateService = () => {
    setServiceSelectedItem()
    setServiceSidePanelDataItem()
    setPanelModel('scopes')
  }

  const isEstimateServiceGridEditable = () => {
    if (!estimateStatuses || !estimate || !estimateStatuses[estimate.statusId]) return false

    return !estimateModel.cannotEditStatuses.includes(estimateStatuses[estimate.statusId].i18n_id)
  }

  const defineServicesButtonVisibility = (item) => {
    const scopeRequestStatusId = scopeSelectedItem?.request?.request_status_id
    return !hasParent(item) && !CONTEXT_MENU_HIDDEN_STATUS.includes(scopeRequestStatusId)
  }

  const estimateServicesMenuItems = {
    edit: editModalMenuItem(
      (dataItem) => defineServicesButtonVisibility(dataItem),
      (e, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: estimateServiceModel.paramName,
          payload: {
            dataItem,
            formType: 'edit',
            opts: {
              scopeId: dataItem.scope_id,
              templateId: serviceTemplate.id,
              disciplineId: serviceTemplate.discipline_id
            }
          }
        })
    ),
    duplicate: duplicateModalMenuItem(
      (dataItem) => defineServicesButtonVisibility(dataItem),
      (e, dataItem) =>
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: estimateServiceModel.paramName,
          payload: {
            dataItem,
            formType: 'duplicate',
            opts: {
              scopeId: dataItem.scope_id,
              templateId: serviceTemplate.id,
              disciplineId: serviceTemplate.discipline_id
            }
          }
        })
    ),
    remove: deleteMenuItem(history, estimateServiceModel, showConfirmation, handleDeletedEstimateService, () => {
      const scopeRequestStatusId = scopeSelectedItem?.request?.request_status_id
      return !CONTEXT_MENU_HIDDEN_STATUS.includes(scopeRequestStatusId)
    })
  }

  const bulkEditItemVisible = (option) => {
    let editableGridDataSource = editableTopGridDataSource
    if (option === 'bottom') editableGridDataSource = editableBottomGridDataSource

    return editableGridDataSource && editableGridDataSource.get && !editableGridDataSource.get.loading
  }

  const estimatesBulkEditItemVisible = (option) => {
    return bulkEditItemVisible(option) && isEstimateServiceGridEditable()
  }

  // Scope
  const onScopeRowClick = useCallback((e) => {
    setScopeSelectedItem((prevScopeSelectedItem) => {
      let newItem = e.dataItem
      if (prevScopeSelectedItem && prevScopeSelectedItem.id === e.dataItem.id) newItem = undefined

      setScopeSidePanelDataItem(newItem)

      if (newItem) {
        setPanelModel('scopes')
        setColumnStyles(SIDE_PANEL_OPENED)
      } else {
        handleCloseSidePanel()
      }

      return newItem
    })
  }, [])

  // Service
  const onServiceRowClick = useCallback((e) => {
    setServiceSelectedItem((prevServiceSelectedItem) => {
      let newItem = e.dataItem
      if (prevServiceSelectedItem && prevServiceSelectedItem.id === e.dataItem.id) newItem = undefined

      if (newItem) {
        setPanelModel('scoping_services')
      } else {
        setPanelModel('scopes')
      }

      setServiceSidePanelDataItem(newItem)
      return newItem
    })
    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  const fetchScopeTemplate = useCallback(() => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { id: itemTemplateId }
      }
    }

    fetch('eav_templates', params, {
      onSuccess: ({ data }) => {
        if (!data.data[0]) return
        setScopeTemplate(data.data[0])
      }
    })
  }, [itemTemplateId, fetch])

  const fetchServiceTemplate = (parentTemplateId) => {
    const params = {
      requestAction: 'READ',
      httpAction: 'get',
      query: {
        where: { parent_template_id: parentTemplateId, template_type: 'estimate_service' }
      }
    }

    fetch('eav_templates', params, {
      onSuccess: ({ data }, params) => {
        const responseData = data.data
        if (!responseData) return
        setServiceTemplate(responseData[0])
      }
    })
  }

  const fetchServiceSections = (itemTemplateId) => {
    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: {
        where: {
          'eav_templates][parent_template_id': itemTemplateId,
          'eav_templates][template_type': templateType.estimate_service
        }
      }
    }

    serviceGetSections.fetchAPI(params)
  }

  const onTemplateChange = useCallback((template) => {
    setTemplateTitle(template.title)
  }, [setTemplateTitle])

  // Scope
  useEffect(() => {
    if (!itemTemplateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: itemTemplateId } }
    }

    scopeGetSections.fetchAPI(params)
    handleCloseSidePanel()
  }, [itemTemplateId])

  useEffect(() => {
    if (loadingTranslations || scopeGetSections.status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(scopeGetSections.responseData.data[0])
    setScopeSectionsColumns(newSectionsColumns)
  }, [scopeGetSections.status, scopeGetSections.responseData, loadingTranslations])

  // Service
  useEffect(() => {
    handleCloseSidePanel()

    if (!scopeTemplateId) return

    fetchScopeTemplate()
    fetchServiceTemplate(itemTemplateId)
    fetchServiceSections(itemTemplateId)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [scopeTemplateId, itemTemplateId])

  useEffect(() => {
    if (loadingTranslations || serviceGetSections.status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(serviceGetSections.responseData.data[0])
    setServiceSectionsColumns(newSectionsColumns)
  }, [serviceGetSections.status, serviceGetSections.responseData, loadingTranslations])

  // request
  useEffect(() => {
    if (!requestId && !scopeSelectedItem) {
      setAuthorizedScopeCreation(undefined)
      return
    }
    const idRequest = !requestId ? scopeSelectedItem.request_id : requestId
    getRequest(idRequest)
  }, [requestId, scopeSelectedItem])

  useEffect(() => {
    if (!request || !request.id) return

    estimate.read(request.id)
  }, [request])

  useEffect(() => {
    if (requestFetch.status !== 'SUCCESS') return
    if (requestFetch.responseData.data.length === 0) return
    if (!Object.keys(requestStatuses).length) return
    const requestItem = requestFetch.responseData.data[0]
    setRequest(requestItem)
    setAuthorizedScopeCreation(canCreateScope(requestStatuses[requestItem.request_status_id].i18n_id))
  }, [requestFetch.status, requestFetch.responseData, requestStatuses])

  useEffect(() => {
    if (!request) return
    if (!scopeSelectedItem) return

    const isRequestValid = request.id === scopeSelectedItem.request_id && request.subproject_id === globalSubproject.id

    const { isEqual } = lodash

    if (!isEqual(scopeSidePanelDataItem.request, request))
      setScopeSidePanelDataItem({ ...scopeSelectedItem, request: isRequestValid ? request : undefined })
  }, [request, scopeSelectedItem, globalSubproject])

  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    ({ payload }) => {
      const { modelName } = payload

      if (match.params.collection !== undefined || !itemTemplateId) return
      if (authorizedScopeCreation === false) {
        notifyError(I18n.t('scopes.request_status_restriction'))
        return
      }
      if (modelName && modelName !== scopeModel.paramName) {
        setModalEstimateService(true)
      } else {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: scopeModel.paramName,
          payload: {
            formType: 'new',
            opts: { templateId: itemTemplateId }
          }
        })
      }
    },
    [requestId, itemTemplateId, serviceTemplate, scopeSelectedItem, authorizedScopeCreation]
  )

  useBus(
    BusEvents.EXITING_EDITABLE_MODE,
    ({ payload }) => {
      const item = payload()
      if (!item) return

      const { selectedItem, selectedService } = item

      setPanelModel(selectedService ? 'scoping_services' : 'scopes')

      setScopeSelectedItem(selectedItem && { ...selectedItem })
      setScopeSidePanelDataItem(selectedItem && { ...selectedItem })

      setServiceSelectedItem(selectedService && { ...selectedService })
      setServiceSidePanelDataItem(selectedService && { ...selectedService })

      setColumnStyles(selectedItem ? SIDE_PANEL_OPENED : SIDE_PANEL_CLOSED)
    },
    []
  )

  if (match.params.collection === 'clusters') {
    return (
      <Layout key={requestId}>
        <ScopesClusteredGrid request={requestId ? requestId.toString() : ''} />
      </Layout>
    )
  }

  return (
    <Layout showNewButton>
      {renderConfirmation()}
      {duplicateServicesModals()}
      {editableGrid(
        <React.Fragment>
          <TabsWrapper tabType={scopeModel.templateType} onTemplateChange={onTemplateChange} />
          <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
            <div
              className={scopeSelectedItem && showSubGrid ? 'shadowed-page' : 'unshadowed-page'}
              key={itemTemplateId}
            >
              {modalEstimateService && (
                <ChooseEstimateFormModal
                  onClose={() => setModalEstimateService(false)}
                  opts={{
                    scope: scopeSelectedItem,
                    service: serviceSelectedItem,
                    serviceTemplate,
                    modelName: estimateServiceModel.paramName,
                    packageableModelName: estimateServicePackageableModel.name,
                    setModalEstimateService
                  }}
                />
              )}
              <ScopesGrid
                requestId={requestId ? requestId.toString() : ''}
                selectedItem={scopeSelectedItem}
                onRowClick={onScopeRowClick}
                onBulkEditItemClick={setInEditMode}
                onGridColumns={onSetTopGridColumns}
                onDataSource={onScopeGridDataSource}
                bulkEditItemVisible={bulkEditItemVisible}
                contextMenuItems={Object.values(scopesMenuItems)}
                templateTitle={templateTitle}
                selectFiltering={showSubGrid}
              />
              <EstimateServicesGrid
                dataItem={scopeSelectedItem}
                selectedItem={serviceSelectedItem}
                onRowClick={onServiceRowClick}
                onBulkEditItemClick={setInEditMode}
                onGridColumns={onSetBottomGridColumns}
                onDataSource={onServiceGridDataSource}
                bulkEditItemVisible={estimatesBulkEditItemVisible}
                template={serviceTemplate}
                contextMenuItems={Object.values(estimateServicesMenuItems)}
              />
            </div>
            {panelModel === 'scopes' ? (
              <ScopesSidePanel
                dataItem={scopeSidePanelDataItem}
                sections={scopeSectionsColumns}
                onClose={handleCloseSidePanel}
                contextMenuItems={scopesMenuItems}
                template={scopeTemplate}
              />
            ) : (
              <React.Fragment>
                {serviceSidePanelDataItem && (
                  <ScopingServicesSidePanel
                    dataItem={serviceSidePanelDataItem}
                    sections={serviceSectionsColumns}
                    scopeDataItem={scopeSidePanelDataItem}
                    scopeSections={scopeSectionsColumns}
                    onClose={handleCloseSidePanel}
                    contextMenuItems={estimateServicesMenuItems}
                  />
                )}
              </React.Fragment>
            )}
            <GridFormModal modelName={scopeModel.paramName}>
              <ScopeForm templateId={itemTemplateId} requestIds={requestIds} />
            </GridFormModal>
            <GridFormModal modelName={estimateServiceModel.paramName}>
              <EstimateServiceForm estimateId={estimate.id} />
            </GridFormModal>
            <GridFormModal modelName={estimateServicePackageableModel.name}>
              <EstimateServicePackageableForm estimateId={estimate.id} />
            </GridFormModal>
            <ChooseInspectionTemplateModal
              modelName={inspectionModelParamName}
              setInspectionTemplateId={setInspectionTemplateId}
              inspectedType="Scope"
            />
            <InspectionFormModal modelParamName={inspectionModelParamName} templateId={inspectionTemplateId} />
          </LayoutPanels>
        </React.Fragment>
      )}
    </Layout>
  )
}

ScopesPage.propTypes = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      collection: PropTypes.string
    })
  })
}

ScopesPage.defaultProps = {
  match: PropTypes.shape({
    params: PropTypes.shape({
      collection: undefined
    })
  })
}
