import React from 'react'
import PropTypes from 'prop-types'
import I18n from '/src/utils/translations'
import CustomTooltip from '/src/ui/core/layouts/custom_tooltip'
import { ReactComponent as DataAlert } from '/public/static/svg/data-alert'

export default function MissingRequiredCellsIcon({ missingRequiredCells }) {
  if (missingRequiredCells <= 0) return <span className='bulk-actions-cell__icon' />

  return (
    <CustomTooltip parentTitle position='right'>
      <span
        className='bulk-actions-cell__icon bulk-actions-cell__data-alert'
        title={I18n.t('grid.editable.missing_required', { number: missingRequiredCells })}
      >
        <DataAlert />
      </span>
    </CustomTooltip>
  )
}

MissingRequiredCellsIcon.propTypes = {
  missingRequiredCells: PropTypes.number
}

MissingRequiredCellsIcon.defaultProps = {
  missingRequiredCells: 0
}
