const batchEntities = [
  'disciplines',
  'units',
  'service_packages',
  'areas',
  'subareas',
  'request_statuses',
  'scaffolding_statuses',
  'inspect_statuses',
  'progress_statuses',
  'progress_service_statuses',
  'estimate_statuses',
  'formulas',
  'employees',
  'file_categories',
  'variable_types',
  'datasheet_categories',
  'field_settings',
  'contract_services',
  'scaffolding_types',
  'area_noises',
  'load_classes',
  'report_templates',
  'report_templates_roles'
]

// eslint-disable-next-line import/prefer-default-export
export { batchEntities }
