/* eslint-disable max-lines-per-function */
import React, { useState, useEffect, useMemo, useCallback } from 'react'
import { useQueryParam, NumberParam, StringParam } from 'use-query-params'
import { useHistory } from 'react-router-dom'
import lodash from 'lodash'
import useBus, { dispatch } from '/src/hooks/bus/bus'
import BusEvents from '/src/hooks/bus/bus_events'
import useEditableGrid from '/src/ui/core/grid/editable_grid_hook'
import useFetchAPI from '/src/hooks/api/fetch_api'
import Layout from '/src/ui/core/layouts/layout'
import TemplatableGrid from '/src/ui/core/grid/templatable_grid'
import LabelFilter from '/src/ui/core/buttons/label_filter'
import DatasheetModel from '/src/models/datasheet'
import DatasheetFormModal from '/src/ui/core/popups/datasheet_form_modal'
import TabsWrapper from '/src/ui/core/layouts/tabs_wrapper'
import LayoutPanels from '/src/ui/core/layouts/layout_panels'
import useExcelExportUrl from '/src/hooks/api/export_excel_url'
import useConfirmationModal from '/src/ui/core/popups/confirmation_modal'
import {
  editModalMenuItem,
  deleteMenuItem,
  importMenuItem,
  exportMenuItem,
  duplicateModalMenuItem,
  clearRecordsMenuItem,
  printMenuItem,
  clearTemplatePreferencesMenuItem
} from '/src/ui/core/grid/context_menu_entries'
import MoreActionsIcon from '/src/ui/core/icons/more_actions_icon'
import NewButton from '/src/ui/core/icons/new_button'
import PopupImportFile from '/src/ui/core/popups/popup_import_file'
import DatasheetsSidePanel from '/src/ui/domain/datasheets/datasheets_side_panel'
import { filterVisibleOnWeb } from '/src/models/concerns/eav_section'
import { SIDE_PANEL_CLOSED, SIDE_PANEL_OPENED } from '/src/utils/constants/grid'
import useQueryParamWithPrevious from '/src/hooks/query_param_with_previous'
import BulkEditingIcon from '/src/ui/core/icons/bulk_editing_icon'
import useConfigurePrintGridFile from '/src/ui/core/popups/configure_print_grid_file'
import useClearTemplatePreferences from '/src/ui/core/popups/clear_template_preferences'
import '/src/static/css/core/layouts/shadowed_page.css'

export default function DatasheetsPage() {
  const model = new DatasheetModel()
  const [showConfirmation, renderConfirmation] = useConfirmationModal()
  const [datasheetId, setDatasheetId] = useQueryParam('id', NumberParam)
  const templateId = useQueryParamWithPrevious('eav_template_id')
  const [filterQuery] = useQueryParam('filter', StringParam)
  const [openImport, setOpenImport] = useState(false)
  const [filter, setFilter] = useState()
  const exportParams = { entity: 'datasheets', templateId, isApi: false }
  const { linkExport, setExportFilters } = useExcelExportUrl(exportParams)
  const { status, responseData, fetchAPI } = useFetchAPI('eav_sections')
  const [sectionsColumns, setSectionsColumns] = useState([])
  const [selectedItem, setSelectedItem] = useState()
  const [gridColumns, setGridColumns] = useState()
  const [gridDataSource, setGridDataSource] = useState()
  const [columnStyles, setColumnStyles] = useState(SIDE_PANEL_CLOSED)
  const history = useHistory()

  useBus(
    BusEvents.NEW_BUTTON_CLICKED,
    () => {
      dispatch({
        type: BusEvents.OPEN_GRID_FORM_MODAL,
        modelName: model.paramName,
        payload: { formType: 'new' }
      })
    },
    [templateId]
  )

  const editableGridProps = {
    allowCreate: true,
    recoverSettings: () => selectedItem,
    topEntity: { model }
  }

  const [editableGrid, setInEditMode, editableGridColumns, editableGridDataSource] = useEditableGrid(editableGridProps)

  const [openConfigurationPopup, printGridPopup, isPrintable] = useConfigurePrintGridFile(
    gridColumns,
    gridDataSource,
    model
  )

  const [onClearTemplatePreferencesClick, clearTemplatePreferencesPopup] = useClearTemplatePreferences(templateId)

  const menuItems = {
    edit: editModalMenuItem(
      () => true,
      (_, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: model.paramName,
          payload: { dataItem, formType: 'edit' }
        })
      }
    ),
    duplicate: duplicateModalMenuItem(
      () => true,
      (_, dataItem) => {
        dispatch({
          type: BusEvents.OPEN_GRID_FORM_MODAL,
          modelName: model.paramName,
          payload: { dataItem, formType: 'duplicate' }
        })
      }
    ),
    remove: deleteMenuItem(history, model, showConfirmation)
  }

  const moreActionsMenuItems = [
    printMenuItem(openConfigurationPopup, () => isPrintable),
    clearTemplatePreferencesMenuItem(onClearTemplatePreferencesClick),
    importMenuItem(setOpenImport),
    exportMenuItem(linkExport),
    clearRecordsMenuItem(model, templateId, showConfirmation)
  ]

  const clearAllFiltersCallback = useCallback(() => {
    setDatasheetId()
  }, [setDatasheetId])

  const icons = [
    editableGridDataSource && editableGridDataSource.get && !editableGridDataSource.get.loading && (
      <BulkEditingIcon onClick={() => setInEditMode('top')} />
    ),
    <MoreActionsIcon items={moreActionsMenuItems} />,
    <NewButton />
  ]

  const handleCloseSidePanel = () => {
    setSelectedItem()
    dispatch(BusEvents.SIDE_PANEL_CLOSED)
    setColumnStyles(SIDE_PANEL_CLOSED)
  }

  const onRowClick = useCallback((e) => {
    setSelectedItem((prevSelectedItem) => {
      const isSidePanelOpened = prevSelectedItem && prevSelectedItem.id === e.dataItem.id
      const newItem = isSidePanelOpened ? undefined : e.dataItem

      return newItem
    })

    setColumnStyles(SIDE_PANEL_OPENED)
  }, [])

  const onGridColumns = (e) => {
    setGridColumns(e)
    editableGridColumns.set(e)
  }

  const onGridDataSource = (dataSource) => {
    setGridDataSource(dataSource)

    const { isEqual } = lodash

    const newSelectedItem = selectedItem && dataSource.data.filter((item) => item.id === selectedItem.id)[0]

    editableGridDataSource.set(dataSource)

    if (!isEqual(selectedItem, newSelectedItem)) {
      if (!newSelectedItem) handleCloseSidePanel()
      else {
        setSelectedItem(newSelectedItem)
        setColumnStyles(SIDE_PANEL_OPENED)
      }
    }
  }

  const labels = useMemo(() => {
    if (!datasheetId || !templateId) return []

    const link = [`/datasheets?eav_template_id=${templateId}`]
    if (filterQuery) {
      const categoryFilter = encodeURIComponent(filterQuery)
      link.push(`filter=${categoryFilter}`)
    }

    return [<LabelFilter key={datasheetId} link={link.join('&')} text={datasheetId.toString()} />]
  }, [datasheetId, templateId, filterQuery])

  const updateFilterState = () => {
    const filterArray = [{ type: 'where', column: 'id', value: parseInt(datasheetId, 10) }]
    setFilter(datasheetId ? filterArray : undefined)
  }

  const onClosePopupImport = () => {
    setOpenImport(false)
    dispatch(BusEvents.RELOAD_GRID)
  }

  useEffect(() => {
    updateFilterState()
  }, [datasheetId])

  useEffect(() => {
    if (!templateId) return

    const params = {
      httpAction: 'get',
      additionalResource: { path: 'eav_columns' },
      query: { where: { eav_template_id: templateId } }
    }

    fetchAPI(params)
    handleCloseSidePanel()
  }, [templateId])

  useEffect(() => {
    if (status !== 'SUCCESS') return

    const newSectionsColumns = filterVisibleOnWeb(responseData.data[0])
    setSectionsColumns(newSectionsColumns)
  }, [status, responseData])

  useBus(
    BusEvents.EXITING_EDITABLE_MODE,
    ({ payload }) => {
      const item = payload()
      if (!item) return

      setSelectedItem({ ...item })
      setColumnStyles(SIDE_PANEL_OPENED)
    },
    []
  )

  return (
    <Layout showNewButton={!!templateId}>
      <React.Fragment>
        {renderConfirmation()}
        {printGridPopup}
        {clearTemplatePreferencesPopup()}
        {openImport && (
          <PopupImportFile
            modelRoute={model.route}
            templateId={templateId}
            onClose={onClosePopupImport}
            useLegacyPath
          />
        )}
        {editableGrid(
          <React.Fragment>
            <TabsWrapper tabType={model.templateType} />
            <LayoutPanels wrapperClass="side-panel-wrapper" columnStyles={columnStyles}>
              <TemplatableGrid
                contextMenuItems={Object.values(menuItems)}
                model={model}
                icons={icons}
                labels={labels}
                filter={filter}
                clearAllFiltersCallback={clearAllFiltersCallback}
                selectedItem={selectedItem}
                selectFiltering={false}
                onFilterUpdate={setExportFilters}
                onRowClick={onRowClick}
                onGridColumns={onGridColumns}
                onDataSource={onGridDataSource}
              />
              <DatasheetsSidePanel
                dataItem={selectedItem}
                sections={sectionsColumns}
                columns={gridColumns}
                onClose={handleCloseSidePanel}
                contextMenuItems={menuItems}
              />
            </LayoutPanels>
            <DatasheetFormModal model={model} templateId={templateId} />
          </React.Fragment>
        )}
      </React.Fragment>
    </Layout>
  )
}
