import { useStore } from 'react-context-hook'
import { tableize } from 'inflected'
import useCookie from '/src/hooks/cookie'

const SERVER_URL = import.meta.env.SNOWPACK_PUBLIC_DPMS_API_URL

export default function useBuildUrl(attachment) {
  const [project] = useStore('project')
  const [subproject] = useStore('subproject')
  const [getToken] = useCookie('authentication_token')

  const type = tableize(attachment.attachable_type)
  const filePath = `/api/v1/${type}/${attachment.id}/download_attachment`
  const subprojectQuery = `&subproject_id=${subproject.id}&project_id=${project.id}`

  return `${SERVER_URL + filePath}?remember_token=${getToken()}${subprojectQuery}`
}
