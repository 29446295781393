export const transformSectionsWithColumns = (isColumnVisible, sections, columns) => {
  return sections.map((section) => ({
    ...section,
    columns: columns
      .filter((column) => column.section === section.id)
      .map((column) => ({
        ...column,
        visible_on_web: isColumnVisible(column)
      }))
  }))
}

export const filterInfoTabSections = (sections) => (
  sections.filter((section) => (
    !section.hideOnInfoTab
  ))
)

export const filterSectionOnSidePanel = (sections) => (
  sections.filter((section) => (
    !section.hideSectionOnSidePanel
  ))
)
