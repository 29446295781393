import React from 'react'
import PropTypes from 'prop-types'
import LabelColumn from '/src/ui/core/grid/label_column'
import FlagColumn from '/src/ui/domain/progresses/flag_column'
import DescriptionColumn from '/src/ui/domain/progresses/description_column'
import ProductivityColumn from '/src/ui/domain/progresses/productivity_column'
import ProgressColumn from '/src/ui/domain/progresses/progress_column'
import DeltaQuantityColumn from '/src/ui/domain/progresses/delta_quantity_column'
import DeltaHoursColumn from '/src/ui/domain/progresses/delta_hours_column'
import RequestIdCell from '/src/ui/core/grid/request_id_cell'
import FieldFactory from '/src/ui/core/fields/field_factory'
import '/src/static/css/domain/progresses/cells.css'

export default function ProgressCellFactory({ field, dataItem, columns }) {
  const column = columns.find((c) => c.description === field)
  const RIGHT_ALIGNED_FIELDS = ['quantity', 'crews', 'crew_size', 'working_hours']

  let cell

  switch (field) {
    case 'request_id':
      cell = <RequestIdCell id={dataItem[field]} request={dataItem.request} showStatus />
      break
    case 'label':
      cell = <LabelColumn value={dataItem[field]} />
      break
    case 'flag':
      cell = <FlagColumn data={dataItem} />
      break
    case 'description':
      cell = <DescriptionColumn data={dataItem} columns={columns} />
      break
    case 'PF':
      cell = <ProductivityColumn data={dataItem} />
      break
    case 'actual_progress':
    case 'progress':
      cell = <ProgressColumn data={dataItem} summaryKey="progress_summary" />
      break
    case 'delta_quantity':
      cell = <DeltaQuantityColumn data={dataItem} />
      break
    case 'delta_hours':
      cell = <DeltaHoursColumn data={dataItem} />
      break
    default:
      cell = <FieldFactory value={dataItem[field]} type={column ? column.type : 'text'} />
      break
  }

  return RIGHT_ALIGNED_FIELDS.includes(field) ? <span className="align-cells-right">{cell}</span> : cell
}

ProgressCellFactory.propTypes = {
  field: PropTypes.string,
  dataItem: PropTypes.oneOfType([PropTypes.object]),
  columns: PropTypes.arrayOf(PropTypes.object)
}

ProgressCellFactory.defaultProps = {
  field: '',
  dataItem: undefined,
  columns: []
}
